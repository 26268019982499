import React, { useEffect } from 'react';
import Reveal from 'reveal.js';

import 'reveal.js/dist/reveal.css';
import '../../../font.scss';
import './theme.scss';
import './presentation.scss';

import { UnthemedLoading } from '../../../components/Loading';
import {
    useDispatchContext,
    useStateContext,
} from '../../../services/contextProvider';
import { useThemes } from '../../../hooks/useThemes';
import { useGetMissionNavQuery } from '../../../data/types';

import fitty, { FittyOptions } from 'fitty';
import { usePresentationColours } from '../hooks/usePresentationColours';

export function StepContainer(props: {
    isReady: boolean;
    steps: JSX.Element[];
    onInitialized?: (deck: Reveal.Api) => void;
}) {
    const { isReady, onInitialized } = props;

    const {
        currentTenantId,
        themePrimaryColourHex,
        currentMissionId,
        currentTeamCode,
    } = useStateContext();

    const dispatch = useDispatchContext();

    const { lightTheme, darkTheme } = useThemes();

    const {
        highlightColour,
        highlightColourLight,
        highlightColourTransparent,
        highlightTextColour,
    } = usePresentationColours();

    const sassVariables = [
        {
            name: '--presentationThemePrimary',
            value: themePrimaryColourHex ? `#${themePrimaryColourHex}` : null,
        },
        {
            name: '--presentationHighlight',
            value: highlightColour,
        },
        {
            name: '--presentationHighlightLight',
            value: highlightColourLight,
        },
        {
            name: '--presentationHighlightTransparent',
            value: highlightColourTransparent,
        },
        {
            name: '--presentationHighlightText',
            value: highlightTextColour,
        },
        {
            name: '--reveal-base03',
            value: lightTheme.palette.neutralDark,
        },
        {
            name: '--reveal-base02',
            value: lightTheme.palette.themePrimary,
        },
    ];

    const root = document.documentElement;
    for (const v of sassVariables) {
        root.style.setProperty(v.name, v.value);
    }

    const { loading } = useGetMissionNavQuery({
        skip: !currentTenantId || !currentMissionId || !currentTeamCode,
        variables: {
            tenantId: currentTenantId || '',
            missionId: currentMissionId || '',
        },
        onCompleted: ({ mission }) => {
            const teamCode = currentTeamCode;
            const currentTeam =
                teamCode &&
                mission?.team?.code &&
                mission?.team?.code.toUpperCase() === teamCode.toUpperCase()
                    ? mission?.team
                    : mission?.leaderOfTeams.find(
                          (t) =>
                              t.code?.toUpperCase() === teamCode?.toUpperCase()
                      );
            if (currentTeam) {
                dispatch({
                    type: 'SetThemePrimaryColour',
                    payload: {
                        themePrimaryColourHex:
                            currentTeam?.division?.primaryColourHex,
                    },
                });
            }
        },
    });

    useEffect(() => {
        if (isReady && !loading) {
            const deck = new Reveal({
                transition: 'none',
                width: '100%',
                height: '100%',
                slideNumber: false,
                margin: 0,
            });
            deck.initialize().then(() => {
                if (onInitialized) {
                    onInitialized(deck);
                }
            });
            deck.on('slidechanged', () => {
                // Couldn't get the built in support to work, and it doesn't allow options anyway.
                const fittyOptions: FittyOptions = {
                    minSize: 8,
                    maxSize: 36,
                    multiLine: true,
                    observeMutations: {
                        subtree: true,
                        childList: true,
                        characterData: true,
                        attributeFilter: ['class'],
                    },
                };
                fitty('.fit', fittyOptions);
                fitty.fitAll(fittyOptions);
            });
        }
    }, [isReady, onInitialized, loading]);

    if (!isReady || loading) {
        return <UnthemedLoading padding={0} theme={darkTheme} />;
    }

    return (
        <div className="reveal">
            <div className="slides" data-transition="slide">
                {props.steps}
            </div>
        </div>
    );
}
