import { mergeStyles } from '@fluentui/react';
import { useState, useEffect } from 'react';
import {
    PromptBuilder,
    useMissionMentor,
} from '../../../hooks/useMissionMentor';
import { useThemes } from '../../../hooks/useThemes';
import { Markdown } from '../../Presentations/Markdown';
import {
    GetStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataLazyQuery,
    useGetMeasuresForStatusReportLazyQuery,
    useGetMissionQuery,
    useGetTasksForStatusReportLazyQuery,
} from '../../../data/types';
import { useReportPeriods } from '../../../hooks/useReportPeriods';
import { useStateContext } from '../../../services/contextProvider';
import { sorters } from '../../../data/sorters';
import dayjs from 'dayjs';
import { useFormatters } from '../../../hooks/useFormatters';
import { taskStatusUtil } from '../../../data/extendedTypes';

export const markdownModules = import.meta.glob('../../../prompts/*.md', {
    as: 'raw',
});

export type InputTextName =
    | 'ExecutiveSummary'
    | 'LastPeriod'
    | 'NextPeriod'
    | 'RisksAndOpportunities'
    | 'Support'
    | undefined;

export function useStatusReportMai(
    inputText: string,
    inputTextName: InputTextName,
    onInputTextChanged: (newInputText: string) => void,
    statusReport: GetStatusReportQuery['statusReport']
): {
    isEnabled: boolean;
    isDialogOpen: boolean;
    dismissDialog: () => void;
    maiContent: JSX.Element | undefined;
    maiActions: { name: string; action: () => void }[];
    generateDraft: () => void;
    factCheck: () => void;
    changeTone: (toneType: string) => void;
    simplify: () => void;
    isStreaming: boolean;
    isBuildingPrompt: boolean;
} {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const {
        isStreaming,
        contentMd,
        executePrompt,
        addTeamToPrompt,
        cancel,
        isEnabled,
    } = useMissionMentor();

    const { data: missionData } = useGetMissionQuery({
        skip: !isEnabled || !statusReport?.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: statusReport?.missionId || '',
        },
    });

    const [isBuildingPrompt, setIsBuildingPrompt] = useState(false);

    const { formatTaskPercentage } = useFormatters();

    const [getMeasures] = useGetMeasuresForStatusReportLazyQuery();
    const [getTasks] = useGetTasksForStatusReportLazyQuery();
    const [getPeriodData] = useGetMeasurePeriodDataLazyQuery();

    const mission = missionData?.mission;
    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    const { formatPeriod } = useReportPeriods(fyStartDate, fyEndDate);

    const markdownContainerStyles = mergeStyles({
        '& BLOCKQUOTE': {
            marginLeft: 0,
            marginRight: 8,
            paddingLeft: 12,
            paddingRight: 16,
            paddingTop: 1,
            paddingBottom: 1,
            borderLeft: `solid 4px ${currentTheme.palette.themeLight}`,
        },
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [maiContent, setMaiContent] = useState<JSX.Element>();
    const [maiActions, setMaiActions] =
        useState<{ name: string; action: () => void }[]>();

    const getTaskDetailsMd = async () => {
        const promptBuilder = new PromptBuilder();

        promptBuilder.writeln(`# Specified Tasks`);
        promptBuilder.writeln();

        const tasksData = await getTasks({
            variables: {
                tenantId: currentTenantId || '',
                forDateTime:
                    statusReport?.utcDataDate ||
                    statusReport?.utcCompletedDate ||
                    null,
                missionId: statusReport?.missionId || '',
                includeDeleted: false,
            },
        });

        const tasks = tasksData.data?.tasks;

        if (!tasks?.length) {
            promptBuilder.writeln('NONE DEFINED');
        } else {
            const specifiedTasks = tasks
                .slice()
                .filter((t) => t.parentTaskId === null)
                .sort(sorters.sequenceSorter);

            specifiedTasks?.forEach(async (st) => {
                promptBuilder.writeln(`## ${st.name}`);
                promptBuilder.writeln();

                if (st.start) {
                    promptBuilder.writeln(
                        `Start: ${dayjs(st.start).format('DD MMM YYYY')}`
                    );
                }
                if (st.due) {
                    promptBuilder.writeln(
                        `Due: ${dayjs(st.due).format('DD MMM YYYY')}`
                    );
                }

                promptBuilder.writeln();

                const impliedTasks = tasks
                    .slice()
                    .filter((it) => it.parentTaskId === st.id)
                    .filter((it) => !it.isDuplicate)
                    .sort(sorters.sequenceSorter);

                const headers = [
                    'Implied Task',
                    'Start Date',
                    'Due Date',
                    'Done Date',
                    'Percent Complete',
                    'Status',
                ];

                const rows: string[][] = [];

                impliedTasks.forEach((it) => {
                    const start = it.start
                        ? dayjs(it.start).format('DD MMM YYYY')
                        : 'NONE';
                    const due = it.due
                        ? dayjs(it.due).format('DD MMM YYYY')
                        : 'NONE';
                    const done = it.done
                        ? dayjs(it.done).format('DD MMM YYYY')
                        : 'NONE';
                    const percentComplete = formatTaskPercentage(
                        it.done ? 1 : it.percentComplete
                    );

                    const status =
                        taskStatusUtil.GetTaskStatus(
                            {
                                ...it,
                                resourcedFromTask: null, // This isn't needed for status reports.
                            },
                            mission?.team?.division?.financialYear?.code ||
                                undefined,
                            statusReport?.utcDataDate ||
                                statusReport?.utcCompletedDate
                        ) || '';

                    rows.push([
                        it.name || 'NO NAME',
                        start,
                        due,
                        done,
                        percentComplete,
                        status.match(/($[a-z])|[A-Z][^A-Z]+/g)?.join(' ') || '',
                    ]);
                });

                if (rows.length) {
                    promptBuilder.writeln(
                        'The actions required to complete this specified task:'
                    );
                    promptBuilder.writeMarkdownTable(headers, rows);
                } else {
                    promptBuilder.writeln('No implied tasks');
                }
                promptBuilder.writeln();
            });
        }

        return promptBuilder.toString();
    };

    const getMeasureDetailsMd = async () => {
        const promptBuilder = new PromptBuilder();

        const measuresData = await getMeasures({
            variables: {
                tenantId: currentTenantId || '',
                forDateTime:
                    statusReport?.utcDataDate ||
                    statusReport?.utcCompletedDate ||
                    null,
                missionId: statusReport?.missionId || '',
                includeDeleted: false,
            },
        });

        const measures =
            measuresData.data?.measures
                ?.slice()
                ?.sort(sorters.sequenceSorter) || [];

        if (measures?.length) {
            promptBuilder.writeln('# Measures of Success\n\n');
        }

        const headers = [
            'Name',
            'Period',
            'Target',
            'Actual',
            'Next Period',
            'Next Period Target',
            'YTD Target',
            'YTD Actual',
            'Other Information',
        ];

        const rows: string[][] = [];

        for (const m of measures) {
            const periodData = await getPeriodData({
                variables: {
                    forDateTime:
                        statusReport?.utcDataDate ||
                        statusReport?.utcCompletedDate ||
                        null,
                    measureId:
                        m?.isLinked && m.linkedFromMeasureId
                            ? m.linkedFromMeasureId
                            : m?.id || '',
                    tenantId: currentTenantId || '',
                    reportPeriod: statusReport?.reportPeriod || null,
                    reportPeriodType:
                        statusReport?.reportPeriodType ||
                        ReportPeriodTypes.None,
                },
            });

            const periodDataYtd = await getPeriodData({
                variables: {
                    forDateTime:
                        statusReport?.utcDataDate ||
                        statusReport?.utcCompletedDate ||
                        null,
                    measureId:
                        m?.isLinked && m.linkedFromMeasureId
                            ? m.linkedFromMeasureId
                            : m?.id || '',
                    tenantId: currentTenantId || '',
                    reportPeriod: statusReport?.reportPeriod || null,
                    reportPeriodType: ReportPeriodTypes.Ytd,
                },
            });

            const mpd = periodData.data?.periodData?.measurePeriodData;
            const ytd = periodDataYtd.data?.periodData?.measurePeriodData;

            let otherInformation = '';

            const fact = statusReport?.facts.find(
                (f) => f.measureId === m.id && f.isIncluded
            );

            if (fact) {
                otherInformation +=
                    'This measure of success has been included as a key measure for this status report\n\n';

                if (
                    fact.soWhatText ||
                    fact.insightText ||
                    fact.actions.length
                ) {
                    otherInformation +=
                        'The user has made the following updates:\n\n';

                    if (fact.soWhatText) {
                        otherInformation += 'So What?\n\n' + fact.soWhatText;
                    }

                    if (fact.soWhatText) {
                        otherInformation += 'Insights?\n\n' + fact.insightText;
                    }

                    if (fact.actions.length) {
                        otherInformation += 'Actions\n\n';
                        otherInformation += fact.actions.map(
                            (a) => `- ${a.name}\n\n`
                        );
                    }
                }
            }

            rows.push([
                m.name || 'No name',
                mpd?.period || '',
                mpd?.targetFormatted || 'N/A',
                mpd?.actualFormatted || 'N/A',
                mpd?.nextPeriod || '',
                mpd?.nextTargetFormatted || 'N/A',
                ytd?.targetFormatted || 'N/A',
                ytd?.actualFormatted || 'N/A',
                otherInformation,
            ]);
        }

        promptBuilder.writeMarkdownTable(headers, rows);

        return promptBuilder.toString();
    };

    const getMissionDetailsMd = async (): Promise<string> => {
        const promptBuilder = new PromptBuilder();

        if (statusReport?.reportPeriodType && statusReport.reportPeriod) {
            const period = formatPeriod(
                statusReport?.reportPeriodType,
                statusReport.reportPeriod
            );

            if (period) {
                promptBuilder.writeln('# Report Period');
                promptBuilder.writeln(period);
                promptBuilder.writeln();
            }
        }

        if (mission?.title) {
            promptBuilder.writeln('# Mission Title');
            promptBuilder.writeln(mission?.title);
            promptBuilder.writeln();
        }

        if (mission?.owner) {
            promptBuilder.writeln('# Mission Owner');
            promptBuilder.writeln(mission?.owner);
            promptBuilder.writeln();
        }

        if (mission?.missionStatement) {
            promptBuilder.writeln('# Mission Statement');
            promptBuilder.writeln(mission?.missionStatement);
            promptBuilder.writeln();
        }

        const team =
            mission?.leaderOfTeams.find((t) => t.code) || mission?.team;

        addTeamToPrompt(
            promptBuilder,
            team?.code,
            mission?.team?.division?.financialYear?.code,
            mission?.userId
        );

        promptBuilder.write(await getMeasureDetailsMd());
        promptBuilder.write(await getTaskDetailsMd());

        addCustomFactsToPrompt(promptBuilder);

        return promptBuilder.toString();
    };

    const addCustomFactsToPrompt = (promptBuilder: PromptBuilder): void => {
        const customFacts = statusReport?.facts
            .slice()
            .filter(
                (f) => f.isIncluded && f.factText && !f.measureId && !f.taskId
            )
            .sort(sorters.sequenceSorter);

        if (!customFacts?.length) {
            return;
        }

        promptBuilder.writeln('# Report Facts');
        promptBuilder.writeln();

        customFacts.forEach((cf) => {
            if (cf.factText) {
                promptBuilder.writeln(`## ${cf.factText}\n\n`);
            }
            if (cf.soWhatText) {
                promptBuilder.writeln(`### So What?\n\n${cf.soWhatText}\n\n`);
            }
            if (cf.insightText) {
                promptBuilder.writeln(`### Insight\n\n${cf.insightText}\n\n`);
            }
            if (cf.actions.length) {
                promptBuilder.writeln(
                    `### Actions\n\n${cf.actions.map((a) => `- ${a.name}`).join('\n\n')}`
                );
            }
        });
    };

    const processMarkdownContent = (
        markdown: string,
        isStreaming: boolean
    ): string => {
        const placeholderStart = '[//]: UPDATED TEXT START';
        const placeholderEnd = '[//]: UPDATED TEXT END';
        let isInPlaceholderBlock = false;

        // Process markdown line by line
        let processedMarkdown = markdown
            .split('\n')
            .map((line) => {
                if (line.trim() === placeholderStart) {
                    isInPlaceholderBlock = true;
                    return ''; // Remove the placeholder start line
                }

                if (line.trim() === placeholderEnd) {
                    isInPlaceholderBlock = false;
                    return ''; // Remove the placeholder end line
                }

                if (line.trim().startsWith('[//]:')) {
                    return ''; // Remove other markdown comments
                }

                if (isInPlaceholderBlock) {
                    return `> ${line}`; // Add blockquote prefix
                }

                return line; // Keep the rest of the content
            })
            .join('\n');

        if (isStreaming) {
            processedMarkdown += ' ⬤';
        }

        return processedMarkdown;
    };

    useEffect(() => {
        let markdown = contentMd;

        if (markdown.indexOf('```markdown') > -1) {
            markdown = markdown.replaceAll('```markdown', '');
            markdown = markdown.replaceAll('```', '');
        }

        markdown = processMarkdownContent(markdown, isStreaming);

        if (markdown) {
            setMaiContent(
                <Markdown
                    source={markdown}
                    className={markdownContainerStyles}
                />
            );
        } else if (isStreaming) {
            setMaiContent(<p className="cursor">⬤</p>);
        } else {
            setMaiContent(undefined);
        }

        if (!isStreaming && contentMd && onInputTextChanged) {
            const regex =
                /\[\/\/\]: UPDATED TEXT START([\s\S]*?)\[\/\/\]: UPDATED TEXT END/;
            const match = contentMd.match(regex);
            const updatedText = match ? match[1] : null;
            if (updatedText && onInputTextChanged) {
                setMaiActions([
                    {
                        name: 'Use This Text',
                        action: () => {
                            onInputTextChanged(updatedText);
                            setIsDialogOpen(false);
                        },
                    },
                ]);
            }
        }
    }, [contentMd, isStreaming, markdownContainerStyles, onInputTextChanged]);

    const openDialog = () => setIsDialogOpen(true);
    const dismissDialog = () => {
        cancel();
        setIsDialogOpen(false);
    };

    const getPromptBuilder = async (
        fileName: string
    ): Promise<PromptBuilder> => {
        const promptBuilder = new PromptBuilder();

        try {
            const path = `../../../prompts/${fileName}`;

            const loader = markdownModules[path as string];
            if (loader) {
                const md = await loader();
                promptBuilder.writeln(md);
                return promptBuilder;
            }
        } catch (err) {
            console.error(err);
        }

        promptBuilder.clear();
        promptBuilder.writeln('# IMPORTANT INSTRUCTIONS');
        promptBuilder.writeln(
            'Inform the user there has been an error and to check back later. Do not use any placeholders in the response.'
        );

        return promptBuilder;
    };

    const generateDraft = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `statusreport-${inputTextName?.toLowerCase()}-draft.md`
        );

        if (statusReport?.lastPeriodText) {
            promptBuilder.writeln(
                '# The user has said they were working on this in the previous period:'
            );
            promptBuilder.writeln(statusReport?.lastPeriodText);
            promptBuilder.writeln();
        }

        if (statusReport?.nextPeriodText) {
            promptBuilder.writeln(
                '# The user has said they will be working on this in the next period:'
            );
            promptBuilder.writeln(statusReport?.nextPeriodText);
            promptBuilder.writeln();
        }

        if (statusReport?.risksAndOpportunitiesText) {
            promptBuilder.writeln(
                '# The user has identified these risks and opportunities:'
            );
            promptBuilder.writeln(statusReport?.risksAndOpportunitiesText);
            promptBuilder.writeln();
        }

        if (statusReport?.supportText) {
            promptBuilder.writeln('# The user has asked for support:');
            promptBuilder.writeln(statusReport?.supportText);
            promptBuilder.writeln();
        }

        const missionDetailsMd = await getMissionDetailsMd();

        const prompt = promptBuilder
            .toString()
            .replace('[[MISSION DETAILS]]', missionDetailsMd);

        setIsBuildingPrompt(false);

        executePrompt(`generateDraft${inputTextName}`, prompt);
    };

    const factCheck = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `statusreport-${inputTextName?.toLowerCase()}-factcheck.md`
        );
        const missionDetailsMd = await getMissionDetailsMd();
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText)
            .replace('[[MISSION DETAILS]]', missionDetailsMd);

        setIsBuildingPrompt(false);

        executePrompt(`factCheck${inputTextName}`, prompt);
    };

    const changeTone = async (toneType: string) => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `tone-change-${toneType}.md`
        );
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText);

        setIsBuildingPrompt(false);

        executePrompt(`${toneType}Tone`, prompt);
    };

    const simplify = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(`simplify.md`);
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText);

        setIsBuildingPrompt(false);

        executePrompt(`simplify`, prompt);
    };

    return {
        isEnabled,
        isDialogOpen,
        dismissDialog,
        maiContent,
        maiActions: maiActions || [],
        generateDraft,
        factCheck,
        changeTone,
        simplify,
        isStreaming,
        isBuildingPrompt,
    };
}
