import {
    Checkbox,
    Link,
    mergeStyleSets,
    FontSizes,
    Panel,
    PanelType,
    Label,
    Icon,
} from '@fluentui/react';
import orderBy from 'lodash/orderBy';
import { useStateContext } from '../../../services/contextProvider';
import {
    GetMeasurePublishedFactsQuery,
    useGetMeasurePublishedFactsQuery,
    useUpdateStatusReportFactActionsMutation,
} from '../../../data/types';
import {
    AdvanceCard,
    AdvanceCardActionButton,
} from '../../../components/AdvanceCard';
import { ActionDatePicker } from '../../StatusReports/components/ActionDatePicker';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from '../../../services/navigation';
import { FormEvent, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { TaskStatusColours } from '../../../Colours';

type FactType = ExtractQueryArrayType<GetMeasurePublishedFactsQuery, ['facts']>;

export function MeasureStatusReportActionsCard(props: {
    measureId: string | null | undefined;
    isReadOnly: boolean;
    isLoading: boolean;
}): JSX.Element | null {
    const { currentTenantId } = useStateContext();

    const [view, setView] = useState<'All' | 'Outstanding'>('All');

    const [isFactPanelVisible, setIsFactPanelVisible] =
        useState<FactType | null>(null);

    const { data, loading } = useGetMeasurePublishedFactsQuery({
        skip: !props.measureId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            measureId: props.measureId || '',
        },
    });

    const [updateStatusReportFactActions, { loading: isUpdatingFact }] =
        useUpdateStatusReportFactActionsMutation();

    const allActions =
        data?.facts.slice().flatMap((f) =>
            f.actions.map((a) => ({
                ...a,
                factId: f.id,
                statusReportId: f.statusReport?.id || null,
                statusReportTitle: f.statusReport?.title || null,
                statusReportDate: f.statusReport?.reportDate || null,
            }))
        ) || [];

    // Latest status report first, then the most due
    const actionsOrdered = orderBy(
        allActions.filter((a) => view === 'All' || !a.done),
        ['statusReportDate', 'due', 'name'],
        ['desc', 'asc', 'asc']
    );

    const handleFactActionDateChanged = useCallback(
        (
            factId: string,
            factActionId: string,
            updatedDateType: 'Due' | 'Done',
            updatedDate: string | null
        ) => {
            const fact = data?.facts.find((f) => f.id === factId);

            if (fact && currentTenantId) {
                updateStatusReportFactActions({
                    variables: {
                        tenantId: currentTenantId,
                        statusReportFactId: factId,
                        factActions: fact.actions.map((fa) => ({
                            done:
                                factActionId === fa.id &&
                                updatedDateType === 'Done'
                                    ? updatedDate
                                    : fa.done
                                      ? dayjs(fa.done).format('YYYY-MM-DD')
                                      : null,
                            due:
                                factActionId === fa.id &&
                                updatedDateType === 'Due'
                                    ? updatedDate
                                    : fa.due
                                      ? dayjs(fa.due).format('YYYY-MM-DD')
                                      : null,
                            id: fa.id,
                            name: fa.name,
                            sequence: fa.sequence,
                            statusReportFactId: fact.id,
                            version: fa.version,
                        })),
                    },
                });
            }
        },
        [currentTenantId, data?.facts, updateStatusReportFactActions]
    );

    const handleActionClicked = (factId: string) => {
        const fact = data?.facts.find((f) => f.id === factId);
        if (fact) {
            setIsFactPanelVisible(fact);
        }
    };

    const handleActionPanelDismiss = () => {
        setIsFactPanelVisible(null);
    };

    const buttons: AdvanceCardActionButton[] = [];

    if (props.measureId) {
        buttons.push({
            key: 'displayOptions',
            text: 'Filter',
            iconProps: { iconName: view === 'All' ? 'Filter' : 'FilterSolid' },
            menuProps: {
                items: [
                    {
                        key: 'ShowAll',
                        text: 'All',
                        onClick: () => setView('All'),
                    },
                    {
                        key: 'ShowOutstanding',
                        text: 'Outstanding',
                        onClick: () => setView('Outstanding'),
                    },
                ],
            },
        });
    }

    const classNames = mergeStyleSets({
        container: {
            maxHeight: 240,
            overflowY: 'auto',
        },
    });

    if (!props.measureId || loading || props.isLoading || !allActions.length) {
        return null;
    }

    return (
        <AdvanceCard title="Status Report Actions" buttons={buttons}>
            <AdvanceCard.Item>
                <div className={classNames.container}>
                    {actionsOrdered.map((a) => (
                        <MeasureStatusReportActionsCardItem
                            key={a.id}
                            actionItem={a}
                            isBusy={isUpdatingFact}
                            isReadOnly={props.isReadOnly}
                            onActionDateChanged={handleFactActionDateChanged}
                            onActionClicked={handleActionClicked}
                        />
                    ))}
                </div>

                <MeasureStatusReportActionsCardPanel
                    showPanel={!!isFactPanelVisible}
                    onDismiss={handleActionPanelDismiss}
                    fact={isFactPanelVisible}
                />
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}

function MeasureStatusReportActionsCardPanel(props: {
    showPanel: boolean;
    onDismiss: () => void;
    fact: FactType | null;
}) {
    const { fact } = props;

    const {
        currentFinancialYearCode,
        currentTenantCode,
        currentTeamCode,
        currentMissionId,
    } = useStateContext();

    const navigate = useNavigate();

    const path = fact?.statusReport?.id
        ? generatePath(paths.statusReport, {
              tenantCode: currentTenantCode,
              financialYearCode: currentFinancialYearCode,
              teamCode: currentTeamCode,
              missionId: currentMissionId,
              statusReportId: fact?.statusReport?.id,
          })
        : null;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            paddingTop: 16,
        },
    });

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={props.onDismiss}
            headerText="Status Report Action"
            closeButtonAriaLabel="Close"
            isFooterAtBottom
        >
            <div className={classNames.container}>
                {!!fact?.statusReport?.title && (
                    <div>
                        <Label>Source</Label>
                        <Link
                            onClick={() => {
                                if (path) {
                                    navigate(path);
                                }
                            }}
                        >
                            {fact?.statusReport?.title}{' '}
                            {fact?.statusReport.reportDate
                                ? `(${dayjs
                                      .utc(fact?.statusReport.reportDate)
                                      .format('DD MMM YYYY')})`
                                : null}
                        </Link>
                    </div>
                )}

                {!!fact?.soWhatText && (
                    <div>
                        <Label>So What</Label>
                        <div>{fact?.soWhatText}</div>
                    </div>
                )}

                {!!fact?.insightText && (
                    <div>
                        <Label>Insight</Label>
                        <div>{fact?.insightText}</div>
                    </div>
                )}
            </div>
        </Panel>
    );
}

function MeasureStatusReportActionsCardItem(props: {
    actionItem: {
        statusReportId: string | null;
        factId: string | null;
        id: string | null;
        name: string | null;
        due: string | null;
        done: string | null;
        statusReportTitle: string | null;
    };
    isReadOnly: boolean;
    isBusy: boolean;
    onActionDateChanged: (
        factId: string,
        factActionId: string,
        updatedDateType: 'Due' | 'Done',
        updatedDate: string | null
    ) => void;
    onActionClicked: (factId: string) => void;
}) {
    const { actionItem } = props;

    const handleCheckedChanged = (_ev?: FormEvent, checked?: boolean) => {
        if (actionItem.factId && actionItem.id) {
            props.onActionDateChanged(
                actionItem.factId,
                actionItem.id,
                'Done',
                checked ? dayjs().format('YYYY-MM-DD') : null
            );
        }
    };

    const handleDueDateChanged = (dueDate: string | null) => {
        if (actionItem.factId && actionItem.id) {
            props.onActionDateChanged(
                actionItem.factId,
                actionItem.id,
                'Due',
                dueDate
            );
        }
    };

    const statusColor = actionItem.done
        ? TaskStatusColours.completedOnTime
        : !actionItem.due
          ? TaskStatusColours.notStarted
          : dayjs(actionItem.due).isAfter(dayjs().utc())
            ? TaskStatusColours.onTarget
            : TaskStatusColours.offTarget;

    const classNames = mergeStyleSets({
        actionItem: {
            paddingLeft: 8,
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            paddingBottom: 16,
            marginRight: 32,
        },
        actionItemNameAndDate: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
        dueDateContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        dueDate: {
            minWidth: 80,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 6,
            fontSize: FontSizes.xSmall + ' !important',
            '& div, & span': {
                fontSize: FontSizes.xSmall + ' !important',
            },
            backgroundColor: statusColor + '36',
            padding: 2,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 4,
        },
        checkBox: {
            paddingTop: 2,
        },
    });

    return (
        <div className={classNames.actionItem} key={actionItem.id}>
            <div className={classNames.checkBox}>
                <Checkbox
                    checked={!!actionItem.done}
                    onChange={handleCheckedChanged}
                    disabled={props.isReadOnly || props.isBusy}
                />
            </div>

            <div className={classNames.actionItemNameAndDate}>
                <Link
                    onClick={() => {
                        if (actionItem.factId) {
                            props.onActionClicked(actionItem.factId);
                        }
                    }}
                >
                    {actionItem.name}
                </Link>

                <div className={classNames.dueDateContainer}>
                    <div className={classNames.dueDate}>
                        <Icon
                            iconName="Calendar"
                            title="Due"
                            aria-label="Due Date"
                        />
                        <ActionDatePicker
                            dateValue={actionItem.due}
                            isReadOnly={props.isReadOnly || props.isBusy}
                            isLoading={false}
                            onDateChanged={handleDueDateChanged}
                            placeholder="Due Date"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
