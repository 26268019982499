import React, { useState } from 'react';
import {
    ActionButton,
    IRenderFunction,
    ITextFieldProps,
    mergeStyleSets,
    Persona,
    PersonaSize,
} from '@fluentui/react';
import { MissionMentorDialog } from '../MissionMentorDialog';
import mai from '../../images/mai.png';

export function MissionMentorLabel(props: {
    textFieldProps: ITextFieldProps | undefined;
    defaultRender: IRenderFunction<ITextFieldProps> | undefined;
    onPromptSelected: (userPromptKey?: string) => void;
    content: JSX.Element | undefined;
    actions?: { name: string; action: () => void }[];
}): JSX.Element {
    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'end',
        },
        buttonContainer: {
            '& .ms-Image': {
                transition: 'transform 0.2s',
                transform: 'scale(0.9)',
                filter: 'grayscale(1) opacity(0.75)',
            },
            ':hover .ms-Image, .is-expanded .ms-Image': {
                transform: 'scale(1)',
                filter: 'grayscale(0%)',
            },
        },
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const openDialog = () => setIsDialogOpen(true);
    const dismissDialog = () => setIsDialogOpen(false);

    return (
        <>
            <div className={classNames.container}>
                <div>
                    {!!props.defaultRender &&
                        props.defaultRender(props.textFieldProps)}
                </div>
                <div className={classNames.buttonContainer}>
                    <ActionButton
                        onClick={openDialog}
                        styles={{
                            root: { height: '' },
                        }}
                    >
                        <Persona
                            imageUrl={mai}
                            text="Ask MAI"
                            size={PersonaSize.size24}
                            showSecondaryText={false}
                            styles={{
                                root: { margin: 0 },
                            }}
                        />
                    </ActionButton>
                </div>
            </div>
            <MissionMentorDialog
                hidden={!isDialogOpen}
                onDismiss={dismissDialog}
                onPromptSelected={props.onPromptSelected}
                content={props.content}
                actions={props.actions}
            />
        </>
    );
}
