import React, { useEffect, useState } from 'react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import {
    Icon,
    IconButton,
    IContextualMenuItem,
    mergeStyleSets,
    Separator,
    Text,
} from '@fluentui/react';
import { useThemes } from '../../../hooks/useThemes';
import { StatusReportActionPromoteDialog } from './StatusReportActionPromoteDialog';
import { ActionDatePicker } from './ActionDatePicker';

type ActionItem = {
    id: string | null;
    statusReportFactId: string;
    name: string | null;
    due: string | null;
    done: string | null;
    sequence: number;
    version: string | null;
};

type StatusReportActionListProps = {
    missionId: string;
    isReadOnly: boolean;
    isLoading: boolean;
    actions: ActionItem[];
    onChanged: (
        factId: string,
        newActions: {
            id: string | null;
            name: string | null;
            due: string | null;
            done: string | null;
            sequence: number;
        }[]
    ) => void;
};

export function StatusReportActionList(
    props: StatusReportActionListProps
): JSX.Element {
    const [actionList, setActionList] = useState<ActionItem[]>(props.actions);
    const [promotingAction, setPromotingAction] = useState<ActionItem>();

    useEffect(() => {
        setActionList(props.actions);
    }, [props.actions]);

    const updateActionsForFact = (
        actionId: string,
        transform: (a: ActionItem) => ActionItem
    ) => {
        const action = actionList.find((a) => a.id === actionId);
        if (!action) return;

        const updatedActionList = actionList.map((a) =>
            a.statusReportFactId === action.statusReportFactId
                ? transform(a)
                : a
        );

        const updatedActions = updatedActionList.filter(
            (a) => a.statusReportFactId === action.statusReportFactId
        );

        setActionList(updatedActionList);
        props.onChanged(action.statusReportFactId, updatedActions);
    };

    const handleDateChanged = (
        actionId: string,
        dateType: 'due' | 'done',
        dateValue: string | null
    ) => {
        updateActionsForFact(actionId, (a) => ({
            ...a,
            due: a.id === actionId && dateType === 'due' ? dateValue : a.due,
            done: a.id === actionId && dateType === 'done' ? dateValue : a.done,
        }));
    };

    const handleResetDates = (actionId: string) => {
        updateActionsForFact(actionId, (a) => ({
            ...a,
            due: a.id === actionId ? null : a.due,
            done: a.id === actionId ? null : a.done,
        }));
    };

    const handleTaskPromoted = () => {
        if (promotingAction) {
            // Remove the promoted fact action;
            const factId = promotingAction.statusReportFactId;
            props.onChanged(
                factId,
                actionList.filter(
                    (a) =>
                        a.statusReportFactId === factId &&
                        a.id !== promotingAction.id
                )
            );
        }

        setPromotingAction(undefined);
    };

    const handlePromotionDismissed = () => {
        setPromotingAction(undefined);
    };

    const { currentTheme } = useThemes();
    const classNames = mergeStyleSets({
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
        },

        actionTable: {
            width: '100%',
            backgroundColor: currentTheme.palette.white,
            borderRadius: 8,
            '& td, & th': {
                padding: 4,
            },
            '& tr:not(:last-child) td, & tr th': {
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: currentTheme.palette.neutralLighter,
            },
        },
    });

    return (
        <>
            <AdvanceCard style={{ height: '100%' }}>
                <AdvanceCard.Item>
                    <div className={classNames.header}>
                        <Icon iconName="CheckList" />
                        <Text variant="mediumPlus" block>
                            Actions
                        </Text>
                    </div>
                </AdvanceCard.Item>
                <AdvanceCard.Item fill>
                    <Separator
                        styles={{
                            root: {
                                padding: 0,
                            },
                        }}
                    />
                </AdvanceCard.Item>
                <AdvanceCard.Item fill>
                    <table className={classNames.actionTable}>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Due
                                </th>
                                <th scope="col" style={{ textAlign: 'right' }}>
                                    Done
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {actionList.map((a) => {
                                const menuItems: IContextualMenuItem[] = [
                                    {
                                        key: 'reset-dates',
                                        text: 'Clear dates',
                                        iconProps: {
                                            iconName: 'RemoveEvent',
                                        },
                                        onClick: () => {
                                            handleResetDates(a.id || '');
                                        },
                                    },
                                    {
                                        key: 'promote',
                                        text: 'Promote Task',
                                        iconProps: {
                                            iconName: 'Movers',
                                        },
                                        onClick: () => {
                                            setPromotingAction(a);
                                        },
                                    },
                                ];
                                return (
                                    <tr key={a.id}>
                                        <td>{a.name}</td>
                                        <td>
                                            <ActionDatePicker
                                                dateValue={a.due}
                                                isReadOnly={props.isReadOnly}
                                                isLoading={props.isLoading}
                                                onDateChanged={(dateValue) =>
                                                    handleDateChanged(
                                                        a.id || '',
                                                        'due',
                                                        dateValue
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <ActionDatePicker
                                                dateValue={a.done}
                                                isReadOnly={props.isReadOnly}
                                                isLoading={props.isLoading}
                                                onDateChanged={(dateValue) =>
                                                    handleDateChanged(
                                                        a.id || '',
                                                        'done',
                                                        dateValue
                                                    )
                                                }
                                            />
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            <IconButton
                                                iconProps={{
                                                    iconName: 'More',
                                                }}
                                                text="Clear"
                                                menuProps={{
                                                    items: menuItems,
                                                }}
                                                onRenderMenuIcon={() => null}
                                                styles={{
                                                    root: {
                                                        height: 16,
                                                        width: 16,
                                                    },
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </AdvanceCard.Item>
            </AdvanceCard>
            <StatusReportActionPromoteDialog
                statusReportAction={promotingAction}
                missionId={props.missionId}
                hidden={!promotingAction}
                onDismiss={handlePromotionDismissed}
                onTaskPromoted={handleTaskPromoted}
            />
        </>
    );
}
