import React, { useCallback, useMemo } from 'react';
import { Rating, Text, Label } from '@fluentui/react';
import {
    FrequencyPeriods,
    GetMissionQuery,
    ValueTypes,
    useGetDependenciesLazyQuery,
    useGetMeasureLazyQuery,
    useGetMissionMentorLazyQuery,
    useGetMissionTasksLazyQuery,
    useGetTeamMissionsLazyQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useThemes } from './useThemes';
import { sorters } from '../data/sorters';
import dayjs from 'dayjs';
import { useMeasureStatusCalc } from './useMeasureStatusCalc';
import { useFormatters } from './useFormatters';
import { getLabelForDate } from '../scenes/Measure/utils/measureUtils';
import { TaskStatusNames, taskStatusUtil } from '../data/extendedTypes';
import { useMaiWebSockets } from './useMaiWebSockets';
import { useMaiFileUpload } from './useMaiFileUpload';

const getRequiredEndPhrase = (
    mission: GetMissionQuery['mission']
): { hasRequiredPhrase: boolean; requiredEndPrase: string | undefined } => {
    let requiredEndPrase: string | undefined = undefined;

    const oneUpMission =
        mission?.team?.leaderMission &&
        mission?.team?.leaderMission.id !== mission.id
            ? mission?.team?.leaderMission
            : null;

    const oneUp =
        mission?.customMissionStatement1Up || oneUpMission?.missionStatement;

    if (oneUp) {
        const oneUpStatement = `in order to ${
            oneUp.indexOf('in order to') > 0
                ? oneUp
                      .substring(0, oneUp.indexOf('in order to'))
                      .replace(/,\s*$/, '') // any trailing comma
                      .replace(/^To\s*/, '') // remove To from the beginning
                      .trim()
                : oneUp
        }`;

        requiredEndPrase = oneUpStatement.trim().replace(/\.$/, '');
    }

    if (!oneUp && mission?.team?.division?.vision) {
        const oneUpStatement = `in order to ${mission?.team?.division?.vision
            .replace(/^To\s*/, '')
            .trim()}`;

        requiredEndPrase = oneUpStatement.trim().replace(/\.$/, '');
    }

    let hasRequiredPhrase = false;

    if (requiredEndPrase?.trim()) {
        if (mission?.missionStatement?.trim()) {
            if (
                mission.missionStatement
                    .trim()
                    .toUpperCase()
                    .replace(/\.$/, '')
                    .endsWith(requiredEndPrase?.toUpperCase())
            ) {
                hasRequiredPhrase = true;
            }
        }
    }

    return {
        hasRequiredPhrase,
        requiredEndPrase,
    };
};

export function useMissionMentor(): {
    isEnabled: boolean;
    isStreaming: boolean;
    contentMd: string;
    cancel: () => void;
    executePrompt: (promptName: string, prompt: string) => void;
    addTeamToPrompt: (
        promptBuilder: PromptBuilder,
        teamCode: string | null | undefined,
        fyCode: string | null | undefined,
        missionUserId: string | null | undefined
    ) => void;
    reviewMissionStatement: (missionData: GetMissionQuery) => Promise<{
        newMissionStatement: string | null;
        content: JSX.Element;
    }>;
    missionReport: (
        missionData: GetMissionQuery,
        userPromptKey: 'statusReport' | 'improveMission'
    ) => void;
    importFileAnalysis: (
        selectedMissionIds: string[],
        fileBlob: Blob | null
    ) => void;
    scanForMeasuresUsingAI: (fileBlob: Blob | null) => void;
} {
    const { isStreaming, contentMd, startStreaming, cancelStreaming } =
        useMaiWebSockets();

    const {
        isStreaming: isStreamingFromFileUpload,
        contentMd: contentMdFromFileUpload,
        startStreaming: startStreamingFromFileUpload,
        cancelStreaming: cancelStreamingFromFileUpload,
    } = useMaiFileUpload();

    const { currentRoles, currentUserEmail, currentTenantId, userTenants } =
        useStateContext();

    const { currentTheme } = useThemes();

    const [missionMentor] = useGetMissionMentorLazyQuery({
        fetchPolicy: 'network-only',
    });

    const cancel = useCallback(() => {
        cancelStreaming();
        cancelStreamingFromFileUpload();
    }, [cancelStreaming, cancelStreamingFromFileUpload]);

    const [getDependencies] = useGetDependenciesLazyQuery();

    const [getMeasure] = useGetMeasureLazyQuery();

    const [getMissionTasks] = useGetMissionTasksLazyQuery();

    const [getTeamMissions] = useGetTeamMissionsLazyQuery();

    const { getYtd, isCalcSupported } = useMeasureStatusCalc();

    const { formatMeasureValue, formatTaskPercentage } = useFormatters();

    //const [getAllMeasures] = useGetAllMeasuresLazyQuery();

    const buildMeasureTable = useCallback(
        async (
            promptBuilder: PromptBuilder,
            measureIds: { id: string | null }[],
            fyStartDate: string | undefined
        ) => {
            for (const m of measureIds) {
                const measureData = await getMeasure({
                    variables: {
                        id: m.id || '',
                        tenantId: currentTenantId || '',
                    },
                });

                const measure = measureData.data?.measure;

                if (!measure?.id || !fyStartDate) {
                    continue;
                }

                promptBuilder.writeln(
                    `### Measure of Success: ${measure.name}`
                );

                const headers = [
                    'Period',
                    'Period Target',
                    'Period Actual',
                    // 'Period Status',
                    // 'Period Trend',
                ];

                const hasYtd =
                    !measure.isCustom &&
                    measure.valueType === ValueTypes.Incremental &&
                    isCalcSupported(measure?.measureType);

                if (hasYtd) {
                    headers.push(...['YTD Target', 'YTD Actual']);
                }

                const rows: string[][] =
                    measure.valueHistory
                        ?.slice()
                        ?.sort(sorters.asOfDateSorter)
                        .map((asOf) => {
                            const period =
                                asOf.asOfDate &&
                                !measure.isCustom &&
                                measure.frequencyPeriod !==
                                    FrequencyPeriods.None
                                    ? getLabelForDate(
                                          fyStartDate,
                                          measure.frequencyPeriod,
                                          asOf.asOfDate,
                                          false
                                      )
                                    : dayjs(asOf.asOfDate).format(
                                          'DD MMM YYYY'
                                      ) || '';

                            let formattedTarget = '';
                            let formattedActual = '';
                            let formattedYtdTarget = '';
                            let formattedYtdActual = '';

                            // const trend = asOf?.arrowDirection || 'N/A';
                            // const status =
                            //     asOf?.arrowColour === Arrows.Red
                            //         ? 'OFF TARGET'
                            //         : asOf?.arrowColour === Arrows.Green
                            //           ? 'ON TARGET'
                            //           : asOf?.arrowColour === Arrows.Yellow
                            //             ? 'NEAR TARGET'
                            //             : 'N/A';

                            asOf.values?.forEach((v) => {
                                if (
                                    v?.seriesType?.name === 'Target' &&
                                    v?.formatStr
                                ) {
                                    formattedTarget = v?.formatStr;
                                } else if (
                                    v?.seriesType?.name === 'Actual' &&
                                    v?.formatStr
                                ) {
                                    formattedActual = v?.formatStr;
                                }
                            });

                            if (hasYtd) {
                                const ytd = getYtd(
                                    asOf,
                                    measure.valueHistory || []
                                );
                                const measureFormat = {
                                    ...measure,
                                    currencySymbol:
                                        measure.currency?.symbol || null,
                                };
                                formattedYtdTarget = formatMeasureValue(
                                    measureFormat,
                                    ytd.targetYtd
                                );
                                formattedYtdActual = formatMeasureValue(
                                    measureFormat,
                                    ytd.actualYtd
                                );
                            }

                            const row = [
                                period,
                                formattedTarget,
                                formattedActual,
                                // status,
                                // trend,
                            ];

                            if (hasYtd) {
                                row.push(
                                    ...[formattedYtdTarget, formattedYtdActual]
                                );
                            }
                            return row;
                        }) || [];

                if (rows.length) {
                    promptBuilder.writeMarkdownTable(headers, rows);
                    promptBuilder.writeln();
                    promptBuilder.writeln();
                }

                let comment =
                    measure.isLinked && measure.linkedFromMeasure?.lastComment
                        ? measure.linkedFromMeasure?.lastComment
                        : measure.lastComment;

                if (
                    measure.lastComment &&
                    dayjs(measure.lastComment.utcCreated).isAfter(
                        comment?.utcCreated
                    )
                ) {
                    comment = measure.lastComment;
                }

                if (comment) {
                    const lastComment = comment
                        ? `*${comment.text}* - By ${comment?.username} (${dayjs(
                              comment.utcCreated
                          ).format('DD MMM YYYY')})`
                        : '';

                    promptBuilder.writeln();
                    promptBuilder.writeln(`**Last Comment:**\n ${lastComment}`);
                    promptBuilder.writeln();
                }
            }

            promptBuilder.writeln();
        },
        [
            currentTenantId,
            formatMeasureValue,
            getMeasure,
            getYtd,
            isCalcSupported,
        ]
    );

    // const buildMeasureIdTable = useCallback(
    //     async (
    //         promptBuilder: PromptBuilder,
    //         measures: ExtractQueryArrayType<GetAllMeasuresQuery, ['measures']>[]
    //     ) => {
    //         promptBuilder.writeln(`### Measure of Success`);

    //         const headers = ['Measure Name', 'Id'];

    //         const rows: string[][] = measures.map((m) => {
    //             return [m.name || '', m.id || ''];
    //         });

    //         if (rows.length) {
    //             promptBuilder.writeMarkdownTable(headers, rows);
    //             promptBuilder.writeln();
    //             promptBuilder.writeln();
    //         }

    //         promptBuilder.writeln();
    //     },
    //     []
    // );

    const addTeamToPrompt = useCallback(
        async (
            promptBuilder: PromptBuilder,
            teamCode: string | null | undefined,
            fyCode: string | null | undefined,
            missionUserId: string | null | undefined
        ) => {
            if (!teamCode || !fyCode || !currentTenantId) {
                return;
            }

            const teamMissionsQuery = await getTeamMissions({
                variables: {
                    teamCode: teamCode,
                    financialYearCode: fyCode,
                    tenantId: currentTenantId,
                },
            });

            const teamMissions = teamMissionsQuery.data?.teams;

            teamMissions?.forEach((t) => {
                promptBuilder.writeln(`# ${t.name} Team`);

                const mission = teamMissions
                    .flatMap((tm) => tm.missions)
                    .find((m) => m.userId === missionUserId);

                if (t.leaderMission?.userId === missionUserId) {
                    promptBuilder.writeln(
                        `${t.leaderMission?.owner} is leader of the ${t.name} team. The other team members are:`
                    );
                } else if (mission?.owner) {
                    promptBuilder.writeln(
                        `${mission.owner} is a member of the ${t.name} team. The full team members are: `
                    );
                    promptBuilder.writeln(
                        `- Team Leader: ${t.leaderMission?.owner} (${t.leaderMission?.title})`
                    );
                }

                t.missions
                    .filter((tm) => tm.userId)
                    .filter((tm) => tm.userId !== t.leaderMission?.userId)
                    .sort(sorters.sequenceSorter)
                    .forEach((tm) => {
                        promptBuilder.writeln(`- ${tm.owner} (${tm.title})`);
                    });
            });

            promptBuilder.writeln();
        },
        [currentTenantId, getTeamMissions]
    );

    const addMissionToPrompt = useCallback(
        async (promptBuilder: PromptBuilder, missionData: GetMissionQuery) => {
            if (!currentTenantId) {
                throw 'Tenant not loaded';
            }

            if (!missionData.mission) {
                throw 'Mission not loaded';
            }

            const mission = missionData.mission;

            const team =
                mission.leaderOfTeams.find((t) => t.code) || mission.team;

            const measureGroups =
                missionData.measureGroups
                    ?.slice()
                    .sort(sorters.sequenceSorter) || [];
            const measures =
                missionData.measureSummary
                    ?.slice()
                    ?.sort(sorters.sequenceSorter) || [];

            const tasks =
                missionData.tasksSummary
                    .slice()
                    ?.filter((t) => !t.isDuplicate)
                    .sort(sorters.sequenceSorter) || [];

            const fyStartDate =
                mission?.team?.division?.financialYear?.startDate;
            const fyEndDate = mission?.team?.division?.financialYear?.endDate;

            if (mission.owner) {
                promptBuilder.writeln(`The mission owner is: ${mission.owner}`);
            }

            if (mission.title) {
                promptBuilder.writeln(`The mission title is: ${mission.title}`);
            }

            promptBuilder.writeln();

            promptBuilder.writeln(
                `The current date is: ${dayjs().format('DD MMM YYYY')}.`
            );

            promptBuilder.writeln();

            if (fyStartDate && fyEndDate) {
                const isCurrent = dayjs().isBetween(fyStartDate, fyEndDate);
                const isFuture = dayjs(fyStartDate).isAfter(dayjs());

                promptBuilder.writeln(
                    `The mission is for the ${
                        isCurrent ? 'current ' : isFuture ? 'next ' : ''
                    }financial year starting ${dayjs(fyStartDate).format(
                        'DD MMM YYYY'
                    )} and ending ${dayjs(fyEndDate).format('DD MMM YYYY')}`
                );

                if (isFuture) {
                    promptBuilder.writeln(
                        'The mission is in the planning phase.'
                    );
                }
            }

            promptBuilder.writeln();

            await addTeamToPrompt(
                promptBuilder,
                team?.code,
                mission.team?.division?.financialYear?.code,
                mission.userId
            );

            promptBuilder.writeln(`# Mission Statement`);
            if (mission?.missionStatement) {
                promptBuilder.writeln(`> ${mission.missionStatement}`);
            } else {
                promptBuilder.writeln('NOT SET');
            }

            promptBuilder.writeln();

            promptBuilder.writeln(`# Measures of Success`);
            promptBuilder.writeln();
            promptBuilder.writeln();

            if (!measures?.length) {
                promptBuilder.writeln('*NONE DEFINED*');
            } else {
                for (const g of measureGroups) {
                    promptBuilder.writeln(`## Group: ${g.name}`);
                    promptBuilder.writeln();

                    const groupMeasures = measures.filter(
                        (m) => m.measureGroupId === g.id
                    );

                    await buildMeasureTable(
                        promptBuilder,
                        groupMeasures,
                        fyStartDate
                    );
                    promptBuilder.writeln();
                    promptBuilder.writeln();
                }

                const unGroupedMeasures = measures.filter(
                    (m) => !m.measureGroupId
                );
                if (unGroupedMeasures.length) {
                    if (measureGroups?.length) {
                        promptBuilder.writeln(`## Others`);
                        promptBuilder.writeln();
                    }

                    await buildMeasureTable(
                        promptBuilder,
                        unGroupedMeasures,
                        fyStartDate
                    );
                    promptBuilder.writeln();
                    promptBuilder.writeln();
                }
                promptBuilder.writeln();
            }

            promptBuilder.writeln(`# Specified Tasks`);
            promptBuilder.writeln();

            if (!tasks?.length) {
                promptBuilder.writeln('NONE DEFINED');
            } else {
                const fullTasksQuery = await getMissionTasks({
                    variables: {
                        missionId: mission.id,
                        tenantId: currentTenantId,
                    },
                });

                const fullTasks = fullTasksQuery.data?.tasks;

                const specifiedTasks = tasks.filter(
                    (t) => t.parentTaskId === null
                );

                specifiedTasks?.forEach(async (st) => {
                    promptBuilder.writeln(`## ${st.name}`);
                    promptBuilder.writeln();

                    if (st.start) {
                        promptBuilder.writeln(
                            `Start: ${dayjs(st.start).format('DD MMM YYYY')}`
                        );
                    }
                    if (st.due) {
                        promptBuilder.writeln(
                            `Due: ${dayjs(st.due).format('DD MMM YYYY')}`
                        );
                    }

                    promptBuilder.writeln();

                    const fullSpecifiedTask = fullTasks?.find(
                        (ft) => ft.id === st.id
                    );

                    if (fullSpecifiedTask?.lastComment) {
                        promptBuilder.writeln('Last Comment:');
                        promptBuilder.writeln(
                            `> ${fullSpecifiedTask?.lastComment.text} - By ${
                                fullSpecifiedTask?.lastComment.username
                            } (${dayjs(
                                fullSpecifiedTask?.lastComment.utcCreated
                            ).format('DD MMM YYYY')})`
                        );
                        promptBuilder.writeln();
                    }

                    const impliedTasks = tasks.filter(
                        (it) => it.parentTaskId === st.id
                    );

                    const headers = [
                        'Implied Task',
                        // 'Start Date',
                        'Due Date',
                        // 'Done Date',
                        'Percent Complete',
                        'Status',
                        'Resources',
                        'Last Comment',
                    ];

                    const rows: string[][] = [];

                    impliedTasks.forEach((it) => {
                        const fullTask = fullTasks?.find(
                            (ft) => ft.id === it.id
                        );

                        // const start = it.start
                        //     ? dayjs(it.start).format('DD MMM YYYY')
                        //     : 'NONE';
                        const due = it.due
                            ? dayjs(it.due).format('DD MMM YYYY')
                            : 'NONE';
                        // const done = it.done
                        //     ? dayjs(it.done).format('DD MMM YYYY')
                        //     : 'NONE';
                        const percentComplete = formatTaskPercentage(
                            it.done ? 1 : it.percentComplete
                        );

                        const resources = fullTask?.resourcedTasks
                            .map(
                                (rt) =>
                                    `${
                                        rt.resource?.displayName
                                    } (${formatTaskPercentage(
                                        it.done ? 1 : it.percentComplete
                                    )} COMPLETE)\n`
                            )
                            .join(', ');

                        const statusName =
                            taskStatusUtil.GetTaskStatus(fullTask);

                        let status;

                        switch (statusName) {
                            case TaskStatusNames.NotStarted:
                                status = `NOT STARTED (${
                                    it.start
                                        ? `starts ${dayjs(it.start).fromNow()}`
                                        : 'no start date'
                                })`;
                                break;
                            case TaskStatusNames.OffTarget:
                                status = `OFF TARGET (${
                                    it.due
                                        ? `running ${dayjs(it.due).fromNow(
                                              true
                                          )} late`
                                        : 'no due date'
                                })`;
                                break;
                            case TaskStatusNames.OnTarget:
                                status = `ON TARGET (${
                                    it.due
                                        ? `due ${dayjs(it.due).fromNow()}`
                                        : 'no due date'
                                })`;
                                break;
                            case TaskStatusNames.CompletedLate:
                                status = `COMPLETED LATE (${
                                    it.due && it.done
                                        ? `${dayjs(it.due).from(
                                              it.done,
                                              true
                                          )} late`
                                        : 'no due date'
                                })`;
                                break;
                            case TaskStatusNames.CompletedOnTime:
                                status = 'COMPLETED ON TIME';
                                break;
                            case TaskStatusNames.AtRisk:
                                status = 'AT RISK';
                                break;
                            default:
                                status = statusName?.toUpperCase();
                                break;
                        }

                        const lastComment = fullTask?.lastComment
                            ? `${fullTask?.lastComment.text} - By ${
                                  fullTask?.lastComment.username
                              } (${dayjs(
                                  fullTask?.lastComment.utcCreated
                              ).format('DD MMM YYYY')})`
                            : '';

                        rows.push([
                            it.name || 'NO NAME',
                            // start,
                            due,
                            // done,
                            percentComplete,
                            status || 'UNKNOWN',
                            resources || '',
                            lastComment,
                        ]);
                    });

                    if (rows.length) {
                        promptBuilder.writeln(
                            'The actions required to complete this specified task:'
                        );
                        promptBuilder.writeMarkdownTable(headers, rows);
                    } else {
                        promptBuilder.writeln('No implied tasks');
                    }
                    promptBuilder.writeln();
                });
            }

            if (
                mission?.team?.division?.financialYear?.code &&
                mission.userId
            ) {
                const dependencyData = await getDependencies({
                    variables: {
                        tenantId: currentTenantId,
                        financialYearCode:
                            mission?.team?.division?.financialYear?.code,
                        userId: mission.userId,
                    },
                });

                const resourcedTasks =
                    dependencyData?.data?.dependencies?.filter((d) =>
                        // Does the task have an outstanding dependency for this mission user?
                        (d?.resourcedTasks || []).some(
                            (rt) =>
                                (!rt?.utcAccepted || rt?.missionId === null) &&
                                !rt?.utcRejected &&
                                rt?.resource?.userId &&
                                rt?.resource?.userId.toUpperCase() ===
                                    mission.userId?.toUpperCase()
                        )
                    ) || [];

                if (resourcedTasks?.length) {
                    promptBuilder.writeln();
                    promptBuilder.writeln('# Outstanding Requests');
                    promptBuilder.writeln(
                        `The mission has been requested as a resource on the following tasks, but has not yet accepted:`
                    );
                    resourcedTasks
                        .slice(0, 8)
                        .forEach((t) => promptBuilder.write(` - ${t.name}\n`));
                    promptBuilder.writeln();
                }
            }
        },
        [
            currentTenantId,
            addTeamToPrompt,
            buildMeasureTable,
            getMissionTasks,
            formatTaskPercentage,
            getDependencies,
        ]
    );

    const executePrompt = useCallback(
        async (promptName: string, prompt: string) => {
            startStreaming(promptName, prompt);
            console.log(prompt);
        },
        [startStreaming]
    );

    const missionReport = useCallback(
        async (
            missionData: GetMissionQuery,
            userPromptKey: 'statusReport' | 'improveMission'
        ) => {
            if (!missionData?.mission?.id) {
                throw 'Mission not loaded';
            }

            const mission = missionData.mission;

            const promptBuilder = new PromptBuilder();

            await addMissionToPrompt(promptBuilder, missionData);

            promptBuilder.writeln();
            promptBuilder.writeln('***');
            promptBuilder.writeln();

            if (userPromptKey === 'statusReport') {
                promptBuilder.writeln(
                    `Respond with the status of the mission owned by ${mission.owner} above under the following sections:`
                );

                promptBuilder.writeln();

                promptBuilder.writeln(`- Title 'Executive Summary'`);

                promptBuilder.writeln(
                    "- Title: 'So What?' (What is the deduction? What does this mean to us? Break down by measure of success and specified tasks if needed.)"
                );

                promptBuilder.writeln(
                    "- Title: 'Insights' (What could be the causes?)"
                );

                promptBuilder.writeln(
                    "- Title: 'Actions' (Does our analysis require any actions? If so, what are they?)"
                );

                promptBuilder.writeln(
                    "- Title: 'Support' (What is needed to advance the mission? Call out support from team members where needed.)"
                );
            } else if (userPromptKey === 'improveMission') {
                promptBuilder.writeln(
                    `Suggest ways the mission owned by ${mission.owner} above can be improved.`
                );

                promptBuilder.writeln();

                promptBuilder.writeln(
                    '- Ensure the mission statement is focused on what needs to be done, not how is done.'
                );

                promptBuilder.writeln(
                    `- Ensure the mission statement has a single-minded focus and is succinct.`
                );

                const { hasRequiredPhrase, requiredEndPrase } =
                    getRequiredEndPhrase(mission);

                if (requiredEndPrase) {
                    if (hasRequiredPhrase) {
                        promptBuilder.writeln(
                            `- The mission statement correctly ends with the required phrase: "${requiredEndPrase}"`
                        );
                    } else {
                        promptBuilder.writeln(
                            `- The mission statement does not end with the required phrase: "${requiredEndPrase}"`
                        );
                    }
                }

                promptBuilder.writeln(
                    `- Do not suggest a new mission statement, there is another tool for this.`
                );

                promptBuilder.writeln(
                    '- Measures of success should be critical metrics or KPIs gauging the impact of tasks and the overall success of the mission.'
                );

                promptBuilder.writeln(
                    '- Measures of success should be strategic, measurable, achievable and relevant.'
                );

                promptBuilder.writeln(
                    '- Specified Tasks should be key projects, initiatives, and activities that must be completed to deliver the mission.'
                );

                promptBuilder.writeln(
                    '- Specified Tasks are written as outcome statements, and their summation should deliver the mission.'
                );

                promptBuilder.writeln(
                    '- Implied Tasks should be clear, concise, and fulfill the Specified Task. They have should have a Start and Due date, and resourcing if needed.'
                );
            }

            promptBuilder.writeln();

            executePrompt(
                userPromptKey === 'statusReport'
                    ? 'Mission Report'
                    : 'Mission Improvement',
                promptBuilder.toString()
            );
        },
        [addMissionToPrompt, executePrompt]
    );

    const reviewMissionStatement = useCallback(
        async (missionData: GetMissionQuery) => {
            const mission = missionData.mission;

            const missionStatement = mission?.missionStatement?.trim();
            const mightBeMultipleObjective = missionStatement
                ? missionStatement
                      .substring(0, missionStatement.indexOf('in order to'))
                      .indexOf(' and ') > -1
                : false;

            const tasksNames = missionData.tasksSummary
                .slice()
                .filter((t) => t.parentTaskId === null && !t.isDuplicate)
                .slice(0, 8)
                .sort(sorters.sequenceSorter)
                .map((t) => t.name);

            let resourcedTaskNames: string[] = [];

            // Load the resourced tasks, if they don't have a task
            if (
                !tasksNames.length &&
                currentTenantId &&
                mission?.team?.division?.financialYear?.code &&
                mission.userId
            ) {
                const dependencyData = await getDependencies({
                    variables: {
                        tenantId: currentTenantId,
                        financialYearCode:
                            mission?.team?.division?.financialYear?.code,
                        userId: mission.userId,
                    },
                });

                resourcedTaskNames =
                    dependencyData?.data?.dependencies
                        ?.filter((d) =>
                            // Does the task have an outstanding dependency for this mission user?
                            (d?.resourcedTasks || []).some(
                                (rt) =>
                                    (!rt?.utcAccepted ||
                                        rt?.missionId === null) &&
                                    !rt?.utcRejected &&
                                    rt?.resource?.userId &&
                                    rt?.resource?.userId.toUpperCase() ===
                                        mission.userId?.toUpperCase()
                            )
                        )
                        .filter((t) => !t.utcPostponed && !t.utcCancelled)
                        .map((t) => t.name || '') || [];
            }

            const oneUpMission =
                mission?.team?.leaderMission &&
                mission?.team?.leaderMission.id !== mission.id
                    ? mission?.team?.leaderMission
                    : null;

            const oneUp =
                mission?.customMissionStatement1Up ||
                oneUpMission?.missionStatement;

            const { hasRequiredPhrase, requiredEndPrase } =
                getRequiredEndPhrase(mission);

            const promptBuilder = new PromptBuilder();

            promptBuilder.writeln(
                missionStatement
                    ? 'Follow the steps below to first evaluate and rate this mission statement and then suggest a new mission statement if needed.'
                    : 'Suggest a mission statement for this mission.'
            );

            promptBuilder.writeln();

            if (missionStatement) {
                promptBuilder.writeln(
                    `### Mission Statement\n> ${missionStatement}`
                );
            }

            if (mission?.title) {
                promptBuilder.writeln(`### Mission Title\n> ${mission.title}`);
            }

            if (oneUpMission?.title) {
                promptBuilder.writeln(
                    `### Reporting Manager's Title\n> ${oneUpMission?.title}`
                );
            }

            if (oneUp) {
                promptBuilder.writeln(
                    `### Reporting Manager's Mission Statement\n> ${oneUp}`
                );
            }

            if (!oneUp && mission?.team?.division?.vision) {
                promptBuilder.writeln(
                    `### Company Vision\n> ${mission?.team?.division?.vision}`
                );
            }

            if (tasksNames.length) {
                promptBuilder.writeln(`### Main Effort\n${tasksNames[0]}`);
            } else if (resourcedTaskNames.length) {
                promptBuilder.writeln(
                    `### Requested Tasks\nThe mission has been requested as a resource on the following tasks:\n${resourcedTaskNames
                        .map((t) => `- ${t}`)
                        .join('\n')}`
                );
            }

            promptBuilder.writeln();

            if (missionStatement) {
                promptBuilder.writeln(`# Step 1: Evaluation`);

                promptBuilder.writeln(
                    `Evaluate and rate the existing mission statement out of 5 based on this guidance. If the guidance has been met, score a rating of 5.`
                );
                promptBuilder.writeln(
                    `- Focused on what needs to be done, not how is done`
                );
                promptBuilder.writeln(`- An outcome statement`);
                promptBuilder.writeln(`- Single-minded focus and succinct`);
                promptBuilder.writeln(`- Single objective`);

                if (requiredEndPrase) {
                    if (hasRequiredPhrase) {
                        promptBuilder.writeln(
                            `- The mission statement correctly ends with the required phrase: "${requiredEndPrase}"`
                        );
                    } else {
                        promptBuilder.writeln(
                            `- The mission statement does not end with the required phrase: "${requiredEndPrase}"`
                        );
                    }
                }

                promptBuilder.writeln(
                    `${
                        mightBeMultipleObjective
                            ? '- Avoids including multiple objectives before the words in order to'
                            : 'NOTE: this mission statement does not include the word "and" which indicates a single objective'
                    }`
                );

                promptBuilder.writeln();

                promptBuilder.writeln(`# Step 2: Suggestion`);
                promptBuilder.writeln(
                    `After evaluation, if the rating is less than 5, suggest an improved mission statement based on the evaluation guidance above, it must also:`
                );
                promptBuilder.writeln(
                    `- Avoid conjunctions such as "and" and lists before the words "in order to" which often indicate multiple objectives.`
                );
                promptBuilder.writeln(
                    `- End EXACTLY with the phrase: "${requiredEndPrase}".`
                );
            } else {
                promptBuilder.writeln(`# Suggested Mission Statement`);
                promptBuilder.writeln(
                    `- Focused on what needs to be done, not how is done, an outcome statement                `
                );
                promptBuilder.writeln(
                    `- Single-minded focus and succinct                `
                );
                promptBuilder.writeln(`- Single objective`);
                promptBuilder.writeln(
                    `- Avoids conjunctions such as "and" and lists before the words "in order to" which often indicate multiple objectives.`
                );
                if (requiredEndPrase) {
                    promptBuilder.writeln(
                        `- Ends **exactly** with the required phrase: "${requiredEndPrase}".`
                    );
                }
            }

            promptBuilder.writeln();

            promptBuilder.writeln(
                `Keep the response brief without repeating unnecessary information. Respond in JSON format. Here's an example of your output format:`
            );

            if (missionStatement) {
                promptBuilder.writeln(`{
                "evaluation": "",
                "suggested_mission_statement_explanation": "",
                "suggested_mission_statement": "",
                "rating": 5
}`);
            } else {
                promptBuilder.writeln(`{
                "suggested_mission_statement_explanation": "",
                "suggested_mission_statement": ""
}`);
            }

            const result = await missionMentor({
                variables: {
                    promptName: 'Mission Statement',
                    prompt: promptBuilder.toString(),
                },
            });

            let evaluation: string | null = null;
            let explanation: string | null = null;
            let suggestedMissionStatement: string | null = null;
            let rating: number | null | undefined = null;

            let contentJson = result.data?.missionMentor;

            if (contentJson && contentJson.indexOf('```json') > -1) {
                contentJson = contentJson.replaceAll('```json', '');
                contentJson = contentJson.replaceAll('```', '');
            }

            if (contentJson) {
                const json = JSON.parse(contentJson);

                evaluation = json.evaluation;
                explanation = json.suggested_mission_statement_explanation;
                suggestedMissionStatement = json.suggested_mission_statement;
                rating = json.rating;
            }

            return {
                newMissionStatement: suggestedMissionStatement,
                content: (
                    <div>
                        {mission?.missionStatement?.trim() && (
                            <div>
                                <Label>Mission Statement:</Label>
                                <Text
                                    block
                                    style={{
                                        marginBottom: 8,
                                    }}
                                >
                                    {mission.missionStatement}
                                </Text>
                            </div>
                        )}

                        <div>
                            {rating !== null && rating !== undefined && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 8,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Label>Rating:</Label>
                                    <Rating
                                        rating={rating}
                                        max={5}
                                        allowZeroStars
                                        readOnly
                                    />
                                </div>
                            )}
                        </div>

                        {!!evaluation && (
                            <Text
                                block
                                style={{
                                    whiteSpace: 'pre-line',
                                    marginBottom: 8,
                                }}
                            >
                                {evaluation}
                            </Text>
                        )}

                        {!!explanation && (
                            <Text
                                block
                                style={{
                                    whiteSpace: 'pre-line',
                                    marginBottom: 8,
                                }}
                            >
                                {explanation}
                            </Text>
                        )}

                        {!!suggestedMissionStatement && (
                            <p
                                style={{
                                    padding: 8,
                                    backgroundColor:
                                        currentTheme.palette.themeLighterAlt,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor:
                                        currentTheme.palette.themeLighter,
                                }}
                            >
                                <Text
                                    block
                                    variant="xSmall"
                                    style={{
                                        marginBottom: 8,
                                    }}
                                >
                                    Suggested Mission Statement
                                </Text>

                                <Text block>{suggestedMissionStatement}</Text>
                            </p>
                        )}
                    </div>
                ),
            };
        },
        [currentTenantId, currentTheme.palette, getDependencies, missionMentor]
    );

    const importFileAnalysis = useCallback(
        async (selectedMissionIds: string[], fileBlob: Blob | null) => {
            const promptBuilder = new PromptBuilder();

            promptBuilder.writeln(
                'I will give you a table. Can you tell me how many rows and how many columns are in this table? Here is the table:'
            );
            promptBuilder.writeln();
            // const headers = ['Tablename', 'Col1', 'Col2'];
            // const rows = [
            //   ['Row1', '10', '10'],
            //   ['Row2', '20', '20'],
            //   ['Row3', '30', '30'],
            // ];
            // promptBuilder.writeMarkdownTable(headers, rows);

            // Using the Table method
            // promptBuilder.writeln('TableName   Col1  Col2');
            // promptBuilder.writeln('Row1   10  10');
            // promptBuilder.writeln('Row2   20  20');
            // promptBuilder.writeln('Row3   30  30');

            // const measureData = await getAllMeasures({
            //     variables: {
            //         tenantId: currentTenantId || '',
            //         teamId: null,
            //         financialYearCode: currentFinancialYearCode || null,
            //         taskCategoryIds: null,
            //         financialYearActiveDate: null,
            //         forDateTime: null,
            //     },
            // });

            // const missionId =
            //   selectedMissionIds.length > 0 ? selectedMissionIds[0] : '';

            // const { data: measureData, loading } = useGetMissionMeasuresQuery({
            //   variables: {
            //     tenantId: currentTenantId || '',
            //     missionId: missionId || '',
            //   },
            // });

            // const { loading, data, error, refetch } = useGetMissionQuery({
            //   skip: !missionId || !currentTenantId,
            //   variables: {
            //     tenantId: currentTenantId || '',
            //     missionId: missionId || '',
            //   },
            // });

            // const missionData: GetMissionQuery = null;
            // const mission = missionData.mission;
            // const fyStartDate =
            //   mission?.team?.division?.financialYear?.startDate;
            // const measures =
            //   missionData.measureSummary
            //     ?.slice()
            //     ?.sort(sorters.sequenceSorter) || [];
            //
            // const fyStartDate: string | undefined = undefined;

            // var measures = measureData.data?.measures;
            // console.log('selectedMissionIds', selectedMissionIds);

            // if (selectedMissionIds.length > 0 && measures) {
            //     console.log('yeah I am going in here afterall');
            //     measures = measures.filter(
            //         (m) =>
            //             !selectedMissionIds ||
            //             selectedMissionIds.some((k) => k == m.mission?.id)
            //     );
            // }
            // console.log('filtered measures', measures);

            // buildMeasureIdTable(promptBuilder, measures || []);

            promptBuilder.writeln();
            promptBuilder.writeln('Please can you also print the table.');
            // promptBuilder.writeln( 'Please do not print the table in your answer.');

            startStreamingFromFileUpload(
                'importFileAnalysis',
                promptBuilder.toString(),
                'mai-import',
                fileBlob
            );
        },
        [startStreamingFromFileUpload]
    );

    const scanForMeasuresUsingAI = (fileBlob: Blob | null) => {
        startStreamingFromFileUpload(
            'new-measure-with-ai-scan',
            '',
            'mai-backend',
            fileBlob
        );
    };

    const userTenant = userTenants.find(
        (ut) => ut.tenantId === currentTenantId
    );
    const isMaiEnabled = useMemo(() => {
        return (
            userTenant?.tenant.isMaiEnabled ||
            currentRoles?.some((r) => r === 'Developer') ||
            currentUserEmail?.toLocaleLowerCase() ===
                'jweaver1@gpstrategies.com' ||
            currentUserEmail?.toLocaleLowerCase() === 'vswart@gpstrategies.com'
        );
    }, [currentRoles, currentUserEmail, userTenant?.tenant.isMaiEnabled]);

    return {
        contentMd: contentMd || contentMdFromFileUpload,
        isStreaming: isStreaming || isStreamingFromFileUpload,
        cancel: cancel,
        isEnabled: isMaiEnabled,
        executePrompt: executePrompt,
        addTeamToPrompt: addTeamToPrompt,
        missionReport,
        reviewMissionStatement,
        importFileAnalysis,
        scanForMeasuresUsingAI,
    };
}

export class PromptBuilder {
    private _lines: string[] = [];

    write(line = ''): void {
        this._lines.push(line);
    }

    writeln(line = ''): void {
        this._lines.push(line);
        this._lines.push('\n');
    }

    clear(): void {
        this._lines = [];
    }

    toString(): string {
        return this._lines.join('');
    }

    writeMarkdownTable = (headers: string[], rows: string[][]) => {
        // Function to escape individual cell content
        const escapeCell = (cell: string) =>
            cell
                .replace(/\\/g, '\\\\')
                .replace(/\|/g, '\\|')
                .replace(/\r?\n|\r/g, ' ');

        // Create the header row
        let table = `| ${headers
            .map((header) => escapeCell(header))
            .join(' | ')} |\n`;

        // Create the separator row
        table += `| ${headers.map(() => '---').join(' | ')} |\n`;

        // Add each row
        for (const row of rows) {
            table += `| ${row.map((cell) => escapeCell(cell)).join(' | ')} |\n`;
        }

        this._lines.push(table);
        this._lines.push('\n');
    };
}
