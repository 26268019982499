import React, { useState, useEffect } from 'react';
import {
    ActivityItem,
    IColumn,
    SelectionMode,
    ShimmeredDetailsList,
} from '@fluentui/react';

import {
    FrequencyPeriods,
    GetMeasureHistoryQuery,
    useGetCommentsQuery,
    useGetCurrenciesQuery,
    useGetFinancialYearsQuery,
    useGetMeasureAttachmentsQuery,
    useGetMeasureHistoryQuery,
    useGetSeriesValueHistoryQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';

import dayjs from 'dayjs';

import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../data/extendedTypes';
import { useAttachmentIcons } from '../hooks/useAttachmentIcons';
import AttachmentLink from './AttachmentLink';
import { HistoryCoin } from './HistoryCoin';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import { useThemes } from '../hooks/useThemes';
import { getLabelForDate } from '../scenes/Measure/utils/measureUtils';
import { useFormatters } from '../hooks/useFormatters';
import { useLanguage } from '../services/i18n';
import { useComments } from '../hooks/useComments';

export default function MeasureHistoryFeed(props: {
    measureId: string;
}): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { currentTheme } = useThemes();

    const { t } = useLanguage();

    const attachmentIcons = useAttachmentIcons();

    const formatters = useFormatters();

    const { renderCommentText } = useComments();

    const [linkedFromMeasure, setLinkedFromMeasure] = useState<{
        id: string;
        dateLinked: string;
    } | null>();

    interface FeedItem {
        key: string;
        type: string;
        person: string | undefined;
        actionText: string;
        text?: string | React.ReactNode;
        timeStamp: string;
        userId: string | undefined;
        iconName: string | undefined;
        getText?: () => string | React.ReactNode;
    }

    type HistoryItem = ExtractQueryArrayType<
        GetMeasureHistoryQuery,
        ['measureHistory']
    >;

    const getFrequencyName = (measure: HistoryItem) => {
        return measure.isCustom
            ? 'Custom'
            : measure.frequencyPeriod === FrequencyPeriods.Month
              ? 'Monthly'
              : measure.frequencyPeriod === FrequencyPeriods.Quarter
                ? 'Quarterly'
                : measure.frequencyPeriod === FrequencyPeriods.Year
                  ? 'Yearly'
                  : measure.frequencyPeriod === FrequencyPeriods.None
                    ? 'Intermittent'
                    : 'Unknown';
    };

    const feedColumns: IColumn[] = [
        {
            key: 'text',
            name: 'text',
            fieldName: 'text',
            minWidth: 100,
            isMultiline: true,
        },
    ];

    const { data: measureHistoryData, loading } = useGetMeasureHistoryQuery({
        fetchPolicy: 'no-cache',
        skip: !props.measureId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const { data: seriesValueHistoryData, loading: valuesLoading } =
        useGetSeriesValueHistoryQuery({
            fetchPolicy: 'no-cache',
            skip: !props.measureId && !linkedFromMeasure?.id,
            variables: {
                tenantId: currentTenantId || '',
                measureId: linkedFromMeasure?.id || props.measureId,
            },
        });

    const { data: commentsData, loading: commentsLoading } =
        useGetCommentsQuery({
            fetchPolicy: 'cache-and-network',
            variables: {
                tenantId: currentTenantId || '',
                taskId: null,
                measureId: props.measureId,
            },
        });

    const { data: attachmentsData, loading: attachmentsLoading } =
        useGetMeasureAttachmentsQuery({
            fetchPolicy: 'cache-and-network',
            variables: {
                tenantId: currentTenantId || '',
                measureId: props.measureId,
            },
        });

    const { data: currencyData } = useGetCurrenciesQuery({
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const { data: financialYearData } = useGetFinancialYearsQuery({
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const fyStartDate = financialYearData?.financialYears?.find(
        (fy) =>
            fy.code?.toUpperCase() === currentFinancialYearCode?.toUpperCase()
    )?.startDate;

    const feedItems: FeedItem[] = [];

    commentsData?.comments?.forEach((c) => {
        feedItems.push({
            key: `comment_${c.id}`,
            type: 'comment',
            person: c.username || unknownUserDisplayName,
            userId: c.userId || '',
            actionText: 'commented',
            iconName: 'Message',
            timeStamp: c.utcCreated,
            getText: () => renderCommentText(c.text),
        });
    });

    attachmentsData?.measure?.attachments?.forEach((attachment) => {
        feedItems.push({
            key: `attachment_${attachment.id}`,
            type: 'attachment',
            person: attachment.username ?? unknownUserDisplayName,
            userId: attachment.userId || '',
            actionText: attachment.isFile
                ? 'attached a file'
                : 'attached a link',
            text: <AttachmentLink attachment={attachment} />,
            iconName: attachmentIcons.getIconName(attachment),
            timeStamp: attachment.utcCreated,
        });
    });

    const unknownUserDisplayName = 'A system process';

    const checkRename = (before: HistoryItem, after: HistoryItem) => {
        if (before.name !== after.name) {
            feedItems.push({
                key: `rename_${after.utcUpdated?.toString()}`,
                type: 'rename',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Rename',
                actionText: 'renamed',
                text: `The measure was renamed from ${before.name} to ${after?.name}.`,
                timeStamp: after.sysStartTime,
            });
        }
    };

    const checkUpgrade = (before: HistoryItem, after: HistoryItem) => {
        if (before.isCustom && !after.isCustom) {
            feedItems.push({
                key: `upgraded_${after.utcUpdated?.toString()}`,
                type: 'upgrade',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'DoubleChevronUp8',
                actionText: 'upgraded',
                text: `The measure was upgraded with the frequency ${getFrequencyName(
                    after
                )}`,
                timeStamp: after.sysStartTime,
            });
        }
    };

    const checkFrequency = (before: HistoryItem, after: HistoryItem) => {
        // Not an upgrade, but a frequency change
        if (
            before.isCustom === after.isCustom &&
            before.frequencyPeriod !== after.frequencyPeriod
        ) {
            feedItems.push({
                key: `frequency_${after.utcUpdated?.toString()}`,
                type: 'frequency',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Calendar',
                actionText: 'changed the frequency',
                text: `The measure was frequency was change from ${getFrequencyName(
                    before
                )} to ${getFrequencyName(after)}`,
                timeStamp: after.sysStartTime,
            });
        }
    };

    const checkUnlink = (before: HistoryItem, after: HistoryItem) => {
        if (before.isLinked && !after.isLinked) {
            feedItems.push({
                key: `unlink_${after.utcUpdated?.toString()}`,
                type: 'unlink',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'RemoveLink',
                actionText: 'removed the link',
                text: `The measure's link to another measure of success was removed.`,
                timeStamp: after.sysStartTime,
            });
        }
    };

    useEffect(() => {
        const measureHistory = measureHistoryData?.measureHistory || [];
        if (measureHistory !== null) {
            if (
                measureHistory.length &&
                measureHistory[0].isLinked &&
                measureHistory[0].linkedFromMeasureId
            ) {
                setLinkedFromMeasure({
                    id: measureHistory[0].linkedFromMeasureId,
                    dateLinked:
                        measureHistory.find((h) => !h.isLinked)?.sysEndTime || // find when it wasn't linked before
                        measureHistory[measureHistory.length - 1].sysStartTime, // or its always been linked
                });
            } else {
                setLinkedFromMeasure(null);
            }
        }
    }, [measureHistoryData?.measureHistory]);

    if (measureHistoryData?.measureHistory !== null) {
        const measureHistory = measureHistoryData?.measureHistory || [];

        for (let index = 0; index < measureHistory.length; index++) {
            const h = measureHistory[index as number];
            const previous = measureHistory[index + 1];
            if (h !== null && previous) {
                checkRename(previous, h);
                checkUpgrade(previous, h);
                checkFrequency(previous, h);
                checkUnlink(previous, h);
            }
        }

        if (
            measureHistory.length &&
            measureHistory[measureHistory.length - 1].sysStartTime
        ) {
            const createdEntry = measureHistory[measureHistory.length - 1];

            feedItems.push({
                key: `created`,
                type: 'created',
                person:
                    createdEntry?.updatedByUserName || unknownUserDisplayName,
                userId: createdEntry?.updatedByUserId || undefined,
                iconName: 'Add',
                actionText: 'created this measure of success.',
                text: createdEntry.isLinked
                    ? `This measure of success was linked from another mission.`
                    : `This measure of success was created.`,
                timeStamp: createdEntry.sysStartTime,
            });
        }
    }

    const history = linkedFromMeasure?.dateLinked
        ? seriesValueHistoryData?.seriesValueHistory.filter((h) =>
              dayjs(h.sysStartTime).isAfter(linkedFromMeasure?.dateLinked)
          ) || []
        : seriesValueHistoryData?.seriesValueHistory || [];

    const historyGrouped = groupBy(history, function (h) {
        return `${
            h.sysStartTime
        }|${h.updatedByUserId || ''}|${h.updatedByUserName || ''}`;
    });

    const asOfs = history.filter((h) => h.asOf).map((h) => h.asOf);

    for (const groupKey in historyGrouped) {
        const sysStartTime = groupKey.split('|')[0];
        const updatedByUserId = groupKey.split('|')[1];
        const updatedByUserName = groupKey.split('|')[2];
        const historyForTime = historyGrouped[groupKey as string];

        const getText = () => {
            const seriesTypes: string[] = [];

            const seriesTypesInUse = [
                ...new Set([...historyForTime.map((h) => h.seriesType?.name)]),
            ];

            if (
                seriesTypesInUse.some(
                    (st) =>
                        st === MeasureSeriesNames.Target ||
                        st === MeasureSeriesNames.Actual
                )
            ) {
                seriesTypes.push(
                    ...[MeasureSeriesNames.Target, MeasureSeriesNames.Actual]
                );
            }

            if (
                seriesTypesInUse.some(
                    (st) => st === MeasureSeriesNames.PhasedTarget
                )
            ) {
                seriesTypes.push(...[MeasureSeriesNames.PhasedTarget]);
            }

            if (
                seriesTypesInUse.some(
                    (st) => st === MeasureSeriesNames.PhasedForecast
                )
            ) {
                seriesTypes.push(...[MeasureSeriesNames.PhasedForecast]);
            }

            const asOfDates = [
                ...new Set(
                    orderBy(
                        historyForTime
                            .filter((h) => h?.asOf?.asOfDate)
                            .map((h) => h.asOf?.asOfDate),
                        String,
                        'desc'
                    )
                ),
            ];

            const measureForDate = measureHistoryData?.measureHistory.find(
                (m) =>
                    dayjs(sysStartTime).isBetween(
                        m.sysStartTime,
                        m.sysEndTime,
                        null,
                        '[)' // inclusive
                    )
            );

            if (!fyStartDate) {
                return;
            }

            return (
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th></th>
                            {seriesTypes.map((st) => {
                                let seriesName: string;

                                switch (st) {
                                    case MeasureSeriesNames.PhasedForecast:
                                        seriesName = t(
                                            'measure-of-success.forecast'
                                        );
                                        break;
                                    case MeasureSeriesNames.PhasedTarget:
                                        seriesName = 'Phasing Target';
                                        break;
                                    default:
                                        seriesName = st || 'Unknown';
                                }

                                return <th key={st}>{seriesName}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {asOfDates.map((asOfDate) => {
                            const thisAsOf = asOfs.find(
                                (a) =>
                                    a?.asOfDate === asOfDate &&
                                    a?.sysStartTime === sysStartTime
                            );

                            const previousAsOf = asOfs.find(
                                (a) =>
                                    a?.id === thisAsOf?.id &&
                                    a?.sysEndTime === sysStartTime
                            );

                            return (
                                <tr key={asOfDate}>
                                    <td>
                                        {!!previousAsOf &&
                                            previousAsOf.asOfDate !==
                                                asOfDate && (
                                                <s>
                                                    {getLabelForDate(
                                                        fyStartDate,
                                                        measureForDate?.isCustom ||
                                                            !measureForDate
                                                            ? FrequencyPeriods.None
                                                            : measureForDate.frequencyPeriod,
                                                        previousAsOf.asOfDate
                                                    )}
                                                </s>
                                            )}

                                        {getLabelForDate(
                                            fyStartDate,
                                            measureForDate?.isCustom ||
                                                !measureForDate
                                                ? FrequencyPeriods.None
                                                : measureForDate.frequencyPeriod,
                                            asOfDate || ''
                                        )}
                                    </td>

                                    {seriesTypes.map((st) => {
                                        const h = historyForTime.find(
                                            (t) =>
                                                t.seriesType?.name === st &&
                                                t.asOf?.asOfDate === asOfDate
                                        );

                                        if (!h) {
                                            return (
                                                <td
                                                    key={`${asOfDate}_${st}`}
                                                ></td>
                                            );
                                        }

                                        const previous = history.find(
                                            (p) =>
                                                (p.asOf?.id === h.asOf?.id ||
                                                    p.asOf?.asOfDate ===
                                                        h.asOf?.asOfDate) &&
                                                !p.utcDeleted &&
                                                !p.asOf?.utcDeleted &&
                                                p.sysStartTime !==
                                                    h.sysStartTime &&
                                                p.sysEndTime ===
                                                    h.sysStartTime &&
                                                p.seriesType?.name ===
                                                    h.seriesType?.name
                                        );

                                        const valueFormatted = measureForDate
                                            ? h.stringValue ||
                                              h.dateValue ||
                                              formatters.formatMeasureValue(
                                                  {
                                                      ...measureForDate,
                                                      currencySymbol:
                                                          currencyData?.currencies.find(
                                                              (c) =>
                                                                  c.code ==
                                                                  measureForDate.currencyCode
                                                          )?.symbol || null,
                                                  },
                                                  h.decimalValue
                                              )
                                            : null;

                                        const previousFormatted =
                                            previous && measureForDate
                                                ? previous.stringValue ||
                                                  previous.dateValue ||
                                                  formatters.formatMeasureValue(
                                                      {
                                                          ...measureForDate,
                                                          currencySymbol:
                                                              currencyData?.currencies.find(
                                                                  (c) =>
                                                                      c.code ==
                                                                      measureForDate.currencyCode
                                                              )?.symbol || null,
                                                      },
                                                      previous.decimalValue
                                                  )
                                                : null;

                                        if (
                                            h.utcDeleted ||
                                            h.asOf?.utcDeleted ||
                                            (h.decimalValue === null &&
                                                h.stringValue === null &&
                                                h.dateValue === null &&
                                                (previous?.decimalValue !==
                                                    null ||
                                                    previous?.dateValue !==
                                                        null ||
                                                    previous?.dateValue ===
                                                        null))
                                        ) {
                                            return (
                                                <td key={`${asOfDate}_${st}`}>
                                                    <s>{previousFormatted}</s>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={`${asOfDate}_${st}`}>
                                                    {previousFormatted &&
                                                    previousFormatted !==
                                                        valueFormatted ? (
                                                        <s>
                                                            {previousFormatted}
                                                        </s>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {valueFormatted}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        };

        const feedItem = {
            key: `values_${groupKey}`,
            type: 'valuechanged',
            person: updatedByUserName,
            userId: updatedByUserId || undefined,
            iconName: 'Table',
            actionText: updatedByUserName
                ? 'updated values.'
                : 'Values were updated.',
            timeStamp: sysStartTime,
            getText: getText,
        };

        feedItems.push(feedItem);
    }

    function renderItemColumn(
        item?: FeedItem,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }

        switch (column.key) {
            case 'text':
                return (
                    <ActivityItem
                        styles={{
                            // workaround for ActivityItem not using the theme
                            root: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                            commentText: {
                                color: currentTheme.palette.neutralPrimary,
                                whiteSpace: 'pre-line',
                            },
                            activityContent: {
                                width: '100%',
                            },
                            timeStamp: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                        }}
                        activityDescription={`${item.person} ${item.actionText}`}
                        activityIcon={
                            item.iconName && (
                                <HistoryCoin
                                    iconName={item.iconName}
                                    userDisplayName={
                                        item.person || unknownUserDisplayName
                                    }
                                    userId={item.userId}
                                />
                            )
                        }
                        comments={item.getText ? item.getText() : item.text}
                        timeStamp={dayjs.utc(item.timeStamp).fromNow()}
                    />
                );

            default:
                return <span></span>;
        }
    }

    const orderedItems = orderBy(feedItems, ['timeStamp'], 'desc');

    return (
        <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            isHeaderVisible={false}
            items={orderedItems}
            enableShimmer={
                loading ||
                commentsLoading ||
                attachmentsLoading ||
                valuesLoading
            }
            columns={feedColumns}
            onRenderItemColumn={renderItemColumn}
        />
    );
}
