import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStateContext } from '../../services/contextProvider';
import {
    generatePath,
    useNavigate,
    useParams,
    useMatch,
} from 'react-router-dom';

import {
    Stack,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    ActionButton,
    FontSizes,
    IButtonStyles,
    Link,
    Persona,
    PersonaSize,
} from '@fluentui/react';

import {
    useGetMissionQuery,
    useToggleTagLinkMutation,
    refetchGetMeasureQuery,
} from '../../data/types';

import MissionCard from './components/MissionCard';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import TaskList from './components/TaskList';
import { AreaContainer, AreaContainerButton } from './components/AreaContainer';
import HeaderSummaryBar from './components/HeaderSummaryBar';
import EditMeasureGroupsPanel from '../../components/EditMeasureGroupsPanel';

import { useTaskSummary } from '../../hooks/useTaskSummary';
import { UnlinkMeasureDialog } from '../../components/UnlinkMeasureDialog';
import RejectedResourcePanel from '../../components/RejectedResourcePanel';
import {
    useTaskExpandActionsContext,
    withTaskExpand,
} from '../../TaskExpandContext';
import { navigation, PathParams, paths } from '../../services/navigation';
import {
    missionAnalysisExport,
    missionSummaryExport,
} from '../../services/exporter';
import { useActiveView } from '../../hooks/useActiveView';
import { useLanguage } from '../../services/i18n';
import { useViewport } from '../../hooks/useViewport';
import { useViewModeDropDownButton } from '../../hooks/useViewModeDropDownButton';
import { Measure } from '../Measure/Measure';
import { MeasureFormulaEditor } from '../MeasureFormulaEditor/MeasureFormulaEditor';
import { useMissionMentor } from '../../hooks/useMissionMentor';
import { MissionMentorDialog } from '../../components/MissionMentorDialog';
import { Markdown } from '../Presentations/Markdown';
import { useTaskTileColumnPreferences } from '../../hooks/useColumnPreferences';
import { TaskRatingsActionButton } from './components/TaskRatingsActionButton';
import { MissionBuilderMeasureArea } from './components/MissionBuilderMeasureArea';
import mai from '../../images/mai.png';

type TaskViewModes = 'Tile' | 'Gantt' | 'List';
const taskViewModes: {
    [key in TaskViewModes]: {
        text: string;
        iconName: string;
    };
} = {
    ['Tile']: {
        text: 'Tile',
        iconName: 'RectangleShape',
    },
    ['Gantt']: {
        text: 'Gantt',
        iconName: 'TimelineProgress',
    },
    ['List']: {
        text: 'List',
        iconName: 'ViewList',
    },
};

function MissionBuilder(): JSX.Element {
    const params = useParams();

    const { t } = useLanguage();

    const {
        missionId,
        teamCode,
        financialYearCode,
        measureId: activeMeasureId,
    }: PathParams = params;

    const navigate = useNavigate();

    const { width } = useViewport();
    const breakpoint = 620;
    const isMobileMode = width < breakpoint;

    const missionBuilderSpecifiedTaskMatch = useMatch({
        path: paths.missionBuilderSpecifiedTask,
        end: true,
    });

    const missionBuilderImpliedTaskMatch = useMatch({
        path: paths.missionBuilderImpliedTask,
        end: true,
    });

    const missionBuilderMeasureMatch = useMatch({
        path: paths.missionBuilderMeasure,
        end: true,
    });

    const missionBuilderMeasureFormulaMatch = useMatch({
        path: paths.missionBuilderMeasureFormulaEditor,
        end: true,
    });

    const { expandTasks, collapseTasks, expandTask, getExpandedTaskIds } =
        useTaskExpandActionsContext();

    const {
        selectedColumnNames: selectedTileColumnNames,
        toggleColumn: toggleTileColumn,
    } = useTaskTileColumnPreferences();

    const [missionAreaState, setMissionAreaState] = useState<
        'expanded' | 'collapsed'
    >('collapsed');

    const [taskAreaState, setTaskAreaState] = useState<
        'expanded' | 'collapsed'
    >('expanded');

    const [isExporting, setIsExporting] = useState(false);
    const [isSummaryExporting, setIsSummaryExporting] = useState(false);

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMeasureGroupId, setActiveMeasureGroupId] = useState<
        string | null
    >();

    const [showTaskFilter, setShowTaskFilter] = useState<boolean>(false);

    const [taskFiltersActive, setTaskFiltersActive] = useState<boolean>(false);

    const [isClosingMeasure, setIsClosingMeasure] = useState(false);

    const { currentTenantId, currentUserId, configuration } = useStateContext();

    const { loading, data, error, refetch } = useGetMissionQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
    });

    const {
        missionReport,
        contentMd,
        isEnabled: isMissionMentorEnabled,
        isStreaming,
        cancel: cancelMissionMentor,
    } = useMissionMentor();

    const [isMissionMentorDialogOpen, setMissionMentorIsDialogOpen] =
        useState(false);

    const [chatContent, setChatContent] = useState<JSX.Element>();

    useEffect(() => {
        let markdown = contentMd;

        if (markdown.indexOf('```markdown') > -1) {
            markdown = markdown.replaceAll('```markdown', '');
            markdown = markdown.replaceAll('```', '');
        }

        if (markdown) {
            if (isStreaming) {
                markdown = markdown + ' ⬤';
            }

            setChatContent(<Markdown source={markdown} />);
        } else if (isStreaming) {
            setChatContent(<p className="cursor">⬤</p>);
        } else {
            setChatContent(undefined);
        }
    }, [contentMd, isStreaming]);

    const isMeasureCustom = useCallback(
        (measureId: string) => {
            return (
                !!measureId &&
                data?.measureSummary?.find((m) => m.id === measureId)?.isCustom
            );
        },
        [data?.measureSummary]
    );

    const [showMeasureUnlink, setShowMeasureUnlink] = useState(false);

    useEffect(() => {
        if (
            activeMeasureId &&
            !activePanel &&
            !showMeasureUnlink &&
            !isClosingMeasure &&
            isMeasureCustom(activeMeasureId)
        ) {
            // Measure in parameters so open the oveview.
            setActivePanel('measure-overview');
        }
    }, [
        activePanel,
        activeMeasureId,
        showMeasureUnlink,
        isClosingMeasure,
        isMeasureCustom,
    ]);

    useEffect(() => {
        if (params.specifiedTaskId) {
            expandTask(params.specifiedTaskId);
        }
    }, [params.specifiedTaskId, expandTask]);

    const setActiveMeasureId = useCallback(
        async (id: string | null, navigateTo?: boolean) => {
            setSelectedMeasureId(id);

            if (id) {
                if (navigateTo) {
                    // If a measure has been selected update the url.
                    await navigate(
                        navigation.getPathForParams({
                            ...params,
                            measureId: id || undefined,
                            specifiedTaskId: undefined,
                        }),
                        { replace: true }
                    );
                }
            } else if (params.measureId) {
                setIsClosingMeasure(true);

                // If a measure has closed and the url is still a measure one, clear it.
                await navigate(
                    navigation.getPathForParams({
                        ...params,
                        measureId: undefined,
                        specifiedTaskId: undefined,
                    })
                );
            }
        },
        [navigate, params]
    );

    useEffect(() => {
        if (!activeMeasureId) {
            setIsClosingMeasure(false);
        }
    }, [activeMeasureId]);

    const [selectedMeasureId, setSelectedMeasureId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();

    // Reset the url if a task has been selected.
    useEffect(() => {
        if (
            activeTaskId &&
            params.specifiedTaskId &&
            (missionBuilderSpecifiedTaskMatch || missionBuilderImpliedTaskMatch)
        ) {
            navigate(
                navigation.getPathForParams({
                    ...params,
                    specifiedTaskId: undefined,
                    impliedTaskId: undefined,
                })
            );
        }
    }, [
        activeTaskId,
        params,
        missionBuilderSpecifiedTaskMatch,
        missionBuilderImpliedTaskMatch,
        navigate,
    ]);

    const [showEditMeasureGroupPanel, setShowEditMeasureGroupPanel] =
        useState(false);

    const [showRejectedTaskId, setShowRejectedTaskId] = useState<
        string | undefined
    >();

    const [toggleLockedTag] = useToggleTagLinkMutation({
        refetchQueries: [
            refetchGetMeasureQuery({
                tenantId: currentTenantId || '',
                id: activeMeasureId || '',
            }),
        ],
        variables: {
            tenantId: currentTenantId || '',
            measureId: activeMeasureId || '',
            taskId: null,
            tagName: 'Locked',
            tagId: null,
        },
    });

    const specifiedTaskIds = useMemo(
        () =>
            (data?.tasksSummary || [])
                ?.filter((t) => t.parentTaskId === null)
                .map((t) => t.id || '') || [],
        [data?.tasksSummary]
    );

    const impliedTasks = useMemo(
        () =>
            (data?.tasksSummary || []).filter(
                (t) =>
                    t.parentTaskId !== null &&
                    !t.isDuplicate &&
                    specifiedTaskIds.indexOf(t.parentTaskId) > -1
            ) || [],
        [data?.tasksSummary, specifiedTaskIds]
    );

    const taskSummaryData = data?.tasksSummary || [];
    const taskSummary = useTaskSummary(
        impliedTasks.map((t) => {
            return { ...t, resourcedFromTask: null }; // We don't care about accepted/rejected here as they won't be in the mission
        })
    );

    const tokens = {
        gapStack: {
            padding: 0,
            marginBottom: 32,
            childrenGap: 0,
        },
    };

    const mission = data?.mission;

    const oneUpMissionId = useMemo(() => {
        return mission?.team?.leaderMission &&
            mission?.team.leaderMission.id !== mission.id
            ? mission.team?.leaderMission.id
            : null;
    }, [mission?.id, mission?.team?.leaderMission]);

    const onCommentsClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, false);
            setActiveTaskId(null);
            setActivePanel('comments');
        },
        [setActiveMeasureId]
    );

    const onAttachmentsClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, false);
            setActiveTaskId(null);
            setActivePanel('attachments');
        },
        [setActiveMeasureId]
    );

    const onMeasureCardClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, true);
            if (isMeasureCustom(measureId)) {
                setActivePanel('measure-overview');
            }
        },
        [isMeasureCustom, setActiveMeasureId]
    );

    const onEditMeasureClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, true);
            setActivePanel('edit-measure');
        },
        [setActiveMeasureId]
    );

    const onMeasureLinkClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, false);
            setShowMeasureUnlink(true);
        },
        [setActiveMeasureId]
    );

    const handleAddMeasureButtonClick = useCallback(async (): Promise<void> => {
        await setActiveMeasureId(null, false);
        setActiveMeasureGroupId(null);
        setActivePanel('edit-measure');
    }, [setActiveMeasureId]);

    const handleAddSpecifiedTaskClick = useCallback((): void => {
        setActiveTaskId(null);
        setActivePanel('new-task');
    }, []);

    const handleAddImpliedTaskClick = useCallback(
        (specifiedTaskId: string): void => {
            setActiveTaskId(specifiedTaskId);
            setActivePanel('new-task');
        },
        []
    );

    const onEditMeasureActualClick = useCallback(
        async (measureId: string): Promise<void> => {
            await setActiveMeasureId(measureId, true);
            if (isMeasureCustom(measureId)) {
                setActivePanel('edit-measure-actual');
            }
        },
        [isMeasureCustom, setActiveMeasureId]
    );

    const onMeasureCountChanged = useCallback((count: number): void => {
        // Expand the missions area, if they haven't added measures yet.
        setMissionAreaState(count === 0 ? 'expanded' : 'collapsed');
    }, []);

    const onTaskClick = useCallback(
        (taskId: string): void => {
            setActiveTaskId(taskId);
            setActivePanel(
                mission?.rights.write ? 'edit-task' : 'task-alignment'
            );
        },
        [mission?.rights.write]
    );

    const onEditTaskClick = useCallback((taskId: string): void => {
        setActiveTaskId(taskId);
        setActivePanel('edit-task');
    }, []);

    const onTaskCommentsClick = useCallback(
        async (taskId: string): Promise<void> => {
            await setActiveMeasureId(null);
            setActiveTaskId(taskId);
            setActivePanel('comments');
        },
        [setActiveMeasureId]
    );

    const onTaskAttachmentsClick = useCallback(
        async (taskId: string): Promise<void> => {
            await setActiveMeasureId(null);
            setActiveTaskId(taskId);
            setActivePanel('attachments');
        },
        [setActiveMeasureId]
    );

    const onRejectedTaskClick = useCallback((rejectedTaskId: string) => {
        setShowRejectedTaskId(rejectedTaskId);
    }, []);

    const handleMeasureGroupingClick = useCallback((): void => {
        setShowEditMeasureGroupPanel(true);
    }, []);

    const handleAddNewMeasureButtonClick = useCallback(
        async (measureGroupId: string | null | undefined) => {
            setActiveMeasureGroupId(measureGroupId);
            await setActiveMeasureId(null);
            setActivePanel('edit-measure');
        },
        [setActiveMeasureId]
    );

    const fyStartDate = data?.mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = data?.mission?.team?.division?.financialYear?.endDate;

    const [docTitle, setDocTitle] = useState<string | undefined>();

    const [taskNotificationCount, setTaskNotificationCount] = useState<
        number | undefined
    >();

    const [additionalProperties, setAdditionalProperties] = useState<{
        measureCount?: number;
        specifiedTaskCount?: number;
        impliedTaskCount?: number;
        isOwnMission?: boolean;
        isLoaded: boolean;
        taskNotificationCount?: number;
    }>();

    useActiveView('MissionBuilder', docTitle, additionalProperties);

    const handleTaskNotificationCountChanged = useCallback(
        (count: number) => setTaskNotificationCount(count),
        []
    );

    const measureCount = data?.measureSummary?.length;
    const specifiedTaskCount = data?.tasksSummary.filter(
        (t) => t.parentTaskId === null
    ).length;
    const impliedTaskCount = impliedTasks.length;

    useEffect(() => {
        if (!loading && mission) {
            setAdditionalProperties({
                measureCount: measureCount,
                specifiedTaskCount: specifiedTaskCount,
                impliedTaskCount: impliedTaskCount,
                isOwnMission: mission?.userId === currentUserId,
                taskNotificationCount: taskNotificationCount,
                isLoaded: true,
            });
            setDocTitle(`${mission?.owner} (${mission?.title})`);
        } else {
            setAdditionalProperties({
                taskNotificationCount: taskNotificationCount,
                isLoaded: false,
            });
        }
    }, [
        currentUserId,
        mission,
        loading,
        taskNotificationCount,
        measureCount,
        specifiedTaskCount,
        impliedTaskCount,
    ]);

    const handleExpandAllTasksClick = () => {
        const expandedTaskIds = getExpandedTaskIds();

        // If any of the specified tasks on the list are not expanded.
        if (specifiedTaskIds.some((st) => !expandedTaskIds.includes(st))) {
            // Expand the specified
            expandTasks(specifiedTaskIds);
        } else {
            // If they are all expanded, expand the implied
            expandTasks(impliedTasks.map((it) => it.id || ''));
        }
    };

    const handleCollapseAllTasksClick = () => {
        collapseTasks([
            ...specifiedTaskIds,
            ...impliedTasks.map((it) => it.id || ''),
        ]);
    };

    const handleFilterTasksClick = useCallback(() => {
        setShowTaskFilter((f) => !f);
    }, []);

    const handleTaskFiltersActiveChanged = useCallback(
        (isActive: boolean) => {
            // Expand all the tasks when the filter is activated.
            if (!taskFiltersActive && isActive) {
                // Expand the specified
                const expandedTaskIds = getExpandedTaskIds();
                if (
                    specifiedTaskIds.some((st) => !expandedTaskIds.includes(st))
                ) {
                    expandTasks(specifiedTaskIds);
                }
            }

            setTaskFiltersActive(isActive);
        },
        [expandTasks, getExpandedTaskIds, specifiedTaskIds, taskFiltersActive]
    );

    const handleFiltersDismiss = useCallback(
        () => setShowTaskFilter(false),
        []
    );

    const { button: taskViewModeButton, activeViewMode: taskViewMode } =
        useViewModeDropDownButton(
            'ADVANCE_MISSIONBUILDER_TASKVIEW',
            taskViewModes,
            'Tile'
        );

    const userPrompts = useMemo(
        () => [
            {
                key: 'statusReport',
                text: 'What is the status of the mission?',
            },
            {
                key: 'improveMission',
                text: 'How can I improve this mission?',
            },
        ],
        []
    );

    const handlePromptSelected = useCallback(
        async (userPromptKey: string | undefined) => {
            cancelMissionMentor();
            setChatContent(undefined);
            if (data) {
                await missionReport(
                    data,
                    userPromptKey as 'statusReport' | 'improveMission'
                );
            }
        },
        [data, cancelMissionMentor, missionReport]
    );

    const onDismissMentorDialogDialog = useCallback(() => {
        setMissionMentorIsDialogOpen(false);
        cancelMissionMentor();
    }, [cancelMissionMentor]);

    const handleActivePanelChanged = useCallback(
        async (panelType: PanelTypes): Promise<void> => {
            if (
                panelType === null &&
                activeMeasureId &&
                isMeasureCustom(activeMeasureId)
            ) {
                await setActiveMeasureId(null);
            }

            setActivePanel(panelType);
        },
        [activeMeasureId, isMeasureCustom, setActiveMeasureId]
    );

    const handleActiveTaskChanged = useCallback(
        (taskId: string | null): void => {
            setActivePanel(null);
            setActiveTaskId(taskId);
            if (taskId) {
                setActivePanel(
                    mission?.rights.write ? 'edit-task' : 'task-alignment'
                );
            }
        },
        [mission?.rights.write]
    );

    const handleMeasureLockToggle = useCallback(async () => {
        if (activeMeasureId) {
            await toggleLockedTag();
        }
    }, [activeMeasureId, toggleLockedTag]);

    const handleMeasureDeleted = useCallback(
        () => setActiveMeasureId(null),
        [setActiveMeasureId]
    );

    const taskAreaButtons: AreaContainerButton[] = useMemo(() => {
        const buttons: AreaContainerButton[] = [];

        if (specifiedTaskIds.length > 0) {
            buttons.push(taskViewModeButton);
            buttons.push({
                key: 'Filter',
                iconProps: {
                    iconName: taskFiltersActive ? 'FilterSolid' : 'Filter',
                },
                text: 'Filter',
                onClick: handleFilterTasksClick,
                hideOnCollapse: true,
            });
        }

        if (mission?.rights.write) {
            buttons.push({
                key: 'Add',
                iconProps: {
                    iconName: 'Add',
                },
                text: t('specified-task.specified-task', {
                    count: 1,
                }),
                onClick: handleAddSpecifiedTaskClick,
                hideOnCollapse: true,
            });
        }

        return buttons;
    }, [
        handleAddSpecifiedTaskClick,
        handleFilterTasksClick,
        mission?.rights.write,
        specifiedTaskIds.length,
        t,
        taskFiltersActive,
        taskViewModeButton,
    ]);

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                {error.message}
            </MessageBar>
        );
    } else if (loading || !mission) {
        return (
            <div id="loader">
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }

    const taskSummaryItems = taskSummary.map((ts) => {
        return {
            count: ts.count,
            text: ts.name.toLocaleUpperCase(),
            iconName: 'SquareShapeSolid',
            iconColour: ts.colour,
        };
    });

    const handleInsightsButtonClick = async () => {
        setMissionMentorIsDialogOpen(true);
    };

    const handleMissionExportButtonClick = async () => {
        try {
            setIsExporting(true);
            await missionAnalysisExport(
                configuration,
                params.missionId,
                currentTenantId,
                mission.owner
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    const handleSummaryExportButtonClick = async () => {
        try {
            setIsSummaryExporting(true);
            await missionSummaryExport(
                configuration,
                params.missionId,
                currentTenantId,
                mission.owner
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsSummaryExporting(false);
        }
    };

    const missionButtons: AreaContainerButton[] = [];

    if (isMissionMentorEnabled) {
        missionButtons.push({
            key: 'MMReport',
            iconProps: {
                iconName: 'X',
            },

            onRenderIcon: () => {
                return (
                    <Persona
                        imageUrl={mai}
                        text="MAI"
                        size={PersonaSize.size24}
                        showSecondaryText={false}
                        styles={{
                            root: {
                                margin: 0,
                                '& .ms-Image': {
                                    transition: 'transform 0.2s',
                                    transform: 'scale(0.9)',
                                    filter: 'grayscale(1) opacity(0.75)',
                                },
                                ':hover .ms-Image': {
                                    transform: 'scale(1)',
                                    filter: 'grayscale(0%)',
                                },
                            },
                        }}
                    />
                );
            },
            onClick: () => {
                handleInsightsButtonClick();
            },
        });
    }

    const handlePresentButtonClick = (presentation: string) => {
        const href = generatePath(paths.present, {
            ...params,
            presentation: presentation,
        });
        navigate(href);
    };

    const presentButton: AreaContainerButton = {
        key: 'Present',
        text: 'Present',
        iconProps: {
            iconName: 'Presentation',
        },
        engagementTargetKey: 'PresentButton',
        subMenuProps: {
            items: [
                {
                    key: 'mission-analysis',
                    text: 'Mission Analysis',
                    onClick: () => handlePresentButtonClick('mission-analysis'),
                    iconProps: {
                        iconName: 'Presentation12',
                    },
                },
                {
                    key: 'mission-summary',
                    text: 'Mission Summary',
                    onClick: () => handlePresentButtonClick('mission-summary'),
                    iconProps: {
                        iconName: 'Presentation12',
                    },
                },
            ],
        },
    };

    if (mission?.rights?.export && presentButton.subMenuProps?.items) {
        presentButton.subMenuProps.items.push({
            key: 'download-mission-analysis',
            text: 'Download Mission Analysis',
            iconProps: {
                iconName: 'PowerPointDocument',
            },
            disabled: isExporting,
            onRenderIcon: (iconProps, defaultRenderer) => {
                if (isExporting) {
                    return (
                        <Spinner
                            size={SpinnerSize.small}
                            styles={{
                                root: { marginLeft: 4, marginRight: 4 },
                            }}
                        />
                    );
                }
                return defaultRenderer ? defaultRenderer(iconProps) : null;
            },
            onClick: (ev) => {
                ev?.preventDefault();
                handleMissionExportButtonClick();
            },
        });
    }

    if (mission?.rights?.export && presentButton.subMenuProps?.items) {
        presentButton.subMenuProps.items.push({
            key: 'download-mission-summary',
            text: 'Download Mission Summary',
            iconProps: {
                iconName: 'PowerPointDocument',
            },
            disabled: isSummaryExporting,
            onRenderIcon: (iconProps, defaultRenderer) => {
                if (isSummaryExporting) {
                    return (
                        <Spinner
                            size={SpinnerSize.small}
                            styles={{
                                root: { marginLeft: 4, marginRight: 4 },
                            }}
                        />
                    );
                }
                return defaultRenderer ? defaultRenderer(iconProps) : null;
            },
            onClick: (ev) => {
                ev?.preventDefault();
                handleSummaryExportButtonClick();
            },
        });
    }

    missionButtons.push(presentButton);

    if (mission?.rights?.write) {
        missionButtons.push({
            key: 'Edit',
            text: 'Edit',
            iconProps: {
                iconName: 'Edit',
            },
            onClick: () => setActivePanel('edit-mission'),
        });
    }

    if (!loading && !mission.rights.read) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                messageBarIconProps={{
                    iconName: 'AlertSolid',
                }}
                isMultiline={true}
            >
                <span>You do not have access to view this mission</span>
            </MessageBar>
        );
    }

    const expandCollapseButtonStyles: Partial<IButtonStyles> = {
        label: {
            whiteSpace: 'nowrap',
        },
        root: {
            height: 20,
            fontSize: FontSizes.small,
        },
        icon: {
            fontSize: FontSizes.small,
        },
    };

    let currentDivisionId = mission?.team?.division?.id;

    if (teamCode && mission) {
        const thisTeam = mission.leaderOfTeams?.find(
            (lm) => lm.code?.toLowerCase() === teamCode?.toLowerCase()
        );
        if (thisTeam?.division) {
            currentDivisionId = thisTeam.division.id;
        }
    }

    return (
        <React.Fragment>
            <EditPanels
                activePanel={activePanel}
                missionId={missionId}
                measureId={activeMeasureId || selectedMeasureId}
                measureGroupId={activeMeasureGroupId}
                taskId={activeTaskId}
                onActivePanelChanged={handleActivePanelChanged}
                onActiveTaskChanged={handleActiveTaskChanged}
                onMeasureLockedToggle={handleMeasureLockToggle}
                onDeleteMeasure={handleMeasureDeleted}
            />
            <EditMeasureGroupsPanel
                missionId={missionId}
                missionAccess={mission.rights}
                showPanel={showEditMeasureGroupPanel}
                onCancel={() => {
                    setShowEditMeasureGroupPanel(false);
                }}
                onSave={async () => {
                    await refetch();
                    setShowEditMeasureGroupPanel(false);
                }}
            />

            <UnlinkMeasureDialog
                tenantId={currentTenantId || ''}
                hidden={!showMeasureUnlink}
                onDismiss={() => {
                    setShowMeasureUnlink(false);
                }}
                measureId={activeMeasureId || selectedMeasureId}
                onMeasureUnlinked={() => {
                    setShowMeasureUnlink(false);
                }}
            />

            <RejectedResourcePanel
                taskId={showRejectedTaskId}
                showPanel={showRejectedTaskId !== undefined}
                missionAccess={mission.rights}
                onCancel={(): void => setShowRejectedTaskId(undefined)}
            />

            <MissionMentorDialog
                hidden={!isMissionMentorDialogOpen}
                userPrompts={userPrompts}
                onDismiss={onDismissMentorDialogDialog}
                onPromptSelected={handlePromptSelected}
                content={chatContent}
                isStreaming={isStreaming}
            />

            {missionBuilderMeasureMatch &&
            data?.measureSummary?.find(
                (m) =>
                    m.id === missionBuilderMeasureMatch?.params.measureId &&
                    !m.isCustom
            ) ? (
                <Measure
                    onEditMeasureClick={onEditMeasureClick}
                    onAttachmentsClick={onAttachmentsClick}
                    onCommentsClick={onCommentsClick}
                    onTaskClick={onTaskClick}
                />
            ) : missionBuilderMeasureFormulaMatch &&
              data?.measureSummary?.find(
                  (m) =>
                      m.id ===
                          missionBuilderMeasureFormulaMatch?.params.measureId &&
                      !m.isCustom
              ) ? (
                <MeasureFormulaEditor />
            ) : (
                <Stack tokens={tokens.gapStack}>
                    <Stack.Item align="stretch" grow>
                        <AreaContainer
                            title={t('mission.mission', { count: 1 })}
                            isCollapsable={true}
                            initialState={missionAreaState}
                            subTitle={
                                mission?.missionStatement?.trim() ||
                                !mission.rights.write ||
                                mission.userId !== currentUserId ? (
                                    <span>{mission.missionStatement}</span>
                                ) : (
                                    <Link
                                        onClick={() =>
                                            setActivePanel('edit-mission')
                                        }
                                    >
                                        Enter your mission statement
                                    </Link>
                                )
                            }
                            commandBarButtons={missionButtons}
                        >
                            <MissionCard
                                missionData={data.mission}
                                teamCode={teamCode}
                            />
                        </AreaContainer>
                    </Stack.Item>
                    <Stack.Item align="stretch" grow>
                        <MissionBuilderMeasureArea
                            missionId={missionId}
                            missionUserId={mission?.userId}
                            fyStartDate={fyStartDate}
                            fyEndDate={fyEndDate}
                            missionAccess={mission.rights}
                            measureSummary={data?.measureSummary}
                            measureGroups={data?.measureGroups}
                            onMeasureGroupingClick={handleMeasureGroupingClick}
                            onAddMeasureButtonClick={
                                handleAddMeasureButtonClick
                            }
                            onMeasureCardClick={onMeasureCardClick}
                            onAddNewMeasureButtonClick={
                                handleAddNewMeasureButtonClick
                            }
                            onMeasureCountChanged={onMeasureCountChanged}
                            onEditMeasureActualClick={onEditMeasureActualClick}
                            onAttachmentsClick={onAttachmentsClick}
                            onCommentsClick={onCommentsClick}
                            onMeasureLinkClick={onMeasureLinkClick}
                            oneUpMissionId={oneUpMissionId}
                        />
                    </Stack.Item>
                    <Stack.Item align="stretch" grow>
                        <AreaContainer
                            title={t('specified-task.specified-task', {
                                count: 10,
                            })}
                            isCollapsable={true}
                            onStateChanged={setTaskAreaState}
                            subTitle={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <HeaderSummaryBar
                                            items={taskSummaryItems}
                                        />
                                    </div>
                                    {taskAreaState === 'expanded' &&
                                        !isMobileMode && (
                                            <div>
                                                {taskViewMode === 'Tile' && (
                                                    <TaskRatingsActionButton
                                                        styles={
                                                            expandCollapseButtonStyles
                                                        }
                                                        selectedColumnNames={
                                                            selectedTileColumnNames
                                                        }
                                                        toggleColumn={
                                                            toggleTileColumn
                                                        }
                                                    />
                                                )}
                                                <ActionButton
                                                    iconProps={{
                                                        iconName:
                                                            'ExploreContent',
                                                    }}
                                                    text="Expand All"
                                                    onClick={
                                                        handleExpandAllTasksClick
                                                    }
                                                    styles={
                                                        expandCollapseButtonStyles
                                                    }
                                                />
                                                <ActionButton
                                                    iconProps={{
                                                        iconName:
                                                            'CollapseContent',
                                                    }}
                                                    styles={
                                                        expandCollapseButtonStyles
                                                    }
                                                    text="Collapse All"
                                                    onClick={
                                                        handleCollapseAllTasksClick
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            }
                            commandBarButtons={taskAreaButtons}
                        >
                            <TaskList
                                key={`TaskList_${missionId}`}
                                divisionId={currentDivisionId || null}
                                missionId={missionId || ''}
                                missionUserId={mission.userId}
                                missionOwner={mission.owner}
                                financialYearCode={financialYearCode || ''}
                                missionAccess={mission.rights}
                                tasksSummary={taskSummaryData}
                                showAdd={
                                    !loading &&
                                    taskSummaryData.length === 0 &&
                                    mission.rights.write
                                }
                                onAddSpecifiedButtonClick={
                                    handleAddSpecifiedTaskClick
                                }
                                onAddImpliedButtonClick={
                                    handleAddImpliedTaskClick
                                }
                                onTaskClick={onTaskClick}
                                onTaskEditClick={onEditTaskClick}
                                onCommentsClick={onTaskCommentsClick}
                                onAttachmentsClick={onTaskAttachmentsClick}
                                onRejectedTaskClick={onRejectedTaskClick}
                                scrollToSpecifiedTaskId={
                                    !activeTaskId
                                        ? params.specifiedTaskId
                                        : null
                                }
                                highlightImpliedTaskId={
                                    !activeTaskId ? params.impliedTaskId : null
                                }
                                showFilter={showTaskFilter}
                                onFiltersDismiss={handleFiltersDismiss}
                                onFiltersActiveChanged={
                                    handleTaskFiltersActiveChanged
                                }
                                viewMode={taskViewMode}
                                onTaskNotificationCountChanged={
                                    handleTaskNotificationCountChanged
                                }
                                tileColumnNames={selectedTileColumnNames}
                            />
                        </AreaContainer>
                    </Stack.Item>
                </Stack>
            )}
        </React.Fragment>
    );
}

export default withTaskExpand(MissionBuilder);
