import React, { useState } from 'react';
import max from 'lodash/max';

import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
} from '@fluentui/react';
import {
    useGetMissionTasksQuery,
    useUpdateTaskMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { TaskDropDown } from '../../../components/inputs/TaskDropDown';

export function StatusReportActionPromoteDialog(props: {
    statusReportAction:
        | {
              name: string | null;
              due: string | null;
              done: string | null;
          }
        | null
        | undefined;
    missionId: string;
    hidden: boolean;
    onDismiss: () => void;
    onTaskPromoted: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [targetSpecifiedTaskId, setTargetSpecifiedTaskId] = useState<
        string | null
    >();

    const { data: tasksData, loading: tasksLoading } = useGetMissionTasksQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const [updateTask, { loading: isSaving }] = useUpdateTaskMutation();

    const onConfirmPromoteClick = async () => {
        if (
            !currentTenantId ||
            !targetSpecifiedTaskId ||
            !props.statusReportAction
        ) {
            return;
        }

        const lastSequence = max(
            tasksData?.tasks
                ?.filter((t) => t.parentTaskId === targetSpecifiedTaskId)
                .filter((t) => !t.isDuplicate)
                .map((t) => t.sequence)
        );

        const newSequence = lastSequence !== undefined ? lastSequence + 1 : 0;

        await updateTask({
            variables: {
                tenantId: currentTenantId,
                input: {
                    id: null,
                    costWeight: 0,
                    description: '',
                    done: props.statusReportAction.done,
                    due: props.statusReportAction.due,
                    effortResourceWeight: 0,
                    effortWeight: 0,
                    isDuplicate: false,
                    isPercentageCompleteFromResources: false,
                    isPercentageCompleteFromSubTasks: false,
                    linkedMeasures: [],
                    missionId: props.missionId,
                    name: props.statusReportAction.name,
                    parentTaskId: targetSpecifiedTaskId,
                    percentComplete: 0,
                    rejectedReason: null,
                    resource: null,
                    resourceId: null,
                    resourceIsPrimary: false,
                    resourcedFromTaskId: null,
                    review: null,
                    sequence: newSequence,
                    start: null,
                    taskCategoryId: null,
                    utcAccepted: null,
                    utcAtRisk: null,
                    utcCancelled: null,
                    utcChangesPending: null,
                    utcPostponed: null,
                    utcRejected: null,
                    utcResourceRemoved: null,
                    version: '',
                },
            },
            refetchQueries: ['GetTasksForStatusReport'],
        });

        props.onTaskPromoted();
    };

    const handeTaskChange = (taskId: string | null) => {
        setTargetSpecifiedTaskId(taskId);
    };

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Promote',
                showCloseButton: true,
                subText:
                    'This will promote this action item to an implied task inside the seleted specified task.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <TaskDropDown
                canSelectSpecifiedTasks
                canSelectImpliedTasks={false}
                isDataLoaded={!tasksLoading}
                missionTasks={tasksData?.tasks}
                selectedKey={targetSpecifiedTaskId}
                onTaskChange={handeTaskChange}
                placeholder="Select a specified task"
                label="Target Specified Task"
            />

            <DialogFooter>
                <PrimaryButton
                    text="Promote"
                    onClick={onConfirmPromoteClick}
                    disabled={
                        isSaving || tasksLoading || !targetSpecifiedTaskId
                    }
                />
                <DefaultButton text="Cancel" onClick={props.onDismiss} />
            </DialogFooter>
        </Dialog>
    );
}
