import React, { useEffect, useMemo, useState } from 'react';
import {
    DefaultButton,
    PrimaryButton,
    Dialog,
    DialogFooter,
    DialogType,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { UserPicker } from '../../components/UserPicker';
import { StatusReportAsOfDatePicker } from './components/StatusReportAsOfDatePicker';
import dayjs from 'dayjs';

export function StatusReportPublishDialog(props: {
    hidden: boolean;
    title: string | null;
    reportDate: string | null;
    statusReport?: {
        summaryText: string | null;
        lastPeriodText: string | null;
        nextPeriodText: string | null;
        risksAndOpportunitiesText: string | null;
        supportText: string | null;
    };
    onDismiss: () => void;
    onConfirm: (
        title: string,
        reportDate: string | null,
        notifyUserIds: string[]
    ) => void;
    disabled: boolean;
    teamLeader: {
        userId: string;
        displayName: string;
    } | null;
    mission: { utcCreated: string } | null;
}): JSX.Element {
    const DefaultReportDate = useMemo(() => dayjs.utc().toISOString(), []);
    const DefaultTitle = 'Status Report';

    const [notiftyUsers, setNotiftyUsers] = useState<
        {
            userId: string;
            displayName: string;
        }[]
    >([]);

    useEffect(() => {
        setReportDate(props.reportDate || DefaultReportDate);
    }, [DefaultReportDate, props.reportDate]);

    useEffect(() => {
        setTitle(props.title || DefaultTitle);
    }, [props.title]);

    const [reportDate, setReportDate] = useState<string | null>(
        DefaultReportDate
    );
    const [title, setTitle] = useState<string>(DefaultTitle);

    const handleUserPicked = (users: { userId: string; name: string }[]) => {
        setNotiftyUsers(
            users.map((u) => {
                return {
                    userId: u.userId,
                    displayName: u.name,
                };
            })
        );
    };

    useEffect(() => {
        const userList: {
            userId: string;
            displayName: string;
        }[] = [];

        if (!props.hidden) {
            if (props.teamLeader) {
                userList.push(props.teamLeader);
            }
            if (props.statusReport) {
                const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;

                const allText = `${props.statusReport.summaryText}\n${props.statusReport.lastPeriodText}\n${props.statusReport.nextPeriodText}\n${props.statusReport.risksAndOpportunitiesText}\n${props.statusReport.supportText}\n`;

                let match: RegExpExecArray | null = null;
                while ((match = mentionRegex.exec(allText)) !== null) {
                    if (
                        match[1] &&
                        match[2] &&
                        !userList.some((u) => match && u.userId == match[2])
                    ) {
                        userList.push({
                            displayName: match[1],
                            userId: match[2],
                        });
                    }
                }
            }
        }
        setNotiftyUsers(userList);
    }, [props.teamLeader, props.statusReport, props.hidden]);

    const handlePublishClick = () => {
        props.onConfirm(
            title || DefaultTitle,
            reportDate,
            notiftyUsers.map((nu) => nu.userId)
        );
    };

    const handleReportDateChanged = async (reportDate?: string | null) => {
        setReportDate(reportDate || null);
    };

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Confirm Publish',
                closeButtonAriaLabel: 'Close',
                subText: 'Do you want to mark this report as published?',
            }}
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Report Title"
                    value={title}
                    onChange={(_ev, newValue) => {
                        setTitle(newValue || '');
                    }}
                />

                <Stack.Item>
                    <StatusReportAsOfDatePicker
                        value={reportDate}
                        mission={props.mission}
                        onSelectDate={handleReportDateChanged}
                    />
                    <Text variant="small">For display purposes only.</Text>
                </Stack.Item>

                <UserPicker
                    label="Select users to notify"
                    selectedUsers={notiftyUsers}
                    onChange={handleUserPicked}
                />
            </Stack>

            <DialogFooter>
                <PrimaryButton
                    onClick={handlePublishClick}
                    disabled={props.disabled}
                    text="Publish"
                />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
