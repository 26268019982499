import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
    Stack,
    mergeStyleSets,
    ActionButton,
    DefaultButton,
    PrimaryButton,
    Text,
    Dialog,
    DialogFooter,
    DialogType,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    useTheme,
    Checkbox,
    Shimmer,
    ShimmerElementsGroup,
    ShimmerElementType,
} from '@fluentui/react';
import dayjs from 'dayjs';

import {
    GetStatusReportQuery,
    ReportPeriodTypes,
    StatusReport,
    useDeleteStatusReportMutation,
    useGetStatusReportQuery,
    useUpdateStatusReportFactActionsMutation,
    useUpdateStatusReportFactMutation,
    useUpdateStatusReportMutation,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import {
    AreaContainer,
    AreaContainerButton,
} from '../MissionBuilder/components/AreaContainer';
import StatusReportGrid from './components/StatusReportGrid';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import { StatusReportEditorCard } from './components/StatusReportEditorCard';
import { StatusReportAddFactPanel } from './components/StatusReportAddFactPanel';
import DeleteModal from '../../components/shared/DeleteModal';
import { paths } from '../../services/navigation';
import { statusReportExport } from '../../services/exporter';
import { StatusReportPublishDialog } from './StatusReportPublishDialog';
import { ErrorMessageBar } from '../../components/shared/ErrorMessageBar';
import { useActiveView } from '../../hooks/useActiveView';
import { StatusReportAsOfDatePicker } from './components/StatusReportAsOfDatePicker';
import { StatusReportDataChangedIndicator } from './components/StatusReportDataChangedIndicator';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import { sorters } from '../../data/sorters';
import { StatusReportTitle } from './StatusReportTitle';
import { ReportPeriodLabel } from '../../components/reports/ReportPeriodLabel';
import { StatusReportActionList } from './components/StatusReportActionList';
import { useInputMappers } from '../../hooks/useInputMappers';

type ChangeTracker = {
    input: StatusReport;
    reportChanged: boolean;
    factIdsChanged: string[];
    factIdsActionsChanged: string[];
    isSaving: boolean;
};

function StatusReportScene(): JSX.Element {
    const params = useParams();

    const { getDateForInput } = useInputMappers();

    const [docTitle, setDocTitle] = useState<string | undefined>();
    useActiveView('StatusReport', docTitle);

    const { statusReportId } = params;

    const { currentTenantId, configuration, currentRoles } = useStateContext();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin', 'Developer'].includes(r)
    );

    const changeTracker = useRef<ChangeTracker>();

    const [isExporting, setIsExporting] = useState(false);

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMeasureId, setActiveMeasureId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();

    // For the add action list to next 30 functionality
    //const [actionList, setActionList] = useState<string[]>([]);

    const navigate = useNavigate();

    const { data, loading, refetch } = useGetStatusReportQuery({
        skip: !statusReportId || !currentTenantId,
        fetchPolicy: 'network-only',
        variables: {
            tenantId: currentTenantId || '',
            statusReportId: statusReportId || '',
        },
        onCompleted: (d) => {
            if (!changeTracker.current) {
                resetChangeTracker(d.statusReport);
            }

            const isNew =
                !d.statusReport?.utcUpdated ||
                dayjs(d.statusReport?.utcUpdated).diff(
                    dayjs(d.statusReport?.utcCreated),
                    'seconds'
                ) < 1;

            // Display the fact panel once on a virgin report
            if (
                isNew &&
                d.statusReport?.facts.length === 0 &&
                d.statusReport.mission?.rights.write &&
                !d.statusReport.utcCompletedDate &&
                !hasForcedOpenedFactPanel
            ) {
                // Its new if the report hasn't been updated or the update date matches the creation date
                setShowAddFactsPanel(true);
                setHasForcedOpenedFactPanel(true);
            }
        },
    });

    const [hasForcedOpenedFactPanel, setHasForcedOpenedFactPanel] =
        useState(false);
    const [showAddFactsPanel, setShowAddFactsPanel] = useState(false);

    const getInput = useCallback(
        (
            statusReport: NonNullable<GetStatusReportQuery['statusReport']>
        ): StatusReport => {
            const r = statusReport;

            return {
                id: r.id,
                missionId: r.missionId,
                title: r.title,
                reportDate: r.reportDate,
                reportPeriod: r.reportPeriod,
                reportPeriodType: r.reportPeriodType,
                utcCompletedDate: r.utcCompletedDate,
                utcDataDate: r.utcDataDate,
                summaryText: r.summaryText,
                lastPeriodText: r.lastPeriodText,
                nextPeriodText: r.nextPeriodText,
                risksAndOpportunitiesText: r.risksAndOpportunitiesText,
                supportText: r.supportText,
                version: r.version,
                facts: r.facts
                    .slice()
                    .sort(sorters.sequenceSorter)
                    .map((f) => {
                        return {
                            id: f.id,
                            measureId: f.measureId,
                            taskId: f.taskId,
                            actionText: f.actionText,
                            soWhatText: f.soWhatText,
                            factText: f.factText,
                            insightText: f.insightText,
                            isIncluded: f.isIncluded,
                            sequence: f.sequence,
                            version: f.version,
                            actions: f.actions
                                .slice()
                                .sort(sorters.sequenceSorter)
                                .map((a) => ({
                                    id: a.id,
                                    statusReportFactId: f.id,
                                    name: a.name,
                                    due: getDateForInput(a.due),
                                    done: getDateForInput(a.done),
                                    sequence: a.sequence,
                                    version: a.version,
                                })),
                        };
                    }),
            };
        },
        [getDateForInput]
    );

    const [updateStatusReport, { loading: isUpdating, error: updateError }] =
        useUpdateStatusReportMutation();

    const [
        updateStatusReportFact,
        { loading: isUpdatingFact, error: updateFactError },
    ] = useUpdateStatusReportFactMutation();

    const [
        updateStatusReportFactActions,
        { loading: isUpdatingFactActions, error: updateFactActionsError },
    ] = useUpdateStatusReportFactActionsMutation();

    const updateChangeTracker = useCallback(
        async (func: (r: ChangeTracker) => ChangeTracker): Promise<void> => {
            const tracker =
                changeTracker.current ||
                (data?.statusReport
                    ? {
                          input: getInput(data.statusReport),
                          reportChanged: false,
                          factIdsChanged: [],
                          factIdsActionsChanged: [],
                          isSaving: false,
                      }
                    : null);

            if (tracker) {
                const updated = func(tracker);
                changeTracker.current = updated;
            }
        },
        [data?.statusReport, getInput]
    );

    const updateFactVersion = useCallback(
        async (factId: string, version: string | null): Promise<void> => {
            await updateChangeTracker((tracker: ChangeTracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === factId);
                if (sr?.id && existing) {
                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: [
                                ...(sr?.facts || []).filter(
                                    (f) => f.id !== existing.id
                                ),
                                {
                                    ...existing,
                                    version: version,
                                },
                            ],
                        },
                    };
                } else {
                    return tracker;
                }
            });
        },
        [updateChangeTracker]
    );

    const updateFactActionVersions = useCallback(
        async (
            factId: string,
            actions: { id: string | null; version: string | null }[]
        ): Promise<void> => {
            await updateChangeTracker((tracker: ChangeTracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === factId);
                if (sr?.id && existing) {
                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: [
                                ...(sr?.facts || []).filter(
                                    (f) => f.id !== existing.id
                                ),
                                {
                                    ...existing,
                                    actions: existing.actions.map((ea) => ({
                                        ...ea,
                                        version:
                                            actions.find((a) => a.id === ea.id)
                                                ?.version || ea.version,
                                    })),
                                },
                            ],
                        },
                    };
                } else {
                    return tracker;
                }
            });
        },
        [updateChangeTracker]
    );

    const saveAsync = useCallback(
        async (
            tenantId: string | undefined,
            input: StatusReport,
            saveReport: boolean,
            saveFacts: string[],
            saveFactActions: string[]
        ): Promise<void> => {
            if (!tenantId) {
                return;
            }

            if (saveReport) {
                const result = await updateStatusReport({
                    variables: {
                        tenantId: tenantId,
                        input: { ...input, facts: [] }, // Facts updated later
                        notifyUserIds: [],
                    },
                });

                const newVersion = result.data?.statusReportUpdate.version;

                if (changeTracker.current) {
                    changeTracker.current.input.version = newVersion || null;
                }
            }

            const factsToUpdate = saveFacts.map((fid) =>
                input?.facts.find((fi) => fi.id === fid)
            );

            const results = await Promise.all(
                factsToUpdate.map(async (factInput) => {
                    if (factInput?.id && input?.id) {
                        return updateStatusReportFact({
                            variables: {
                                tenantId: tenantId,
                                statusReportId: input?.id,
                                fact: factInput,
                            },
                            optimisticResponse: {
                                __typename: 'Mutations',
                                statusReportFactUpdate: {
                                    ...factInput,
                                    __typename: 'StatusReportFactQL',
                                },
                            },
                        });
                    }
                })
            );

            await Promise.all(
                results.map((r) =>
                    updateFactVersion(
                        r?.data?.statusReportFactUpdate.id || '',
                        r?.data?.statusReportFactUpdate.version || null
                    )
                )
            );

            const factActionsToUpdate = saveFactActions.map((fid) =>
                input?.facts.find((fi) => fi.id === fid)
            );

            const factActionUpdateResults = await Promise.all(
                factActionsToUpdate.map(async (factInput) => {
                    if (factInput?.id && input?.id) {
                        return updateStatusReportFactActions({
                            variables: {
                                tenantId: tenantId,
                                statusReportFactId: factInput?.id,
                                factActions: factInput.actions,
                            },
                            optimisticResponse: {
                                __typename: 'Mutations',
                                statusReportFactActionsUpdate: {
                                    ...factInput,
                                    actions: factInput.actions.map((a) => ({
                                        ...a,
                                        __typename: 'StatusReportFactActionQL',
                                    })),
                                    __typename: 'StatusReportFactQL',
                                },
                            },
                        });
                    }
                })
            );

            await Promise.all(
                factActionUpdateResults.map((r) =>
                    updateFactActionVersions(
                        r?.data?.statusReportFactActionsUpdate.id || '',
                        r?.data?.statusReportFactActionsUpdate.actions || []
                    )
                )
            );
        },
        [
            updateFactVersion,
            updateFactActionVersions,
            updateStatusReport,
            updateStatusReportFact,
            updateStatusReportFactActions,
        ]
    );

    const debouncedSave = useMemo(
        () =>
            debounce(async (tenantId: string | undefined) => {
                if (!changeTracker.current) {
                    return;
                }

                // If its saving, wait.
                if (changeTracker.current.isSaving) {
                    debouncedSave(tenantId);

                    return;
                }

                // Mark as saving.
                changeTracker.current.isSaving = true;

                // Work out what we want to save this time.
                const saveReport = changeTracker.current.reportChanged;
                const saveFacts = changeTracker.current.factIdsChanged.slice();
                const saveFactActions =
                    changeTracker.current.factIdsActionsChanged.slice();

                // Clear the changes for the next save
                changeTracker.current.reportChanged = false;
                changeTracker.current.factIdsChanged = [];
                changeTracker.current.factIdsActionsChanged = [];

                await saveAsync(
                    tenantId,
                    changeTracker.current.input,
                    saveReport,
                    saveFacts,
                    saveFactActions
                );

                // No longer saving
                changeTracker.current.isSaving = false;

                return;
            }, 2000),
        [saveAsync]
    );

    const saveChangeTracker = useCallback(
        async (func: (r: ChangeTracker) => ChangeTracker): Promise<void> => {
            const tracker =
                changeTracker.current ||
                (data?.statusReport
                    ? {
                          input: getInput(data.statusReport),
                          reportChanged: false,
                          factIdsChanged: [],
                          factIdsActionsChanged: [],
                          isSaving: false,
                      }
                    : null);

            if (tracker) {
                const updated = func(tracker);
                changeTracker.current = updated;
                await debouncedSave(currentTenantId);
            }
        },
        [currentTenantId, data?.statusReport, debouncedSave, getInput]
    );

    useEffect(() => {
        return () => {
            // Save on cleanup
            debouncedSave.flush();
        };
    }, [debouncedSave]);

    //const [refreshData] = useStatusReportDataRefreshMutation();

    const [deleteStatusReport, { loading: isDeleting, error: deleteError }] =
        useDeleteStatusReportMutation();

    const hasError =
        !!updateError || !!updateFactError || !!updateFactActionsError;

    const errorMessageBarContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hasError) {
            errorMessageBarContainer.current?.scrollIntoView();
        }
    }, [hasError]);

    const classNames = mergeStyleSets({
        bottomBoxContaner: {
            display: 'flex',
            gap: 16,
            width: '100%',
            boxSizing: 'border-box',
            '@media(max-width: 600px)': {
                flexDirection: 'column',
            },
        },
        bottomBoxColumn: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        bottomBox: {
            boxSizing: 'border-box',
        },
    });

    const statusReport = data?.statusReport;
    const mission = statusReport?.mission;
    const isReadOnly =
        !mission?.rights.write ||
        statusReport?.utcCompletedDate !== null ||
        hasError;
    const includedFacts =
        statusReport?.facts?.filter((f) => f.isIncluded) || [];

    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    useEffect(() => {
        if (mission) {
            setDocTitle(`${mission?.owner} (${mission?.title}) Status Report`);
        }
    }, [mission]);

    const handleSummaryTextChanged = useCallback(
        (value: string) => {
            saveChangeTracker((ct: ChangeTracker) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    summaryText: value,
                },
            }));
        },
        [saveChangeTracker]
    );

    const handleLastPeriodTextChanged = useCallback(
        (value: string) => {
            saveChangeTracker((ct: ChangeTracker) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    lastPeriodText: value,
                },
            }));
        },
        [saveChangeTracker]
    );

    const handleNextPeriodTextChanged = useCallback(
        (value: string) => {
            saveChangeTracker((ct: ChangeTracker) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    nextPeriodText: value,
                },
            }));
        },
        [saveChangeTracker]
    );

    const handleSupportTextChanged = useCallback(
        (value: string) => {
            saveChangeTracker((ct: ChangeTracker) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    supportText: value,
                },
            }));
        },
        [saveChangeTracker]
    );

    const handleRisksAndOpportunitiesTextChanged = useCallback(
        (value: string) => {
            saveChangeTracker((ct: ChangeTracker) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    risksAndOpportunitiesText: value,
                },
            }));
        },
        [saveChangeTracker]
    );

    const onFactChanged = useCallback(
        async (fact: {
            id: string;
            factText: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            sequence: number;
        }) => {
            if (!fact.id) {
                return;
            }

            await saveChangeTracker((tracker: ChangeTracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === fact.id);
                if (sr?.id && existing) {
                    const updatedFacts = [
                        ...(sr?.facts || []).filter(
                            (f) => f.id !== existing.id
                        ),
                        {
                            ...existing,
                            factText: fact.factText,
                            soWhatText: fact.soWhatText,
                            insightText: fact.insightText,
                            actionText: fact.actionText,
                            sequence: fact.sequence,
                        },
                    ];

                    // setActionList(
                    //     updatedFacts
                    //         .slice()
                    //         .sort(sorters.sequenceSorter)
                    //         .filter((f) => f.actionText?.trim() && f.isIncluded)
                    //         .map((f) => f.actionText?.trim() || '') || []
                    // );

                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: updatedFacts,
                        },
                        factIdsChanged: uniq([
                            ...tracker.factIdsChanged,
                            fact.id,
                        ]),
                    };
                } else {
                    return tracker;
                }
            });
        },
        [saveChangeTracker]
    );

    const onFactActionsChanged = useCallback(
        async (
            factId: string,
            newActions: {
                id: string | null;
                name: string | null;
                due: string | null;
                done: string | null;
                sequence: number;
            }[]
        ) => {
            if (!factId) {
                return;
            }

            await saveChangeTracker((tracker: ChangeTracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === factId);
                if (sr?.id && existing) {
                    const updatedFacts = [
                        ...(sr?.facts || []).filter(
                            (f) => f.id !== existing.id
                        ),
                        {
                            ...existing,
                            actions: newActions.map((u) => ({
                                ...u,
                                statusReportFactId: factId,
                                due: getDateForInput(u.due),
                                done: getDateForInput(u.done),
                                version:
                                    existing.actions.find((ea) => ea.id == u.id)
                                        ?.version || '',
                            })),
                        },
                    ];

                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: updatedFacts,
                        },
                        factIdsActionsChanged: uniq([
                            ...tracker.factIdsActionsChanged,
                            factId,
                        ]),
                    };
                } else {
                    return tracker;
                }
            });
        },
        [getDateForInput, saveChangeTracker]
    );

    const handleFlushChanges = useCallback(
        () => debouncedSave.flush(),
        [debouncedSave]
    );

    const onFactRemoved = useCallback(
        async (factId: string) => {
            await saveChangeTracker((tracker: ChangeTracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === factId);
                if (sr?.id && existing) {
                    const updatedFacts = [
                        ...(sr?.facts || []).filter(
                            (f) => f.id !== existing.id
                        ),
                        {
                            ...existing,
                            isIncluded: false,
                        },
                    ];
                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: updatedFacts,
                        },
                        factIdsChanged: uniq([
                            ...tracker.factIdsChanged,
                            factId,
                        ]),
                    };
                } else {
                    return tracker;
                }
            });
            await debouncedSave.flush();
        },
        [debouncedSave, saveChangeTracker]
    );

    const resetChangeTracker = (
        statusReport: GetStatusReportQuery['statusReport']
    ) => {
        if (statusReport) {
            changeTracker.current = {
                input: getInput(statusReport),
                factIdsChanged: [],
                factIdsActionsChanged: [],
                reportChanged: false,
                isSaving: false,
            };

            // setActionList(
            //     statusReport.facts
            //         .slice()
            //         .sort(sorters.sequenceSorter)
            //         .filter((f) => f.actionText?.trim() && f.isIncluded)
            //         .map((f) => f.actionText?.trim() || '')
            // );
        }
    };

    const handleAddFactsPanelDismiss = async () => {
        setShowAddFactsPanel(false);
        const result = await refetch();
        resetChangeTracker(result.data.statusReport);
    };

    const handleAddFactsButtonClick = async () => {
        await debouncedSave.flush();
        setShowAddFactsPanel(true);
    };

    const [hideSubmitDialog, setHideSubmitDialog] = useState(true);
    const [hideUnsubmitDialog, setHideUnsubmitDialog] = useState(true);

    const handleSubmitButtonClick = async () => {
        await debouncedSave.flush();
        setHideSubmitDialog(false);
    };

    const handleSubmitDialogDismiss = () => setHideSubmitDialog(true);
    const handleUnsubmitButtonClick = () => setHideUnsubmitDialog(false);
    const handleUnsubmitDialogDismiss = () => setHideUnsubmitDialog(true);

    const handleConfirmSubmitClick = async (
        title: string,
        reportDate: string | null,
        notifyUserIds: string[]
    ) => {
        await debouncedSave.flush();

        await updateChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                input: {
                    ...ct.input,
                    title: title,
                    reportDate: reportDate,
                    utcCompletedDate: dayjs.utc().format(),
                },
            };
        });

        if (currentTenantId && changeTracker.current?.input) {
            const result = await updateStatusReport({
                variables: {
                    tenantId: currentTenantId,
                    input: { ...changeTracker.current.input, facts: [] },
                    notifyUserIds: notifyUserIds,
                },
            });

            const newVersion = result.data?.statusReportUpdate.version;

            if (newVersion) {
                changeTracker.current.input.version = newVersion;
            }

            changeTracker.current.reportChanged = false;
        }

        setHideSubmitDialog(true);
    };

    const handleConfirmUnsubmitClick = async (getLatestValues: boolean) => {
        await saveChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    utcCompletedDate: null,
                    utcDataDate: getLatestValues
                        ? null
                        : (ct.input.utcDataDate ?? ct.input.utcCompletedDate),
                },
            };
        });
        await debouncedSave.flush();
        setHideUnsubmitDialog(true);
    };

    const handleRefreshDataButtonClick = async () => {
        await saveChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    utcDataDate: null,
                },
            };
        });
        await debouncedSave.flush();
        setHideUnsubmitDialog(true);
    };

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const handleDeleteButtonClick = async () => {
        await debouncedSave.flush();
        setIsDeleteModalOpen(true);
    };
    const handleDismissDeleteModal = () => setIsDeleteModalOpen(false);
    const deleteAction = async () => {
        if (currentTenantId && statusReportId) {
            await debouncedSave.flush();
            await deleteStatusReport({
                variables: {
                    tenantId: currentTenantId,
                    statusReportId: statusReportId,
                    restore: false,
                },
            });
        }

        setIsDeleteModalOpen(false);

        const href = generatePath(paths.statusReports, params);
        navigate(href);
    };

    const handleReportDateChanged = async (reportDate?: string | null) => {
        if (!reportDate) {
            return;
        }

        await saveChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    reportDate: reportDate,
                },
            };
        });

        await debouncedSave.flush();
    };

    const handlePeriodSaveClick = async (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => {
        await saveChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    reportPeriodType: reportPeriodType,
                    reportPeriod: reportPeriod,
                },
            };
        });

        await debouncedSave.flush();
    };

    const handleTitleUpdated = async (title?: string | null) => {
        await saveChangeTracker((ct: ChangeTracker) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    title: title || '',
                },
            };
        });

        await debouncedSave.flush();
    };

    const handleActivePanelChanged = useCallback(
        (panelType: PanelTypes): void => setActivePanel(panelType),
        []
    );

    const onActiveTaskChanged = useCallback(
        (taskId: string | null) => setActiveTaskId(taskId),
        []
    );

    const handleMeasureNavigate = useCallback((measureId: string) => {
        setActiveTaskId(null);
        setActiveMeasureId(measureId);
        setActivePanel('measure-overview');
    }, []);

    const handleTaskNavigate = useCallback(
        (taskId: string) => {
            setActiveMeasureId(null);
            setActiveTaskId(taskId);
            setActivePanel(isReadOnly ? 'task-alignment' : 'edit-task');
        },
        [isReadOnly]
    );

    const handleExportButtonClick = async () => {
        try {
            setIsExporting(true);
            await statusReportExport(
                configuration,
                params.statusReportId,
                currentTenantId,
                statusReport?.mission?.owner
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    const handlePresentButtonClick = () => {
        debouncedSave.flush();
        const href = generatePath(paths.presentStatusReport, {
            ...params,
            presentation: 'status-report',
        });
        navigate(href);
    };

    const buttons: AreaContainerButton[] = [];

    if (statusReport?.mission?.rights?.export) {
        buttons.push({
            key: 'Export',
            text: 'Export',
            iconProps: {
                iconName: 'PowerPointDocument',
            },
            disabled: isExporting,
            onClick: () => {
                handleExportButtonClick();
            },
            onRenderIcon: (_props, defaultRenderer) => {
                if (isExporting) {
                    return <Spinner size={SpinnerSize.small} />;
                }

                return defaultRenderer ? defaultRenderer() : null;
            },
        });
    }

    buttons.push({
        key: 'Present',
        text: 'Present',
        iconProps: {
            iconName: 'Presentation',
        },
        onClick: handlePresentButtonClick,
    });

    if (!statusReport?.utcCompletedDate && mission?.rights.write) {
        buttons.push({
            key: 'PublishReport',
            text: 'Publish',
            iconProps: {
                iconName: 'PublishContent',
            },
            onClick: () => {
                handleSubmitButtonClick();
            },
        });
    }

    if (statusReport?.utcCompletedDate && mission?.rights.write) {
        const disabled =
            !statusReport.mission?.team?.division?.canUnpublishReport &&
            !isAdmin;

        buttons.push({
            key: 'UnpublishReport',
            text: 'Unpublish',
            iconProps: {
                iconName: 'UnpublishContent',
            },
            disabled: disabled,
            title: disabled
                ? 'Only an admin can unpublish this report.'
                : undefined,
            onClick: handleUnsubmitButtonClick,
        });
    }

    if (mission?.rights.write && !statusReport?.utcCompletedDate) {
        buttons.push({
            key: 'Delete',
            text: 'Delete',
            iconOnly: true,
            iconProps: {
                iconName: 'Delete',
            },
            onClick: () => {
                handleDeleteButtonClick();
            },
        });
    }

    const leaderMission = mission?.team?.leaderMission;
    const teamLeader =
        leaderMission?.userId &&
        leaderMission?.username &&
        leaderMission?.userId !== mission?.userId
            ? {
                  userId: leaderMission?.userId,
                  displayName: leaderMission?.username,
              }
            : null;

    const allActions =
        statusReport?.facts
            .slice()
            .filter((f) => f.isIncluded)
            .sort(sorters.sequenceSorter)
            .flatMap((f) => {
                const factInput = changeTracker.current?.input.facts.find(
                    (fi) => fi.id === f.id
                );
                return f.actions
                    .slice()
                    .sort(sorters.sequenceSorter)
                    .filter(
                        (a) =>
                            !factInput?.actions ||
                            factInput?.actions.some((fia) => fia.id === a.id)
                    ) // filter out deleted
                    .map((a) => {
                        const actionInput = factInput?.actions.find(
                            (ai) => ai.id === a.id
                        );
                        return {
                            ...(actionInput || a),
                            statusReportFactId: f.id || '',
                        };
                    });
            }) || [];

    if (!loading && !mission?.rights.read) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                messageBarIconProps={{
                    iconName: 'AlertSolid',
                }}
                isMultiline={true}
            >
                <span>You do not have access to view this mission</span>
            </MessageBar>
        );
    }

    return (
        <React.Fragment>
            <EditPanels
                activePanel={activePanel}
                missionId={data?.statusReport?.missionId}
                measureId={activeMeasureId}
                taskId={activeTaskId}
                teamId={mission?.team?.id}
                onActivePanelChanged={handleActivePanelChanged}
                onActiveTaskChanged={onActiveTaskChanged}
                hideDelete
            />

            <DeleteModal
                activeViewName="StatusReportDelete"
                isOpen={isDeleteModalOpen}
                onDismiss={handleDismissDeleteModal}
                isDeleting={isDeleting}
                deleteAction={deleteAction}
                error={deleteError}
                message="Are you sure you want to delete this status report?"
            />

            {!!statusReport && !!mission && (
                <StatusReportPublishDialog
                    hidden={hideSubmitDialog}
                    onDismiss={handleSubmitDialogDismiss}
                    onConfirm={handleConfirmSubmitClick}
                    disabled={
                        isUpdating ||
                        isUpdatingFact ||
                        isUpdatingFactActions ||
                        loading
                    }
                    teamLeader={teamLeader}
                    mission={mission}
                    reportDate={statusReport.reportDate}
                    title={statusReport.title}
                    statusReport={statusReport}
                />
            )}

            <StatusReportUnPublishDialog
                hidden={hideUnsubmitDialog}
                onDismiss={handleUnsubmitDialogDismiss}
                onConfirm={handleConfirmUnsubmitClick}
                disabled={
                    isUpdating ||
                    isUpdatingFact ||
                    isUpdatingFactActions ||
                    loading
                }
            />

            {currentTenantId && statusReport?.id && mission?.id && (
                <StatusReportAddFactPanel
                    utcCompletedDate={statusReport.utcCompletedDate}
                    utcDataDate={statusReport.utcDataDate}
                    showPanel={showAddFactsPanel}
                    onDismiss={handleAddFactsPanelDismiss}
                    missionId={mission.id}
                    statusReport={statusReport}
                    tenantId={currentTenantId}
                    selectedFacts={includedFacts}
                />
            )}

            {!loading && (
                <AreaContainer
                    title={
                        <StatusReportTitle
                            title={statusReport?.title}
                            isReadOnly={isReadOnly}
                            onTitleUpdated={handleTitleUpdated}
                        />
                    }
                    isCollapsable={false}
                    subTitle={mission?.missionStatement}
                    commandBarButtons={buttons}
                >
                    <Stack tokens={{ childrenGap: 16, padding: 8 }}>
                        <StatusReportTopBar
                            statusReport={statusReport}
                            isReadOnly={isReadOnly}
                            isLoading={loading}
                            fyStartDate={fyStartDate}
                            fyEndDate={fyEndDate}
                            onPeriodSaveClick={handlePeriodSaveClick}
                            onSelectReportDate={handleReportDateChanged}
                            onRefreshDataButtonClick={
                                handleRefreshDataButtonClick
                            }
                            reportDateInputValue={
                                changeTracker.current?.input.reportDate ||
                                dayjs.utc().toISOString()
                            }
                        />

                        {(!!updateError ||
                            !!updateFactError ||
                            !!updateFactActionsError) && (
                            <div ref={errorMessageBarContainer}>
                                <ErrorMessageBar
                                    error={
                                        updateError ??
                                        updateFactError ??
                                        updateFactActionsError
                                    }
                                />
                            </div>
                        )}
                        {!loading && statusReport && (
                            <StatusReportEditorCard
                                title="Executive Summary"
                                iconName="DietPlanNotebook"
                                defaultValue={statusReport.summaryText}
                                statusReport={statusReport}
                                onChange={handleSummaryTextChanged}
                                readOnly={isReadOnly}
                                canExpandCollapse={true}
                                inputTextName="ExecutiveSummary"
                            />
                        )}

                        {!loading &&
                            currentTenantId &&
                            statusReport &&
                            mission?.id &&
                            fyStartDate && (
                                <StatusReportGrid
                                    tenantId={currentTenantId}
                                    fyStartDate={fyStartDate}
                                    missionId={mission?.id}
                                    missionUserId={mission.userId || null}
                                    utcDataDate={statusReport.utcDataDate}
                                    utcCompletedDate={
                                        statusReport.utcCompletedDate
                                    }
                                    reportPeriodType={
                                        statusReport.reportPeriodType
                                    }
                                    reportPeriod={statusReport.reportPeriod}
                                    facts={statusReport.facts}
                                    onFactChanged={onFactChanged}
                                    onFactActionsChanged={onFactActionsChanged}
                                    onFactRemoved={onFactRemoved}
                                    missionAccess={mission.rights}
                                    isReadOnly={isReadOnly}
                                    onMeasureNavigate={handleMeasureNavigate}
                                    onTaskNavigate={handleTaskNavigate}
                                    onFlushChanges={handleFlushChanges}
                                />
                            )}

                        {!loading && !isReadOnly && (
                            <Stack.Item align="start">
                                <ActionButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={handleAddFactsButtonClick}
                                >
                                    Add fact...
                                </ActionButton>
                            </Stack.Item>
                        )}

                        {!loading && (
                            <div className={classNames.bottomBoxContaner}>
                                <div className={classNames.bottomBoxColumn}>
                                    <div className={classNames.bottomBox}>
                                        {statusReport && (
                                            <StatusReportEditorCard
                                                title="Last Period"
                                                iconName="Rewind"
                                                defaultValue={
                                                    statusReport.lastPeriodText
                                                }
                                                statusReport={statusReport}
                                                onChange={
                                                    handleLastPeriodTextChanged
                                                }
                                                readOnly={isReadOnly}
                                                inputTextName="LastPeriod"
                                            />
                                        )}
                                    </div>
                                    <div className={classNames.bottomBox}>
                                        {statusReport && (
                                            <StatusReportEditorCard
                                                title="Risks / Opportunities"
                                                iconName="ReportWarning"
                                                defaultValue={
                                                    statusReport.risksAndOpportunitiesText
                                                }
                                                statusReport={statusReport}
                                                onChange={
                                                    handleRisksAndOpportunitiesTextChanged
                                                }
                                                readOnly={isReadOnly}
                                                inputTextName="RisksAndOpportunities"
                                            />
                                        )}
                                    </div>
                                    <div className={classNames.bottomBox}>
                                        {statusReport && (
                                            <StatusReportEditorCard
                                                title="Support"
                                                iconName="OfficeChat"
                                                defaultValue={
                                                    statusReport?.supportText
                                                }
                                                statusReport={statusReport}
                                                onChange={
                                                    handleSupportTextChanged
                                                }
                                                readOnly={isReadOnly}
                                                inputTextName="Support"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className={classNames.bottomBoxColumn}>
                                    <div className={classNames.bottomBox}>
                                        {statusReport && (
                                            <StatusReportEditorCard
                                                title="Next Period"
                                                iconName="FastForward"
                                                defaultValue={
                                                    statusReport.nextPeriodText
                                                }
                                                statusReport={statusReport}
                                                onChange={
                                                    handleNextPeriodTextChanged
                                                }
                                                readOnly={isReadOnly}
                                                inputTextName="NextPeriod"
                                            />
                                        )}
                                    </div>

                                    {!!statusReport && !!allActions?.length && (
                                        <div className={classNames.bottomBox}>
                                            <StatusReportActionList
                                                missionId={
                                                    statusReport.missionId
                                                }
                                                isReadOnly={
                                                    !mission?.rights.write ||
                                                    hasError
                                                }
                                                isLoading={loading}
                                                actions={allActions}
                                                onChanged={onFactActionsChanged}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Stack>
                </AreaContainer>
            )}
        </React.Fragment>
    );
}

export default StatusReportScene;

export function StatusReportUnPublishDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    onConfirm: (getLatestValues: boolean) => void;
    disabled: boolean;
}): JSX.Element {
    const [isGetLatestValuesChecked, setIsGetLatestValuesChecked] =
        useState(false);

    const onConfirmButtonButtonClick = () => {
        props.onConfirm(isGetLatestValuesChecked);
    };

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Confirm Unpublish',
                closeButtonAriaLabel: 'Close',
                subText: 'Do you want to mark this report as a draft?',
            }}
        >
            <Checkbox
                checked={isGetLatestValuesChecked}
                label="Update measures of success and task facts with the latest values"
                onChange={(_ev, checked) => {
                    setIsGetLatestValuesChecked(!!checked);
                }}
            />

            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmButtonButtonClick}
                    text="Confirm Unpublish"
                    disabled={props.disabled}
                />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}

function StatusReportTopBar(props: {
    statusReport:
        | {
              reportDate: string | null;
              reportPeriod: number | null;
              reportPeriodType: ReportPeriodTypes | null;
              utcDataDate: string | null;
              utcCompletedDate: string | null;
              utcUpdated: string | null;
              missionId: string;
              mission: { utcCreated: string } | null;
          }
        | null
        | undefined;
    isReadOnly: boolean;
    isLoading: boolean;
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    onPeriodSaveClick: (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => void;
    reportDateInputValue: string | null;
    onSelectReportDate: (newValue: string | null | undefined) => void;
    onRefreshDataButtonClick: () => void;
}): JSX.Element {
    const { statusReport, isReadOnly, isLoading, fyStartDate, fyEndDate } =
        props;

    const { currentTenantId } = useStateContext();

    const currentTheme = useTheme();

    const classNames = mergeStyleSets({
        container: {
            minHeight: 36,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            '@media(max-width: 620px)': {
                flexDirection: 'column-reverse',
                gap: 16,
            },
        },
        leftSide: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
        rightSide: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            textAlign: 'right',
            '@media(max-width: 620px)': {
                textAlign: 'left',
            },
        },
    });

    return (
        <Shimmer
            isDataLoaded={!isLoading && !!statusReport}
            customElementsGroup={
                <ShimmerElementsGroup
                    flexWrap={true}
                    width="100%"
                    shimmerElements={[
                        {
                            type: ShimmerElementType.line,
                            height: 36,
                            width: '100%',
                        },
                    ]}
                />
            }
        >
            <div className={classNames.container}>
                <div className={classNames.leftSide}>
                    {!!statusReport && (
                        <ReportPeriodLabel
                            report={statusReport}
                            isReadOnly={isReadOnly}
                            onPeriodSaveClick={props.onPeriodSaveClick}
                            fyStartDate={fyStartDate}
                            fyEndDate={fyEndDate}
                        />
                    )}

                    {!!statusReport?.reportDate && (
                        <div style={{ maxWidth: 200 }}>
                            {!isReadOnly ? (
                                <StatusReportAsOfDatePicker
                                    value={props.reportDateInputValue}
                                    mission={statusReport?.mission}
                                    onSelectDate={props.onSelectReportDate}
                                />
                            ) : (
                                <div>
                                    <Text style={{ fontWeight: 600 }}>
                                        Report Date:{' '}
                                    </Text>
                                    <Text>
                                        {dayjs
                                            .utc(statusReport?.reportDate)
                                            .format('DD MMM YYYY')}
                                    </Text>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className={classNames.rightSide}>
                    {!statusReport?.utcCompletedDate && (
                        <span
                            style={{
                                fontSize: '1em',
                                fontWeight: 'bold',
                                color: currentTheme.semanticColors
                                    .severeWarningIcon,
                                textTransform: 'uppercase',
                            }}
                        >
                            Draft
                        </span>
                    )}

                    {!statusReport?.utcCompletedDate &&
                        statusReport?.utcUpdated && (
                            <Text variant="small">
                                Last saved:{' '}
                                {dayjs
                                    .utc(statusReport?.utcUpdated)
                                    .tz(dayjs.tz.guess())
                                    .format('DD MMM YYYY HH:mm (z)')}
                            </Text>
                        )}

                    {statusReport?.utcCompletedDate && (
                        <Text variant="small">
                            This report was published on{' '}
                            {dayjs
                                .utc(statusReport?.utcCompletedDate)
                                .tz(dayjs.tz.guess())
                                .format('DD MMM YYYY HH:mm (z)')}
                        </Text>
                    )}

                    {statusReport?.utcDataDate &&
                        statusReport?.utcCompletedDate !==
                            statusReport?.utcDataDate && (
                            <Text variant="small">
                                Fact values are as of:{' '}
                                {dayjs
                                    .utc(statusReport?.utcDataDate)
                                    .tz(dayjs.tz.guess())
                                    .format('DD MMM YYYY HH:mm (z)')}
                            </Text>
                        )}

                    {!statusReport?.utcCompletedDate &&
                        statusReport?.utcDataDate &&
                        !isReadOnly &&
                        currentTenantId && (
                            <StatusReportDataChangedIndicator
                                tenantId={currentTenantId}
                                missionId={statusReport.missionId}
                                utcDataDate={statusReport.utcDataDate}
                                onRefreshDataButtonClick={
                                    props.onRefreshDataButtonClick
                                }
                            />
                        )}
                </div>
            </div>
        </Shimmer>
    );
}
