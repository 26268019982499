import React, { useCallback, useRef, useState } from 'react';
import { Icon, Stack, Text, Separator } from '@fluentui/react';
import MarkdownEditor, {
    MarkdownEditorRef,
} from '../../../components/inputs/MarkdownEditor';
import { AdvanceCard } from '../../../components/AdvanceCard';
import ExpandCollapse from '../../../components/ExpandCollapse';
import { Markdown } from '../../Presentations/Markdown';
import StatusReportAskMaiButton from './StatusReportAskMaiButton';
import { GetStatusReportQuery } from '../../../data/types';
import { InputTextName } from '../hooks/useStatusReportMai';

export function StatusReportEditorCard(props: {
    title: string;
    iconName: string;
    defaultValue?: string | null;
    onChange: (value: string) => void;
    readOnly: boolean;
    canExpandCollapse?: boolean;
    statusReport: GetStatusReportQuery['statusReport'];
    previousReportButton?: JSX.Element;
    inputTextName: InputTextName;
}): JSX.Element {
    const { defaultValue, onChange, statusReport, inputTextName } = props;

    const editorRef = useRef<MarkdownEditorRef>(null);

    const [isExpanded, setIsExpanded] = useState(true);

    const onMaiInputChanged = useCallback((newValue: string) => {
        editorRef.current?.setMarkdown(newValue);
        setInputText(newValue);
    }, []);

    const [inputText, setInputText] = useState(defaultValue || '');

    return (
        <AdvanceCard style={{ height: '100%' }}>
            <AdvanceCard.Item>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    verticalAlign={'center'}
                >
                    {props.canExpandCollapse && (
                        <ExpandCollapse
                            isExpanded={isExpanded}
                            onExpand={(): void => setIsExpanded(true)}
                            onCollapse={(): void => setIsExpanded(false)}
                        />
                    )}
                    <Icon iconName={props.iconName} />
                    <Stack.Item grow>
                        <Text variant="mediumPlus" block>
                            {props.title}
                        </Text>
                    </Stack.Item>

                    {!props.readOnly && (
                        <Stack.Item>
                            <StatusReportAskMaiButton
                                inputText={inputText}
                                onInputTextChanged={onMaiInputChanged}
                                statusReport={statusReport}
                                inputTextName={inputTextName}
                            />
                        </Stack.Item>
                    )}
                </Stack>
            </AdvanceCard.Item>
            {!props.readOnly && (
                <AdvanceCard.Item fill>
                    {isExpanded && (
                        <Separator
                            styles={{
                                root: {
                                    padding: 0,
                                },
                            }}
                        />
                    )}
                </AdvanceCard.Item>
            )}
            <AdvanceCard.Item>
                {isExpanded && !props.readOnly && (
                    <MarkdownEditor
                        defaultValue={defaultValue}
                        ref={editorRef}
                        onChange={(newValue: string) => {
                            setInputText(newValue);
                            onChange(newValue || '');
                        }}
                    />
                )}
                {isExpanded && props.readOnly && (
                    <Markdown source={defaultValue} />
                )}
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}
