import { useState, useCallback, useRef, useEffect } from 'react';
import {
    MaiDocument,
    MaiSubscription,
    MaiSubscriptionVariables,
} from '../data/types';
import { ObservableSubscription, useApolloClient } from '@apollo/client';

export function useMaiWebSockets(): {
    isStreaming: boolean;
    contentMd: string;
    startStreaming: (promptName: string, prompt: string) => void;
    cancelStreaming: () => void;
} {
    const [isStreaming, setIsStreaming] = useState(false);

    const [contentMd, setContentMd] = useState<string>('');

    const subRef = useRef<ObservableSubscription | null>(null);
    const isCancelledRef = useRef(false);

    const apolloClient = useApolloClient();

    useEffect(() => {
        return () => {
            isCancelledRef.current = true;
            subRef.current?.unsubscribe();
            subRef.current = null;
        };
    }, []);

    const startStreaming = useCallback(
        async (promptName: string, prompt: string) => {
            isCancelledRef.current = false;
            setContentMd('');
            setIsStreaming(true);

            const subscription = apolloClient.subscribe<
                MaiSubscription,
                MaiSubscriptionVariables
            >({
                query: MaiDocument,
                variables: {
                    prompt: prompt,
                    promptName: promptName,
                },
            });

            let isSubscribing = true;
            const queue: string[] = [];

            subRef.current = subscription.subscribe(
                async (result) => {
                    if (isCancelledRef.current) {
                        isSubscribing = false;
                        return;
                    }
                    queue.push(...(result.data?.chat || ''));
                },
                (err) => {
                    console.error(err);
                    isSubscribing = false;
                },
                () => {
                    isSubscribing = false;
                }
            );

            let fullText = '';
            let accumulatedText = '';
            const chunkSize = 8; // letters at a time
            const updateInterval = 50; // delay between updates

            while ((isSubscribing || queue.length) && !isCancelledRef.current) {
                if (queue.length) {
                    const char = queue.shift();
                    accumulatedText += char;

                    if (accumulatedText.length >= chunkSize) {
                        fullText += accumulatedText;
                        setContentMd(fullText);
                        accumulatedText = '';
                        await new Promise((resolve) =>
                            setTimeout(resolve, updateInterval)
                        );
                    }
                } else {
                    // Queue is empty, so wait a little longer for something to buffer up.
                    await new Promise((resolve) =>
                        setTimeout(resolve, updateInterval * 2)
                    );
                }
            }

            // Last bit
            if (accumulatedText.length > 0) {
                fullText += accumulatedText;
                setContentMd(fullText);
            }

            console.log(fullText);

            setIsStreaming(false);
        },
        [apolloClient]
    );

    const cancelStreaming = useCallback(() => {
        isCancelledRef.current = true;
        subRef.current?.unsubscribe();
        subRef.current = null;
        setContentMd('');
        setIsStreaming(false);
    }, []);

    return {
        isStreaming: isStreaming,
        contentMd: contentMd,
        startStreaming,
        cancelStreaming,
    };
}
