import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import {
    useGetMissionQuery,
    useGetMissionTasksQuery,
    useGetTaskCategoriesQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';

import { sorters } from '../../data/sorters';
import { useTaskStatus } from '../../hooks/useTaskStatus';
import { useStrategicDirection } from '../../hooks/useStrategicDirection';

import { StepContainer } from './components/StepContainer';
import { TaskWithStatus } from '../../data/extendedTypes';

import { Icon } from '@fluentui/react';
import { useLanguage } from '../../services/i18n';

import {
    CheckMarkIcon,
    LightningBoltIcon,
    LightningBoltSolidIcon,
    OfflineStorageIcon,
    OfflineStorageSolidIcon,
    RadioBulletIcon,
    Trending12Icon,
} from '@fluentui/react-icons-mdl2';

import { PresentationTitleSlide } from './components/PresentationTitleSlide';
import { PresentationSlide } from './components/PresentationSlide';
import { PresentationBlockquote } from './components/PresentationBlockquote';

import { PresentationUserImage } from './components/PresentationUserImage';
import { usePresentationColours } from './hooks/usePresentationColours';
import Reveal from 'reveal.js';
import orderBy from 'lodash/orderBy';

export function MissionAnalysisPresentation(props: {
    isSummary?: boolean;
}): JSX.Element {
    const SHOW_CATEGORY_LIST = false;

    const { currentTenantId, currentTeamCode } = useStateContext();

    const {
        backgroundColour,
        backgroundImage,
        highlightColourTransparent,
        highlightColour,
    } = usePresentationColours();

    const { t } = useLanguage();

    const { missionId } = useParams();

    const handleMessage = () => {
        parent.document.dispatchEvent(new Event('presentation:action'));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleMessage, false);
        window.addEventListener('mousedown', handleMessage, false);
        window.addEventListener('touchstart', handleMessage, false);
    }, []);

    const [revealApi, setRevealApi] = useState<Reveal.Api>();
    const handleInitialized = setRevealApi;
    const handleSectionChanged = () => {
        if (revealApi?.sync) {
            revealApi.sync();
        }
    };

    const { data: missionData } = useGetMissionQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
        pollInterval: 10000,
    });

    const mission = missionData?.mission;

    const { data: categoryData } = useGetTaskCategoriesQuery({
        skip:
            !missionId ||
            !currentTenantId ||
            !mission?.team?.division ||
            !mission?.team?.division?.financialYear,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: mission?.team?.division?.id || '',
            financialYearCode:
                mission?.team?.division?.financialYear?.code || '',
        },
        pollInterval: 10000,
    });

    const { data: tasksData } = useGetMissionTasksQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
        pollInterval: 10000,
    });

    const { onLevelUpStatement, twoLevelsUpStatement, vision, purpose } =
        useStrategicDirection(mission, currentTeamCode);

    const specifiedTasks = tasksData?.tasks
        ?.slice()
        ?.filter((t) => t.parentTaskId === null)
        ?.filter((t) => !t.isDuplicate)
        ?.sort(sorters.sequenceSorter);

    const impliedTasks = tasksData?.tasks
        ?.slice()
        ?.filter((t) => t.parentTaskId !== null)
        ?.filter((t) => !t.isDuplicate)
        ?.sort(sorters.sequenceSorter);

    const measures = missionData?.measureSummary
        ?.slice()
        ?.sort(sorters.sequenceSorter);

    const measureGroups = missionData?.measureGroups
        ?.slice()
        ?.sort(sorters.sequenceSorter);

    const isReady = !!mission && !!measures && !!specifiedTasks;

    const showEffort = impliedTasks?.some((it) => it.effortWeight > 1);
    const showCost = impliedTasks?.some((it) => it.costWeight > 1);

    const getStrategicDirectionStep = (isReaffirm?: boolean) => {
        const key = isReaffirm
            ? 'strategic-direction-reaffirm'
            : 'strategic-direction';

        return (
            <PresentationSlide key={key} sectionKey={key} leftSize="large">
                <PresentationSlide.LeftSection>
                    <div
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <div style={{ padding: 16, textAlign: 'center' }}>
                            <PresentationUserImage
                                userId={mission?.userId}
                                style={{
                                    width: '10vw',
                                }}
                            />

                            <h3>{mission?.owner}</h3>

                            {isReaffirm && <h5>REAFFIRM</h5>}

                            <h6>{mission?.missionStatement}</h6>
                        </div>
                    </div>
                </PresentationSlide.LeftSection>
                <PresentationSlide.RightSection>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.2vw',
                            paddingRight: 16,
                        }}
                    >
                        {!!purpose && (
                            <div>
                                <h6>Purpose</h6>
                                <PresentationBlockquote>
                                    {purpose}
                                </PresentationBlockquote>
                            </div>
                        )}

                        {!!vision && (
                            <div>
                                <h6>Vision</h6>
                                <PresentationBlockquote>
                                    {vision}
                                </PresentationBlockquote>
                            </div>
                        )}

                        {SHOW_CATEGORY_LIST &&
                            !!categoryData?.taskCategories?.length && (
                                <div>
                                    <h6>Categories</h6>
                                    <PresentationBlockquote>
                                        <div
                                            style={{
                                                padding: 8,
                                                display: 'grid',
                                                gridTemplateColumns:
                                                    'repeat(auto-fill, minmax(15vw, 1fr))',
                                                gridAutoFlow: 'dense',
                                                gap: '1.2vw',
                                            }}
                                        >
                                            {categoryData?.taskCategories.map(
                                                (c) => {
                                                    return (
                                                        <div
                                                            key={c.id}
                                                            style={{
                                                                backgroundColor: `#${c.colourHex}15`,
                                                                border: `solid 2px #${c.colourHex}`,
                                                                color: `#fff`,
                                                                paddingLeft:
                                                                    '0.5vw',
                                                                paddingRight:
                                                                    '0.5vw',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                minHeight:
                                                                    '3.6vw',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    maxHeight:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <span className="fit">
                                                                    {c.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </PresentationBlockquote>
                                </div>
                            )}

                        {!!twoLevelsUpStatement && (
                            <div>
                                <h6>Two Levels Up</h6>
                                <PresentationBlockquote>
                                    {twoLevelsUpStatement}
                                </PresentationBlockquote>
                            </div>
                        )}

                        {!!onLevelUpStatement && (
                            <div>
                                <h6>One Level Up</h6>
                                <PresentationBlockquote>
                                    {onLevelUpStatement}
                                </PresentationBlockquote>
                            </div>
                        )}
                    </div>
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    };

    const measureGroupDisplayList: {
        id: string | null;
        name: string | null;
    }[] = (measureGroups || [])
        .filter((mg) => measures?.some((m) => m.measureGroupId === mg.id))
        .map((mg) => ({
            id: mg.id,
            name: mg.name,
        }));

    if (measures?.some((m) => !m.measureGroupId)) {
        measureGroupDisplayList?.push({
            id: null,
            name: measureGroupDisplayList.length ? 'Ungrouped' : null,
        });
    }

    const getMeasuresStep = () => (
        <section
            key="measures"
            data-background-image={backgroundImage}
            data-background-color={backgroundColour}
            data-background-opacity={0.1}
        >
            <div style={{ height: '100vh', width: '100vw' }}>
                <div className="mintListContainer">
                    <div className="mintListHeader">
                        <h6>
                            {t('measure-of-success.measure-of-success', {
                                count: measures?.length,
                            })}
                        </h6>
                    </div>
                    {measures?.length && (
                        <div
                            className="mintListBody"
                            style={{ textAlign: 'left' }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div>
                                    {measureGroupDisplayList?.map((g) => (
                                        <React.Fragment key={g.id}>
                                            {g.name ? (
                                                <strong>{g.name}</strong>
                                            ) : null}
                                            <br />
                                            {measures
                                                ?.filter(
                                                    (m) =>
                                                        m.measureGroupId ===
                                                        g.id
                                                )
                                                .map((measure) => (
                                                    <React.Fragment
                                                        key={measure?.id}
                                                    >
                                                        &#x2022; {measure?.name}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>{' '}
            </div>
        </section>
    );

    const getSpecifiedTasksStep = () => {
        const showLinkedMeasures = specifiedTasks?.some(
            (st) => st.linkedMeasures.length
        );

        const dotSize = 28;
        const dotSizeStyle = `min(2vw, ${dotSize - 4}px)`;
        const dotIconStyle = `min(1.2vw, ${dotSize - 8}px)`;

        const specifiedTasksSortedByCategory = orderBy(specifiedTasks, [
            'taskCategory.name',
            'sequence',
        ]);

        return (
            <PresentationSlide
                key="specified-tasks"
                sectionKey="specified-tasks"
                leftSize="none"
            >
                <PresentationSlide.ContentContainer>
                    {/* {(!!onLevelUpStatement || !!vision) && (
                        <div
                            style={{
                                paddingLeft: '2vh',
                                paddingRight: '2vh',
                                paddingBottom: '2vh',
                                paddingTop: 48,
                            }}
                        >
                            <PresentationBlockquote>
                                {onLevelUpStatement || vision}
                            </PresentationBlockquote>
                        </div>
                    )} */}

                    {!!mission?.missionStatement && (
                        <div
                            style={{
                                padding: '2vh',
                                paddingTop: 28,
                            }}
                        >
                            <PresentationBlockquote hideIndicator>
                                MISSION: {mission?.missionStatement}
                            </PresentationBlockquote>
                        </div>
                    )}

                    <div
                        style={{
                            paddingLeft: '2vh',
                            paddingRight: '2vh',
                        }}
                    >
                        <div
                            style={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: 'calc(82vh - 64px)',
                                paddingRight: 16,
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${
                                        (specifiedTasks?.length || 0) > 1
                                            ? Math.min(
                                                  specifiedTasks?.length || 0,
                                                  6
                                              )
                                            : specifiedTasks?.length || 1
                                    }, 1fr)`,
                                    gridAutoRows: 'minmax(120px, auto)',
                                    gridAutoFlow: 'dense',
                                    gap: 8,
                                }}
                            >
                                {(specifiedTasksSortedByCategory || []).map(
                                    (specifiedTask) => {
                                        const classificationTag =
                                            specifiedTask.tags.find(
                                                (t) => t.name === 'Main Effort'
                                            ) ||
                                            specifiedTask.tags.find(
                                                (t) =>
                                                    t.name ===
                                                    'Flanking Activity'
                                            ) ||
                                            specifiedTask.tags.find(
                                                (t) =>
                                                    t.name ===
                                                    'Enabling Activity'
                                            );

                                        const linkedMeasuresToDisplay = measures
                                            ?.slice()
                                            ?.filter((m) =>
                                                specifiedTask.linkedMeasures.some(
                                                    (lm) =>
                                                        lm.measureId === m.id
                                                )
                                            )
                                            .sort(sorters.sequenceSorter)
                                            .slice(0, 3);

                                        const impliedTasksToDisplay =
                                            impliedTasks
                                                ?.sort(sorters.sequenceSorter)
                                                .filter(
                                                    (it) =>
                                                        it.parentTaskId ===
                                                        specifiedTask.id
                                                );

                                        return (
                                            <div
                                                key={specifiedTask?.id}
                                                style={{
                                                    gridColumnEnd: 'span 1',
                                                    gridRowEnd: 'span 1',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'space-between',
                                                        gap: 8,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '0.6em',
                                                            // padding:
                                                            //     '0.6vw',
                                                            paddingLeft: 4,
                                                            marginLeft: 24,
                                                            //borderRadius: 8,
                                                            borderLeft: `solid 4px #${specifiedTask.taskCategory?.colourHex || '818182'}`,
                                                        }}
                                                        className="line-clamp1"
                                                    >
                                                        {specifiedTask.taskCategory
                                                            ? specifiedTask
                                                                  .taskCategory
                                                                  .name
                                                            : 'No Category'}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'stretch',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                background: `linear-gradient(to right, transparent 0%, transparent 50%, ${highlightColourTransparent} 50%, ${highlightColourTransparent} 100%)`,
                                                                borderImage: `linear-gradient(to right, transparent 0%, transparent 50%, ${highlightColour} 50%, ${highlightColour} 100%) 9`,
                                                                // minHeight:
                                                                //     '10vh',
                                                                borderColor:
                                                                    'red',
                                                                borderWidth: 1,
                                                                borderStyle:
                                                                    'solid',
                                                                borderLeftStyle:
                                                                    'none',
                                                                borderRightStyle:
                                                                    'none',

                                                                width: dotSize,
                                                                minWidth:
                                                                    dotSize,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: dotSize,
                                                                    height: dotSize,
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        width: dotSizeStyle,
                                                                        minWidth:
                                                                            dotSizeStyle,
                                                                        height: dotSizeStyle,
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="mintLeftLabelDot"
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            left: 0,
                                                                            top: 0,
                                                                            width: dotSizeStyle,
                                                                            minWidth:
                                                                                dotSizeStyle,
                                                                            height: dotSizeStyle,
                                                                            borderRadius:
                                                                                dotSizeStyle,
                                                                            backgroundColor:
                                                                                '#fff',
                                                                        }}
                                                                    ></div>

                                                                    <div
                                                                        className="mintLeftLabelDot"
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            left: 0,
                                                                            top: 0,
                                                                            width: dotSizeStyle,
                                                                            minWidth:
                                                                                dotSizeStyle,
                                                                            height: dotSizeStyle,
                                                                            borderRadius:
                                                                                dotSizeStyle,
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            // borderColor:'#505050',
                                                                            // specifiedTask
                                                                            //     .taskCategory
                                                                            //     ?.colourHex
                                                                            //     ? `#${specifiedTask.taskCategory?.colourHex}`
                                                                            //     : undefined,
                                                                            //backgroundColor:
                                                                            // specifiedTask
                                                                            //     .taskCategory
                                                                            //     ?.colourHex
                                                                            //     ? `#${specifiedTask.taskCategory?.colourHex}40`
                                                                            //     : undefined,
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            styles={{
                                                                                root: {
                                                                                    //color: '#505050',
                                                                                    // color: specifiedTask
                                                                                    //     .taskCategory
                                                                                    //     ?.colourHex
                                                                                    //     ? `#${specifiedTask.taskCategory?.colourHex}`
                                                                                    //     : '#000',
                                                                                    fontSize:
                                                                                        dotIconStyle,
                                                                                },
                                                                            }}
                                                                            iconName={
                                                                                classificationTag?.icon ||
                                                                                'RadioBullet'
                                                                            }
                                                                            title={`${classificationTag?.name ? `${classificationTag?.name}` : ''}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                flexGrow: 1,
                                                                backgroundColor:
                                                                    highlightColourTransparent,
                                                                borderTop: `solid 1px ${highlightColour}`,
                                                                borderBottom: `solid 1px ${highlightColour}`,
                                                                padding:
                                                                    '0.6vw',
                                                                fontSize:
                                                                    '0.9em',
                                                                // minHeight:
                                                                //     '10vh',
                                                                overflow:
                                                                    'hidden',
                                                                lineHeight:
                                                                    '1.4em',
                                                            }}
                                                        >
                                                            <span
                                                                className="line-clamp3"
                                                                title={
                                                                    specifiedTask?.name ||
                                                                    undefined
                                                                }
                                                            >
                                                                {
                                                                    specifiedTask?.name
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {showLinkedMeasures && (
                                                    <div
                                                        style={{
                                                            paddingLeft: 24,
                                                        }}
                                                    >
                                                        <ul
                                                            className="iconList"
                                                            style={{
                                                                fontSize:
                                                                    '0.7em',
                                                            }}
                                                        >
                                                            {linkedMeasuresToDisplay?.map(
                                                                (m) => (
                                                                    <li
                                                                        className="listItem"
                                                                        key={
                                                                            m?.id
                                                                        }
                                                                        title={
                                                                            m?.name ||
                                                                            undefined
                                                                        }
                                                                    >
                                                                        <Trending12Icon className="icon" />
                                                                        <span className="line-clamp1">
                                                                            {m?.shortName
                                                                                ? m?.shortName
                                                                                : m?.name}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            )}

                                                            {/* Blank space so items line up */}
                                                            {Array.from({
                                                                length:
                                                                    3 -
                                                                    (linkedMeasuresToDisplay?.length ||
                                                                        0),
                                                            }).map((_e, i) => (
                                                                <li key={i}>
                                                                    <span className="line-clamp1"></span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}

                                                {!!impliedTasksToDisplay?.length && (
                                                    <div
                                                        style={{
                                                            marginTop: '0.4vw',
                                                            marginLeft: 14,
                                                            background: `${highlightColourTransparent} 50%`,
                                                            flexGrow: 1,
                                                            // borderTop: `solid 1px ${highlightColour}`,
                                                            // borderBottom: `solid 1px ${highlightColour}`,
                                                        }}
                                                    >
                                                        <ul className="iconList">
                                                            {impliedTasksToDisplay
                                                                ?.filter(
                                                                    (it) =>
                                                                        it.parentTaskId ===
                                                                        specifiedTask.id
                                                                )
                                                                ?.map((it) => (
                                                                    <li
                                                                        className="listItem"
                                                                        key={
                                                                            it?.id
                                                                        }
                                                                        style={{
                                                                            fontSize:
                                                                                '0.7em',
                                                                            padding:
                                                                                '0.4vw',
                                                                            lineHeight:
                                                                                '1.4em',
                                                                        }}
                                                                    >
                                                                        {it.done ? (
                                                                            <CheckMarkIcon className="icon" />
                                                                        ) : (
                                                                            <RadioBulletIcon className="icon" />
                                                                        )}
                                                                        <span
                                                                            className="line-clamp3"
                                                                            style={{
                                                                                minHeight:
                                                                                    'auto',
                                                                            }}
                                                                            title={
                                                                                it?.name ||
                                                                                undefined
                                                                            }
                                                                        >
                                                                            {
                                                                                it?.name
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </PresentationSlide.ContentContainer>
            </PresentationSlide>
        );
    };

    const getTasksSteps = () =>
        (specifiedTasks || []).map((task) => {
            const areRatingsCombined = showEffort && showCost;

            return (
                <section
                    key={`task-${task.id}`}
                    data-background-image={backgroundImage}
                    data-background-color={backgroundColour}
                    data-background-opacity={0.1}
                >
                    <div style={{ height: '100vh', width: '100vw' }}>
                        <div className="mintListContainer">
                            <div className="mintListHeader">
                                <h6>{task?.name}</h6>
                            </div>
                            <div className="mintListBody">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Implied Task</th>
                                            <th>Resources</th>
                                            {(showEffort || showCost) && (
                                                <th>
                                                    {areRatingsCombined
                                                        ? 'Ratings'
                                                        : showEffort
                                                          ? 'Effort'
                                                          : 'Cost'}
                                                </th>
                                            )}

                                            <th>Start</th>
                                            <th>Due</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(impliedTasks || [])
                                            .filter(
                                                (it) =>
                                                    it?.parentTaskId ===
                                                    task?.id
                                            )
                                            .map((it) => (
                                                <tr key={it?.id}>
                                                    <td>{it?.name}</td>
                                                    <td>
                                                        {it.resourcedTasks
                                                            ?.length === 0 && (
                                                            <span>-</span>
                                                        )}
                                                        {(
                                                            it.resourcedTasks?.slice() ||
                                                            []
                                                        )
                                                            .sort((r1, r2) =>
                                                                r1.resourceIsPrimary ===
                                                                r2.resourceIsPrimary
                                                                    ? 0
                                                                    : r1.resourceIsPrimary
                                                                      ? -1
                                                                      : 1
                                                            )
                                                            .map(
                                                                (
                                                                    rt,
                                                                    rtIndex
                                                                ) => [
                                                                    rtIndex >
                                                                        0 &&
                                                                        '\n',
                                                                    <div
                                                                        key={
                                                                            rtIndex
                                                                        }
                                                                        style={{
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            fontWeight:
                                                                                rt.resourceIsPrimary
                                                                                    ? 'bold'
                                                                                    : undefined,
                                                                        }}
                                                                    >
                                                                        {
                                                                            rt
                                                                                .resource
                                                                                ?.displayName
                                                                        }
                                                                    </div>,
                                                                ]
                                                            )}
                                                    </td>

                                                    {(showEffort ||
                                                        showCost) && (
                                                        <td
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    gap: 16,
                                                                }}
                                                            >
                                                                {showEffort && (
                                                                    <div>
                                                                        {areRatingsCombined && (
                                                                            <div
                                                                                style={{
                                                                                    fontSize:
                                                                                        '0.6em',
                                                                                    textTransform:
                                                                                        'uppercase',
                                                                                }}
                                                                            >
                                                                                Effort
                                                                            </div>
                                                                        )}
                                                                        {[
                                                                            ...Array(
                                                                                it.effortWeight
                                                                            ),
                                                                        ].map(
                                                                            (
                                                                                _ew,
                                                                                index
                                                                            ) => (
                                                                                <LightningBoltSolidIcon
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}

                                                                        {[
                                                                            ...Array(
                                                                                5 -
                                                                                    it.effortWeight
                                                                            ),
                                                                        ].map(
                                                                            (
                                                                                _ew,
                                                                                index
                                                                            ) => (
                                                                                <LightningBoltIcon
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    style={{
                                                                                        color: '#d0d0d0',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {showCost && (
                                                                    <div>
                                                                        {areRatingsCombined && (
                                                                            <div
                                                                                style={{
                                                                                    fontSize:
                                                                                        '0.6em',
                                                                                    textTransform:
                                                                                        'uppercase',
                                                                                }}
                                                                            >
                                                                                Cost
                                                                            </div>
                                                                        )}
                                                                        {[
                                                                            ...Array(
                                                                                it.costWeight
                                                                            ),
                                                                        ].map(
                                                                            (
                                                                                _ew,
                                                                                index
                                                                            ) => (
                                                                                <OfflineStorageSolidIcon
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}

                                                                        {[
                                                                            ...Array(
                                                                                5 -
                                                                                    it.costWeight
                                                                            ),
                                                                        ].map(
                                                                            (
                                                                                _ew,
                                                                                index
                                                                            ) => (
                                                                                <OfflineStorageIcon
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    style={{
                                                                                        color: '#d0d0d0',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    )}

                                                    <td
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {it?.start
                                                            ? dayjs
                                                                  .utc(
                                                                      it?.start
                                                                  )
                                                                  .format(
                                                                      'DD MMM YYYY'
                                                                  )
                                                            : '-'}
                                                    </td>
                                                    <td
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {it?.due
                                                            ? dayjs
                                                                  .utc(it?.due)
                                                                  .format(
                                                                      'DD MMM YYYY'
                                                                  )
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        <PresentationTaskStatus
                                                            impliedTask={it}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            );
        });

    const getBehavioursStep = () => {
        return (
            <PresentationSlide
                key="behaviours"
                sectionKey="behaviours"
                leftSize="large"
            >
                <PresentationSlide.LeftSection>
                    <div style={{ padding: 16 }}>
                        <h3>{mission?.owner}</h3>
                        <h6>{t('mission.behaviour', { count: 10 })}</h6>
                    </div>
                </PresentationSlide.LeftSection>
                <PresentationSlide.RightSection>
                    <div style={{ paddingRight: 16 }}>
                        <PresentationBlockquote>
                            <ul>
                                {mission?.behaviours
                                    .slice()
                                    .sort(sorters.sequenceSorter)
                                    .map((b) => <li key={b?.id}>{b?.text}</li>)}
                            </ul>
                        </PresentationBlockquote>
                    </div>
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    };

    const getBoundariesStep = () => {
        return (
            <PresentationSlide
                key="boundaries"
                sectionKey="boundaries"
                leftSize="large"
            >
                <PresentationSlide.LeftSection>
                    <div style={{ padding: 16 }}>
                        <h3>{mission?.owner}</h3>
                        <h6>{t('mission.boundaries')}</h6>
                    </div>
                </PresentationSlide.LeftSection>
                <PresentationSlide.RightSection>
                    <div style={{ paddingRight: 16 }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 48,
                            }}
                        >
                            <div>
                                <h6>
                                    {t('mission.freedom', {
                                        count: mission?.freedoms?.length,
                                    })}
                                </h6>
                                <PresentationBlockquote>
                                    <ul>
                                        {mission?.freedoms
                                            .slice()
                                            .sort(sorters.sequenceSorter)
                                            .map((b) => (
                                                <li key={b?.id}>{b?.text}</li>
                                            ))}
                                    </ul>
                                </PresentationBlockquote>
                            </div>
                            <div>
                                <h6>
                                    {t('mission.constraint', {
                                        count: mission?.constraints?.length,
                                    })}
                                </h6>
                                <PresentationBlockquote>
                                    <ul>
                                        {mission?.constraints
                                            .slice()
                                            .sort(sorters.sequenceSorter)
                                            .map((b) => (
                                                <li key={b?.id}>{b?.text}</li>
                                            ))}
                                    </ul>
                                </PresentationBlockquote>
                            </div>
                        </div>
                    </div>
                </PresentationSlide.RightSection>
            </PresentationSlide>
        );
    };

    const getSteps = (): JSX.Element[] => {
        const steps = !props.isSummary
            ? [
                  <PresentationTitleSlide
                      key="title"
                      title="Mission Presentation"
                      mission={mission}
                      onSectionChanged={handleSectionChanged}
                  />,
              ]
            : [];

        if (isReady) {
            steps.push(getStrategicDirectionStep());

            if (measures.length) {
                steps.push(getMeasuresStep());
            }

            if (specifiedTasks.length) {
                steps.push(getSpecifiedTasksStep());
            }

            if (!props.isSummary) {
                if (specifiedTasks.length) {
                    steps.push(...getTasksSteps());
                }

                if (mission.behaviours.length) {
                    steps.push(getBehavioursStep());
                }

                if (mission.freedoms.length || mission.constraints.length) {
                    steps.push(getBoundariesStep());
                }

                steps.push(getStrategicDirectionStep(true));
            }
        }

        return steps;
    };

    return (
        <StepContainer
            isReady={isReady}
            steps={getSteps()}
            onInitialized={handleInitialized}
        />
    );
}

function PresentationTaskStatus(props: {
    impliedTask: TaskWithStatus & {
        percentComplete: number | null | undefined;
    };
}): JSX.Element {
    const taskStatus = useTaskStatus(props.impliedTask);
    let percentageText: string | null = null;

    if (
        (props.impliedTask.percentComplete ?? 0) > 0 &&
        (props.impliedTask.percentComplete ?? 0) < 1
    ) {
        const formatter = new Intl.NumberFormat(undefined, {
            style: 'percent',
            maximumFractionDigits: 1,
        });
        const formattedPercentage = formatter.format(
            props.impliedTask.percentComplete ?? 0
        );
        percentageText = ` (${formattedPercentage})`;
    }

    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            {taskStatus?.text} {percentageText}
        </span>
    );
}
