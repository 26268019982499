import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import {
    ActionButton,
    Icon,
    Link,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { AdvanceCard } from '../../components/AdvanceCard';
import {
    useGetMissionQuery,
    useGetMissionTemplatesReportsQuery,
    useGetStatusReportsQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { AreaContainer } from '../MissionBuilder/components/AreaContainer';
import { StatusReportCreatePanel } from './components/StatusReportCreatePanel';
import { useActiveView } from '../../hooks/useActiveView';
import { paths } from '../../services/navigation';
import MarkdownIt from 'markdown-it';
import { sorters } from '../../data/sorters';
import { useLanguage } from '../../services/i18n';
import { Chip } from '../../components/Chip';
import Loading from '../../components/Loading';
import { BoldUserMentionsPlugin } from '../../components/plugins/BoldUserMentionsPlugin';

export enum ReportType {
    StatusReport,
    TemplateReport,
}

function StatusReports(): JSX.Element {
    const params = useParams();

    const [docTitle, setDocTitle] = useState<string | undefined>();
    useActiveView('StatusReports', docTitle);

    const { currentTenantId } = useStateContext();

    const [showCreateReportPanel, setShowCreateReportPanel] = useState(false);

    const { loading: missionLoading, data: missionData } = useGetMissionQuery({
        skip: !params.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: params.missionId || '',
        },
    });

    const missionAccess = missionData?.mission?.rights;

    useEffect(() => {
        if (missionData?.mission) {
            setDocTitle(
                `${missionData?.mission?.owner} (${missionData?.mission?.title}) Status Reports`
            );
        }
    }, [missionData?.mission]);

    const { data, loading: statusReportsLoading } = useGetStatusReportsQuery({
        skip: !params.missionId || !currentTenantId || !missionAccess?.read,
        fetchPolicy: 'cache-and-network',
        variables: {
            tenantId: currentTenantId || '',
            missionId: params.missionId || '',
        },
    });

    const { data: templateReportData, loading: templateReportsLoading } =
        useGetMissionTemplatesReportsQuery({
            skip: !params.missionId || !currentTenantId || !missionAccess?.read,
            fetchPolicy: 'cache-and-network',
            variables: {
                tenantId: currentTenantId || '',
                missionId: params.missionId || '',
            },
        });

    const statusReports =
        data?.statusReports?.filter((r) => !r.utcDeleted) || [];

    const templateReports =
        templateReportData?.reports?.templateReports?.filter(
            (r) => !r.utcDeleted
        ) || [];

    const isLoading = statusReportsLoading || templateReportsLoading;

    const allReports = !isLoading
        ? orderBy(
              [
                  ...statusReports.map((r) => ({
                      ...r,
                      type: ReportType.StatusReport,
                      sortDate: r.reportDate || r.utcCreated,
                      previewText: r.summaryText?.trim() || r.lastPeriodText,
                  })),
                  ...templateReports.map((r) => ({
                      ...r,
                      type: ReportType.TemplateReport,
                      sortDate: r.reportDate || r.utcCreated,
                      elements: r.sections
                          .slice()
                          .sort(sorters.sequenceSorter)
                          .flatMap((s) => s.elements)
                          .sort(sorters.sequenceSorter)
                          .filter((e) => e.title)
                          .map((e) => ({
                              title: e.title,
                              measureCount: e.measureLinks.length,
                              taskCount: e.taskLinks.length,
                          })),
                      previewText: r.sections
                          .slice()
                          .sort(sorters.sequenceSorter)
                          .flatMap((s) => s.elements)
                          .sort(sorters.sequenceSorter)
                          .find((e) => e.textContent)?.textContent,
                  })),
              ],
              ['sortDate'],
              ['desc']
          )
        : [];

    const classNames = mergeStyleSets({
        cards: {
            paddingLeft: 8,
            paddingRight: 8,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
            gridAutoRows: 'minmax(100px, auto)',
            gridAutoFlow: 'dense',
            gap: 16,
        },
        card: {
            gridColumnEnd: 'span 1',
            gridRowEnd: 'span 1',
        },
    });

    if (!missionLoading && !missionAccess?.read) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                messageBarIconProps={{
                    iconName: 'AlertSolid',
                }}
                isMultiline={true}
            >
                <span>You do not have access to view this mission</span>
            </MessageBar>
        );
    }

    return (
        <React.Fragment>
            {currentTenantId && params.missionId && (
                <StatusReportCreatePanel
                    tenantId={currentTenantId}
                    missionId={params.missionId}
                    showPanel={showCreateReportPanel}
                    onDismiss={() => {
                        setShowCreateReportPanel(false);
                    }}
                />
            )}
            <AreaContainer title="Status Reports" isCollapsable={false}>
                <Stack tokens={{ padding: 0, childrenGap: 8 }}>
                    {missionAccess?.write && (
                        <Stack.Item>
                            <ActionButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setShowCreateReportPanel(true);
                                }}
                            >
                                Create a report...
                            </ActionButton>
                        </Stack.Item>
                    )}

                    {isLoading && <Loading />}

                    <div className={classNames.cards}>
                        {missionAccess?.read &&
                            allReports.map((r) => (
                                <div key={r.id} className={classNames.card}>
                                    {r.type === ReportType.StatusReport ? (
                                        <StatusReportCard report={r} />
                                    ) : (
                                        <TemplateReportCard report={r} />
                                    )}
                                </div>
                            ))}
                    </div>
                </Stack>
            </AreaContainer>
        </React.Fragment>
    );
}

function TemplateReportCard(props: {
    report: {
        id: string | null;
        title: string | null;
        previewText?: string | null;
        reportDate: string | null;
        utcCompletedDate: string | null;
        elements?: {
            title: string | null;
            measureCount: number;
            taskCount: number;
        }[];
    };
}): JSX.Element {
    const counts: { label: string; count: number; iconName: string }[] = [];

    props.report.elements?.forEach((e) => {
        if (e.title && e.measureCount) {
            counts.push({
                label: e.title,
                count: e.measureCount,
                iconName: 'Trending12',
            });
        } else if (e.title && e.taskCount) {
            counts.push({
                label: e.title,
                count: e.taskCount,
                iconName: 'TaskGroup',
            });
        }
    });

    return (
        <ReportCard
            counts={counts}
            type={ReportType.TemplateReport}
            {...props}
        />
    );
}

function StatusReportCard(props: {
    report: {
        id: string | null;
        title: string | null;
        previewText?: string | null;
        reportDate: string | null;
        utcCompletedDate: string | null;
        facts?:
            | {
                  isIncluded: boolean;
                  measureId: string | null;
                  taskId: string | null;
                  actions: {
                      name: string | null;
                      due: string | null;
                      done: string | null;
                  }[];
              }[]
            | null;
    };
}): JSX.Element {
    const { t } = useLanguage();

    const includedFacts = props.report.facts?.filter((f) => f.isIncluded);
    const measureCount = includedFacts?.filter((f) => f.measureId).length || 0;
    const taskCount = includedFacts?.filter((f) => f.taskId).length || 0;
    const customCount = includedFacts
        ? includedFacts?.length - measureCount - taskCount
        : 0;

    const outstandingActionCount = Object.values(
        includedFacts?.map((a) => a.actions.filter((a) => !a.done)) || []
    ).reduce((acc, val) => acc + val.length, 0);

    const counts: { label: string; count: number; iconName: string }[] = [];

    if (outstandingActionCount) {
        counts.push({
            label: 'Outstanding Actions',
            count: outstandingActionCount,
            iconName: 'ReportWarning',
        });
    }

    if (measureCount) {
        counts.push({
            label: t('measure-of-success.measure-of-success', {
                count: measureCount,
            }),
            count: measureCount,
            iconName: 'Trending12',
        });
    }

    if (taskCount) {
        counts.push({
            label: t('specified-task.specified-task', {
                count: taskCount,
            }),
            count: taskCount,
            iconName: 'TaskGroup',
        });
    }

    if (customCount) {
        counts.push({
            label: 'Custom Fact',
            count: customCount,
            iconName: 'TextDocument',
        });
    }

    return (
        <ReportCard counts={counts} type={ReportType.StatusReport} {...props} />
    );
}

function ReportCard(props: {
    type: ReportType;
    counts: {
        label: string;
        count: number;
        iconName: string;
    }[];
    report: {
        id: string | null;
        title: string | null;
        previewText?: string | null;
        reportDate: string | null;
        utcCompletedDate: string | null;
    };
}): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();

    const currentTheme = useTheme();

    const statusColour = props.report.utcCompletedDate
        ? currentTheme.semanticColors.successIcon
        : currentTheme.semanticColors.severeWarningIcon;

    const statusName = props.report.utcCompletedDate ? 'Published' : 'Draft';

    const iconName = props.report.utcCompletedDate ? 'Completed' : 'PageEdit';

    const handleClick = () => {
        if (props.report.id) {
            const path =
                props.type === ReportType.StatusReport
                    ? generatePath(paths.statusReport, {
                          ...params,
                          statusReportId: props.report.id,
                      })
                    : generatePath(paths.templateReport, {
                          ...params,
                          templateReportId: props.report.id,
                      });
            navigate(path);
        }
    };

    const classNames = mergeStyleSets({
        countIconContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 8,
            gap: 4,
        },
        countIconBox: {
            backgroundColor: currentTheme.palette.neutralLight,
            padding: 4,
            paddingLeft: 8,
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            alignItems: 'center',
        },
        previewTextContainer: {
            minHeight: 32,
        },
        previewText: {
            paddingTop: 8,
            fontStyle: 'italic',
        },
    });

    const md = new MarkdownIt({ xhtmlOut: true });
    md.use(BoldUserMentionsPlugin);
    const parsed = md.parse(props.report.previewText || '', {});

    const firstLineOfPreview = parsed
        .filter((c) => c.type === 'inline')
        .flatMap((block) =>
            block?.children
                ?.filter((c) => c.type === 'text' && c.content)
                .map((child) => child.content)
        )
        .join(' ');

    return (
        <AdvanceCard
            childrenGap={8}
            onClick={handleClick}
            style={{
                cursor: 'pointer',
            }}
        >
            <AdvanceCard.Item>
                <Text variant="large" block title={props.report.title || ''}>
                    <Link className="line-clamp1">
                        {props.report.title || 'Status Report'}
                    </Link>
                </Text>

                <Text variant="small" block>
                    {props.report.reportDate
                        ? `Report Date: ${dayjs
                              .utc(props.report.reportDate)
                              .format('DD MMM YYYY')}`
                        : '\u00A0'}
                </Text>
            </AdvanceCard.Item>

            <AdvanceCard.Item
                style={{
                    minHeight: 124,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderTop: `solid 1px ${currentTheme.palette.neutralLight}`,
                }}
            >
                <div className={classNames.previewTextContainer}>
                    <Text
                        variant="xSmall"
                        block
                        className={`line-clamp2 ${classNames.previewText}`}
                    >
                        {firstLineOfPreview || ''}
                    </Text>
                </div>

                <div className={classNames.countIconContainer}>
                    {props.counts.map((c) => {
                        return (
                            <div
                                key={c.label}
                                className={classNames.countIconBox}
                            >
                                <Icon iconName={c.iconName} />
                                <Text variant="xSmall" block>
                                    {`${c.count.toString()} ${c.label}`}
                                </Text>
                            </div>
                        );
                    })}
                </div>
            </AdvanceCard.Item>
            <AdvanceCard.Item>
                <div style={{ display: 'inline-block' }}>
                    <Chip
                        text={statusName}
                        iconName={iconName}
                        backgroundColor={statusColour}
                    />
                </div>
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}

export default StatusReports;
